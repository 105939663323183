import request from "./index";
import qs from "querystring";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const AdminSettings = async () => {
  const AdminSettingResponse = await request.get("/getadminsettings");
  return AdminSettingResponse.data;
};

//updateStatus
export const updateStatusCall = async (
  type: string,
  variable: string,
  status: boolean
) => {
  // console.log("This is Update Status value =>"+variable +", Status =>"+status);

  const requestBody = qs.stringify({
    type: type,
    variable: variable,
    status: status ? false : true,
  });

  // const response = await request.post("/adminlogin", requestBody, config);

   await request.post(
    "/saveadminsetting",
    requestBody,
    config
  );

  const AdminSettingResponse2 = await request.get("/getadminsettings");
  return AdminSettingResponse2.data;
  //return AdminSettingResponse.data;
};
export const updateEmailPerm = async (
  type: string,
  variable: string,
  email: boolean
) => {
  const requestBody = qs.stringify({
    type: type,
    variable: variable,
    email: email ? false : true,
  });
  await request.post("/saveadminsetting", requestBody, config);
  const AdminSettingResponse2 = await request.get("/getadminsettings");
  return AdminSettingResponse2.data;
};

export const updateViolationPerm = async (
  type: string,
  variable: string,
  saveviolation: boolean
) => {
  const requestBody = qs.stringify({
    type: type,
    variable: variable,
    saveviolation: saveviolation ? false : true,
  });
  await request.post("/saveadminsetting", requestBody, config);
  const AdminSettingResponse2 = await request.get("/getadminsettings");
  return AdminSettingResponse2.data;
};

export const updateAlertPerm = async (
  type: string,
  variable: string,
  alert: boolean
) => {
  const requestBody = qs.stringify({
    type: type,
    variable: variable,
    alert: alert ? false : true,
  });
  await request.post("/saveadminsetting", requestBody, config);
  const AdminSettingResponse2 = await request.get("/getadminsettings");
  return AdminSettingResponse2.data;
};

export const updateNonePerm = async (type: string, variable: string) => {
  const requestBody = qs.stringify({
    type: type,
    variable: variable,
    alert: false,
    email: false,
    saveviolation: false,
  });
  await request.post("/saveadminsetting", requestBody, config);
  const AdminSettingResponse2 = await request.get("/getadminsettings");
  return AdminSettingResponse2.data;
};

export const updateValStatusCall = async (variable: string, value: string, type: string) => {
  // console.log("This is Update Status value =>"+variable +", Status =>"+status);

  const requestBody = qs.stringify({
    variable: variable,
    value: value,
    type
  });

  //const response = await request.post("/adminlogin", requestBody, config);

   await request.post(
    "/saveadminsetting",
    requestBody,
    config
  );

  const AdminSettingResponse2 = await request.get("/getadminsettings");
  return AdminSettingResponse2.data;
  //return AdminSettingResponse.data;
};
