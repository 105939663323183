import { IProfileProps } from "./interface";
//import ManJpg from "../../assets/images/man.jpg";

import "./styles.css";

const Profile = (props: IProfileProps) => {
  const { name = "", role = "" } = props;
  return (
    <div className="profile">
      <div className="text">
        <h6>{name}</h6>
        <p>{role}</p>
      </div>
    </div>
  );
};

export default Profile;
