import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const deleteUser = async (email?: string) => {
  const requestBody = qs.stringify({
    username: email,
  });

  const response = await request.post("/userdel", requestBody, config);

  return response.data;
};

