import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  SET_MFA,
} from "./actionTypes";
import { editUser } from "../services/editUser";
import { toast } from "react-toastify";

export const setMfaAction = (mfa: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_MFA,
      payload: { mfa },
    });
  };
};

export const handleEditUser = (
  username: string,
  email: string,
  password: string,
  role: string,
  firstName: string,
  lastName: string,
  mfa: boolean,
  loginlock: boolean,
  complexPassword: boolean,
  appUpdate: boolean,
  faceVerification:boolean,
  enableDesktopApp: boolean,
  team?: string,
  bussUnit?: string,
  mobile?: string,
  grouprooms?:Array<string>
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (username) {
        const responseData = await editUser(
          username,
          email,
          password,
          role,
          firstName,
          lastName,
          mfa,
          loginlock,
          complexPassword,
          appUpdate,
          faceVerification,
          enableDesktopApp,
          team,
          bussUnit,
          mobile,
          grouprooms
        );

        if (responseData.success) {
          dispatch(setSuccess());
          dispatch(setEditUserError(""));
          //dispatch(setSuccess());
          toast(responseData.message);
        } else {        
          dispatch(setEditUserError("Incorrect Fields"));
        }
        return responseData;
      }
    } catch (error: any) {
      
     // if (username && password) {    
      if (username) {   
        if (error.response.data.message && error.response.data.message.message) {          
          dispatch(setEditUserError(error.response.data.message.message));
        } else if(email){
          dispatch(setEditUserError("Email already exists"));
        } else {
          dispatch(setEditUserError("Incorrect Fields"));
        }
      }        
      else if (mobile) {
        dispatch(setEditUserError("Mobile already exists"));
       } else {
        dispatch(setEditUserError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setSuccess = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPDATE_USER_SUCCESS,
    });
  };
};

const setEditUserError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};
