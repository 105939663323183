import { useEffect, useState } from "react";
import "./AddRoom.css";
import RoomCard from "../../components/RoomCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { getRoomsAction } from "../../actions/roomActions";
import { getBussUnitsAction } from "../../actions/bussUnitActions";
import AddRoomCard from "../../components/AddRoomCard";
import Header from "./../../components/Header";
import ModalComponent from "../../components/DelModal";

const colors = [
  { bg: "#E0F4F4", color: "#479797" },
  { bg: "#FFE8F9", color: "#C9408D" },
  { bg: "#FFF3F1", color: "#FF3726" },
  { bg: "#EEEBFF", color: "#0E2549" },
];

interface IRoomListProps {
  filteredRooms: any[];
}

const RoomList = (props: IRoomListProps) => {
  const { filteredRooms } = props;
  if (filteredRooms && filteredRooms.length) {
    return (
      <ul>
        {filteredRooms.map((room: any, index: number) => {
          const colorIndex = index % colors.length;
          if(room.audioAllowedInRoom === undefined) {
            room.audioAllowedInRoom = true;
          }
          return (
            <RoomCard
              key={index}
              name={room.roomname}
              members={room.userDetails}
              background={colors[colorIndex].bg}
              colors={colors[colorIndex].color}
              image={room.imageName}
              bussUnit={room.bussunit}
              roomtype={room.roomtype}
              audioAllowedInRoom={room.audioAllowedInRoom}
              maxParticipantCount={room.maxParticipantCount}
              room={room}
            />
          );
        })}
        <AddRoomCard />
        <ModalComponent />
      </ul>
    );
  } else {
    return <AddRoomCard />;
  }
};

const AddRoom = () => {
  const dispatch = useDispatch();
  const rooms = useSelector((state: RootState) => state.roomsData.rooms);

  const [searchText, setSearchText] = useState("");
  const [filteredRooms, setFilteredRooms] = useState<any>([]);

  const handleSearchRooms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // useInterval(() => {
  //   dispatch(getRoomsAction());
  // }, 5000);

  useEffect(() => {
    dispatch(getRoomsAction());
    dispatch(getBussUnitsAction());
  }, [dispatch]);

  useEffect(() => {
    if (rooms && rooms.length > 0) {
      const filteredRooms = rooms.filter((room: any) => {
        const roomName = room.roomname.toLowerCase();
        const searchTextLowerCased = searchText.toLowerCase();
        return roomName.startsWith(searchTextLowerCased);
      });
      setFilteredRooms(filteredRooms);
    }
  }, [searchText, rooms]);

  return (
    <>
      <Header />
      <div className="team_con">
        <div className="search">
          <input
            value={searchText}
            type="search"
            name="search-rooms"
            placeholder="Search Team/Room"
            onChange={handleSearchRooms}
          />
        </div>
        <RoomList filteredRooms={filteredRooms} />
      </div>
    </>
  );
};

export default AddRoom;
