import React, { useState, useEffect } from "react";
import  "./ClassifyApps.css"; // Assuming you have a CSS file for styling, adjust the import path as needed.
import Header from "../../components/Header";
import {
  getDesktopApps,
  updateApplications,
} from "../../services/desktopActivity";
import { toast } from "react-toastify";
import { Alert, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ContextMenuComponent from "./ContextMenuComponent";

interface AppsState {
  productive: string[];
  nonProductive: string[];
  ideal: string[];
}

const ClassifyApps = () => {
  const [apps, setApps] = useState<AppsState>({
    productive: [],
    nonProductive: [],
    ideal: [],
  });
  const [localApps, setLocalApps] = useState<AppsState>({
    productive: [],
    nonProductive: [],
    ideal: [],
  });

  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });

  const getMenuItems = (category) => {
    switch (category) {
      case "IDEAL":
        return [
          {
            label: "Move to Productive",
            data: { case: "IDEAL", to: "PRODUCTIVE" },
          },
          {
            label: "Move to Non Productive",
            data: { case: "IDEAL", to: "NONPRODUCTIVE" },
          },
        ];
      case "PRODUCTIVE":
        return [
          {
            label: "Move to Non Productive",
            data: { case: "PRODUCTIVE", to: "NONPRODUCTIVE" },
          },
          { label: "Move to Unclassified", data: { case: "PRODUCTIVE", to: "IDEAL" } },
        ];
      case "NONPRODUCTIVE":
        return [
          {
            label: "Move to Productive",
            data: { case: "NONPRODUCTIVE", to: "PRODUCTIVE" },
          },
          {
            label: "Move to Unclassified",
            data: { case: "NONPRODUCTIVE", to: "IDEAL" },
          },
        ];
      default:
        return [];
    }
  };

  const [appsUpdated, setappsUpdated] = useState(true);

  useEffect(() => {
    // Fetch Apps and Set the initial state based on the categories in the given data
    desktopAppsList();
  }, []);

  // Function to handle radio button selection for an app
  const desktopAppsList = async () => {
    const apiResponse = await getDesktopApps();
    if (apiResponse.success) {
      delete apiResponse.data.browser;
      setLocalApps(apiResponse.data);
      setApps(apiResponse.data);
    } else {
      toast("Unable to fetch list of Applications");
    }
    console.log("APPS FROM DB: ", apiResponse);
  };

  const updateApps = async () => {
    setappsUpdated(false);
    const apiResponse = await updateApplications(apps);
    if (apiResponse.success) {
      toast("Apps Updated Successfully");
      setappsUpdated(true);
    } else {
      setappsUpdated(true);
      toast("Unable to update Apps");
    }
  };

  const handleContextClick = (e) => {
    e.preventDefault();
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <>
      <Header />
      <br />

      <div className="warning-container">
        <div className="warning-message">
          <Alert variant="success" className="green">
            Don't forget to click on Submit Selection Button After Classifying
            the Apps.
          </Alert>
        </div>
      </div>

      <div className="app_container">
        {apps &&
          Object.keys(localApps).map((ele) => (
            <Card key={ele}>
              <Card.Header>
                {ele === "nonProductive" ? "Non Productive" : ele === "ideal" ? "Unclassified" : ele} Apps
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <ListGroup>
                    {apps[ele].map((elem) => (
                      <ContextMenuComponent
                        key={elem}
                        id={elem}
                        position={contextMenuPosition}
                        onClick={(e) => handleContextClick(e)}
                        menuItems={getMenuItems(ele.toUpperCase())}
                        localApps={localApps}
                        setApps={setApps}
                      >
                        <ListGroup.Item
                          title="Right Click to Change Category."
                          onContextMenu={(e) => handleContextClick(e)}
                        >
                          {elem}
                        </ListGroup.Item>
                      </ContextMenuComponent>
                    ))}
                  </ListGroup>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
      </div>
      <div className="submit-button text-center mt-4">
        {appsUpdated ? (
          <Button id="search" variant="info" onClick={() => updateApps()}>
            Submit Selection
          </Button>
        ) : (
          <Button
            id="search"
            disabled
            variant="info"
            onClick={() => updateApps()}
          >
            Submit Selection
          </Button>
        )}
      </div>
    </>
  );
};

export default ClassifyApps;
