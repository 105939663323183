import { useEffect, useRef, useState } from "react";
// import "./Topics.css";
import Header from "./../../components/Header";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import ModalComponent from "./../../components/Modal";
import { OPEN_MODAL, CLOSE_MODAL } from "../../actions/actionTypes";
import { Modal } from "react-bootstrap";
import { editFeedAction, handleViewAllFeeds } from "../../actions/FeedsAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faQuestion } from "@fortawesome/free-solid-svg-icons";

import { handleDeleteFeed } from "../../actions/FeedsAction";
import { RootState } from "../../redux";
import Input from "../../components/Input";
import moment from "moment";
import { timeZoneFormat } from "../../utils/dateformat";
import "./styles.css";
import { Editor } from "@tinymce/tinymce-react";
import { getToken } from "../../utils/Token";
import { toast } from "react-toastify";
import { getActivityChannelUrl } from "../../utils/Token";

createTheme("solarized", {
  text: {
    primary: "#1e3869",
    secondary: "#1e3869",
  },
  background: {
    default: "#ffffff",
  },
  context: {
    background: "#cb4b16",
    text: "#000000",
  },
  divider: {
    default: "#ada8a8",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});
const Feeds = (props: any) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [feedType, setFeedType] = useState("normal");
  const [id, setId] = useState("");
  const [titleCount, setTitleCount] = useState(0);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [editorError, setEditorError] = useState(false);
  const [loading, setLoading] = useState(true);
  const topicId = props.match.params.id;

  const allFeeds = useSelector(
    (state: RootState) => state.viewAllFeedsReducer.feedList
  );

  const { format } = timeZoneFormat();
  const feedId = useSelector((state: RootState) => state.modal.feedId);
  const editorRef = useRef<any>(null);

  const environments = useSelector((state: RootState) => state.environmentsReducers.environments);
  const tinyMCEAPIKey = environments.REACT_APP_TINYMCE_API_KEY
    ? environments.REACT_APP_TINYMCE_API_KEY.value
    : environments.REACT_APP_TINYMCE_API_KEY.value;

  let activityChannelServerUrl = getActivityChannelUrl();
  
  const handleEditModal = (feedId: any) => {
    setShowEditModal(true);

    const feed = allFeeds.filter((feed) => feed._id === feedId);
    console.log(feed);
    setTitle(feed[0].title);
    setDescription(feed[0].description);
    setFeedType(feed[0].type);
    setId(feed[0]._id);
  };

  const handleEditorChange = (content: any) => {
    if (editorRef.current) {
      var some = editorRef.current.getContent();
      some.replace(/<\/?[^>]+(>|$)/g, "");
      setDescription(content);
    }
    setDescriptionError(false);
    setEditorError(false);
  };

  const uploadImageHandler: any = async (blobInfo: any, success: (url: string) => void, failure: (err: string) => void, progress: (percent: number) => void) => {
    // Allowed image MIME types
    const allowedImageTypes = ['image/png', 'image/jpeg', 'image/gif'];

    // Get the blob and its MIME type
    const blob = blobInfo.blob();
    const mimeType = blob.type;

    // Check if the MIME type is allowed
    if (!allowedImageTypes.includes(mimeType)) {
      toast('Only image files (PNG, JPEG, GIF) are allowed.');
      return; // Exit
    }

    // FormData to hold the file
    const formData = new FormData();
    formData.append('file', blob, blobInfo.filename());

    try {
      setLoading(true);

      const response = await fetch(`${activityChannelServerUrl}/TrainingRoom/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': getToken(),
        },
      });

      // Check if the request was successful
      if (response.ok) {
        const data = await response.json();
        if (data && data.location) {
          success(data.location);
        } else {
          failure('The server did not return an image URL.');
        }
        setLoading(false);
      } else {
        setLoading(false);
        // If the response is not ok, throw an error that will be caught by the catch block
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      setLoading(false);
      // On error, call the failure callback with the error message
      failure(`Image upload failed: ${error instanceof Error ? error.message : String(error)}`);
    }
  };

  function Spinner() {
    return (
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  const columns = [
    {
      name: "No",
      sortable: true,
      selector: "No",
      center: true,
    },
    {
      name: "Username",
      sortable: true,
      selector: "Username",
      center: true,
    },
    {
      name: "Title",
      sortable: true,
      selector: "Title",
      center: true,
    },
    {
      name: "Type",
      sortable: true,
      selector: "Type",
      center: true,
    },
    {
      name: "Creation Date",
      sortable: true,
      selector: "Creation_Date",
      center: true,
    },
    {
      name: "Action",
      selector: "Action",
      center: true,
    },
  ];

  const actions = (feedId: String) => {
    return (
      <>
        <button
          className="link marg-up-10"
          value="register"
          onClick={() => openDeleteModal(feedId)}
          title="Delete feed"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>

        <button
          className="link marg-up-10 ml-2"
          value="register"
          onClick={() => handleEditModal(feedId)}
          title="Edit feed"
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </>
    );
  };

  const populateUsers = (feeds) => {
    if (feeds.length > 0) {
      setData(
        feeds.map((feed: any, index: any) =>
          getDataObject(
            index,
            feed.username,
            feed.title,
            feed.description,
            feed.type,
            feed.createdAt,
            feed._id
          )
        )
      );
    } else {
      setData([]);
    }
  };
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    dispatch(handleViewAllFeeds(topicId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    populateUsers(allFeeds);
    // console.log("Feeds",allFeeds.feeds)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFeeds]);

  const getDataObject = (
    index: any,
    username: any,
    title: any,
    description: any,
    type: any,
    creation_date: any,
    feedId
  ) => {
    console.log(new Date(creation_date).toISOString(), { creation_date });

    const row = {
      No: index + 1,
      Username: username,
      Title: title,
      // Description: description.replace(/(<([^>]+)>)/gi, ""),
      Type: type !== undefined ? type : "normal",
      Creation_Date: moment(creation_date).format(format).split(" ")[0],
      Action: actions(feedId),
    };

    console.log(row);

    return row;
  };

  const openDeleteModal = (feedId: any) => {
    dispatch({
      type: OPEN_MODAL,
      feedId: feedId,
    });
  };

  const handleDelModalClose = (e) => {
    e.preventDefault();
    dispatch({
      type: CLOSE_MODAL,
    });
  };

  const handleDelete = (e: any, feedId: any) => {
    e.preventDefault();
    dispatch(handleDeleteFeed(feedId, topicId));
    handleDelModalClose(e);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value) {
      const filteredFeeds = allFeeds.filter((feed) => {
        return feed.description.includes(e.target.value.toUpperCase());
        // return user.username.startsWith(e.target.value.toUpperCase());
      });
      populateUsers(filteredFeeds);
    } else {
      populateUsers(allFeeds);
    }
  };

  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
    setTitleCount(e.target.value);
    setTitleError(false);
  };

  // const handleDescriptionChange = (content: any) => {
  //   const sanitizedHtml = content.replace(/<\/?[^>]+(>|$)/g, "");

  //   // if (sanitizedHtml.length <= maxCharLimit) {
  //   setDescription(content);
  //   // } else {
  //   //   quillRef.current?.blur(); // Remove focus from the editor
  //   // }
  //   setDescriptionError(false);
  //   // setDescriptionCount(e.target.value);
  //   setEditorError(false);
  // };

  const editModalSubmit = (e: any) => {
    e.preventDefault();
    if (title && title.trim() === "") {
      setTitleError(true);
      return;
    }

    if (description && description.replace(/(<([^>]+)>)/gi, "").trim() === "") {
      setDescriptionError(true);
      return;
    }

    if (
      description &&
      description.replace(/(<([^>]+)>)/gi, "").trim().length > 1000
    ) {
      setEditorError(true);
      return;
    }
    dispatch(editFeedAction(id, topicId, title, description, feedType));
    setTitle("");
    setDescription("");
    setId("");
    setShowEditModal(false);
  };

  const customStyles = {
    rows: {
      style: {
        maxHeight: "100%", // override the row height
      },
    },
    headCells: {
      style: {
        maxWidth: "250px !important",
      },
    },

    cells: {
      style: {
        maxWidth: "250px !important",
      },
    },
  };

  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">ALL Feeds</span>

        <div className="search-user">
          <Input
            type="text"
            name="username"
            placeholder="Search Feed"
            value={search}
            onChange={(e) => handleSearch(e)}
          />
          <br />
        </div>
      </nav>

      <DataTable
        title="ALL FEEDS"
        columns={columns}
        theme="solarized"
        noHeader
        data={data}
        pagination
        highlightOnHover
        customStyles={customStyles}
      />

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Feed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={editModalSubmit}>
            <div className="roomname-container">
              <h6>Title</h6>
            </div>
            <div className="input-container">
              <input
                type="text"
                name="room-name"
                placeholder="Title"
                value={title}
                onChange={handleTitleChange}
                maxLength={50}
                required
              />
              <span className="count-span">{`(${
                title.length ? title.length : titleCount ? titleCount : 0
              }/50)`}</span>
              {titleError && (
                <p style={{ color: "red", fontSize: "0.8rem", margin: "0" }}>
                  *Title Cannot be Blank
                </p>
              )}
            </div>
            <div className="type-container">
            <div className="type-catgory">
                <label>Normal</label>
                    <input
                      name="feed_type"
                      id="normal"
                      type="radio"
                      value="normal"
                      placeholder="Post Title"
                      onChange={(e) => {
                        setFeedType(e.target.value);
                      }}
                      checked={feedType === "normal"}
                    />
                </div>
                <div className="type-catgory">
                <label>Urgent</label>
                    <input
                      name="feed_type"
                      id="urgent"
                      type="radio"
                      value="urgent"
                      placeholder="Post Title"
                      onChange={(e) => {
                        setFeedType(e.target.value);
                      }}
                      checked={feedType === "urgent"}
                    />
                    <FontAwesomeIcon
                              className="urgent-tooltip"
                              title="urgent post will broadcast immediately on all user terminals"
                              icon={faQuestion}
                            />
                </div>
                </div>
            <div className="roomname-container">
              <h6>Description</h6>
            </div>
            <div className="input-container">
              {/* <ReactQuill
                theme="snow"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Post Description"
                style={{ fontSize: "15px" }}
              /> */}

              {loading && (
                <div
                  style={{
                    position: 'fixed',
                    left: '50%',
                    top: '50px',
                    transform: 'translateX(-50%)',
                    zIndex: '9999 !important'
                  }}
                >
                  <Spinner />
                </div>
              )}
              <Editor
                apiKey={tinyMCEAPIKey}
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                  setLoading(false);
                }}
                value={description}
                onEditorChange={handleEditorChange}
                init={{
                  branding: false,
                  height: 300,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "image",
                    "link",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "help",
                    "wordcount",
                  ],
                  toolbar: "undo redo blocks bold italic forecolor alignleft aligncenter alignright alignjustify image link | bullist numlist outdent indent removeformat help",
                  image_title: true,
                  automatic_uploads: true,
                  image_uploadtab: false,
                  image_description: false,
                  image_dimensions: false,
                  image_prepend_url: false,
                  image_caption: true,
                  images_upload_handler: uploadImageHandler,
                  file_picker_types: 'file image',
                  file_picker_callback: function (cb, value, meta) {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf');

                    input.onchange = async (e) => {
                      const file = (e.target as HTMLInputElement).files?.[0];
                      if (!file) {
                        return;
                      }

                      // Check if the file type is allowed
                      const allowedTypes = ['image/png', 'image/jpeg', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint', 'text/plain', 'application/rtf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
                      if (!allowedTypes.includes(file.type)) {
                        toast('Only image and document files are allowed.');
                        return; // Exit
                      }

                      // FormData to hold the file
                      const formData = new FormData();
                      formData.append('file', file);

                      const token = getToken();

                      try {
                        setLoading(true);

                        const response = await fetch(`${activityChannelServerUrl}/TrainingRoom/upload`, {
                          method: 'POST',
                          body: formData,
                          headers: {
                            'Authorization': token,
                          },
                        });

                        if (response.ok) {
                          const data = await response.json();

                          if (data && data.location) {
                            cb(data.location, { title: file.name });
                          } else {
                            throw new Error('The server did not return a file URL.');
                          }
                          setLoading(false);
                        } else {
                          setLoading(false);
                          throw new Error(`HTTP error! status: ${response.status}`);
                        }
                      } catch (error) {
                        setLoading(false);
                        console.error('File upload failed:', error);
                        // Handle error case - maybe call cb with empty string or show notification
                        cb('', { title: 'Upload failed' });
                      }
                    };

                    input.click();
                  },
                  content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />

              <span
                className="count-span"
                style={{
                  color: `${
                    description.replace(/<\/?[^>]+(>|$)/g, "").length > 1000 ?
                    "red":"#747474"
                  }`,
                }}
              >{`(${
                description.replace(/<\/?[^>]+(>|$)/g, "").length
              }/1000)`}</span>
              {descriptionError && (
                <p style={{ color: "red", fontSize: "0.8rem", margin: "0" }}>
                  *Description Cannot be Blank
                </p>
              )}
              {editorError && (
                <p style={{ color: "red", fontSize: "0.8rem", margin: "0" }}>
                  *Maximum Character limit for description is 1000
                </p>
              )}
            </div>
            <div className="submit-btn">
              <button className="link mt-3" type="submit">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <ModalComponent>
        <Modal.Header closeButton>
          <Modal.Title>Delete Feed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <h6>Do you want to continue ?</h6>

            <button
              className="link mt-3"
              onClick={(e) => handleDelete(e, feedId)}
            >
              YES
            </button>
            <button
              className="link mt-3 margin-left-10"
              onClick={handleDelModalClose}
            >
              NO
            </button>
          </form>
        </Modal.Body>
      </ModalComponent>
    </>
  );
};

export default Feeds;
