import { useEffect, useState } from "react";
import "./Topics.css";
import Header from "./../../components/Header";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  OPEN_DEL_MODAL,
  CLOSE_MODAL,
} from "../../actions/actionTypes";
import { getBussUnitsAction } from "../../actions/bussUnitActions";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";

import { RootState } from "../../redux";
import { useHistory } from "react-router-dom";
import React from "react";
import Input from "../../components/Input";
import moment from "moment";
import { timeZoneFormat } from "../../utils/dateformat";
import {
  getAllTopics,
  handleDeleteTopic,
  createTopicAction,
  editTopicAction,
} from "../../actions/TopicsAction";

createTheme("solarized", {
  text: {
    primary: "#1e3869",
    secondary: "#1e3869",
  },
  background: {
    default: "#ffffff",
  },
  context: {
    background: "#cb4b16",
    text: "#000000",
  },
  divider: {
    default: "#ada8a8",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const Topics = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [topic, setTopic] = useState("");
  const [currentTopic, setCurrentTopic] = useState("");
  const [currentDescription, setCurrentDescription] = useState("");
  const [description, setDescription] = useState("");
  const [client, setClient] = useState("");
  const [Id, setId] = useState("");
  const [topicCount, setTopicCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [, setEditTopicCount] = useState(0);
  const [editDescriptionCount, setEditDescriptionCount] = useState(0);
  const [whiteSpaceErr, setWhiteSpaceErr] = useState("");
  const [bussunit, setBussUnit] = useState("")

  const currentUsername = useSelector(
    (state: RootState) => state.userDetails.userName
  );
  const currentRole = useSelector(
    (state: RootState) => state.userDetails.role
  )
  const userBussUnit = useSelector(
    (state: RootState) => state.userDetails.bussunit
  );
  const allTopics = useSelector(
    (state: RootState) => state.topicsReducer.topics
  );
  const isDelOpen = useSelector((state: RootState) => state.modal.isDelOpen);
  const bussUnits = useSelector(
    (state: RootState) => state.bussUnit.bussUnits
  );
  const [showEditModal, setShowEditModal] = useState(false);
  //   const deleteIcon = (username: any) => (
  //     <FontAwesomeIcon icon={faTrash} onClick={() => openDeleteModal(username)} />
  //   );
  //   const editIcon = (username: any) => (
  //     <FontAwesomeIcon
  //       icon={faEdit}
  //       onClick={() => {
  //         history.push("/edit_users/" + username);
  //       }}
  //     />
  //   );
  const [data, setData] = useState<any>([]);

  const handleTopicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWhiteSpaceErr("");
    setTopic(e.target.value);

    setTopicCount(e.target.value.length);
  };
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setWhiteSpaceErr("");
    setDescription(e.target.value);
    setDescriptionCount(e.target.value.length);
  };

  // const currentTopicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setWhiteSpaceErr("");
  //   setCurrentTopic(e.target.value);
  //   setEditTopicCount(e.target.value.length);
  // };

  const currentDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setWhiteSpaceErr("");
    setCurrentDescription(e.target.value);
    setEditDescriptionCount(e.target.value.length);
  };
  const { format } = timeZoneFormat();
  const topicId = useSelector((state: RootState) => state.modal.topicId);
  const [roles, setRoles] = useState({
    Agent: ["read"],
    Supervisor: ["read"],
    Manager: ["read"],
    Director: ["read"],
    Admin: ["read"],
  });

  const columns = [
    {
      name: "No",
      sortable: true,
      selector: "No",
      center: true,
    },
    {
      name: "Username",
      sortable: true,
      selector: "Username",
      center: false,
    },
    {
      name: "Business unit",
      sortable: true,
      selector: "Bussunit",
      center: false,
    },
    {
      name: "Topic",
      sortable: true,
      selector: "Topic",
      center: false,
    },
    {
      name: "Description",
      sortable: true,
      selector: "Description",
      center: false,
    },
    {
      name: "Creation Date",
      sortable: true,
      selector: "Creation_Date",
      center: false,
    },
    {
      name: "Action",
      selector: "Action",
      center: false,
    },
  ];

  const actions = (id: any) => {
    return (
      <>
        <button
          className="link marg-up-10"
          value="register"
          onClick={() => openDeleteModal(id)}
          title="delete topic"
        >
          <FontAwesomeIcon icon={faTrash} onClick={() => openDeleteModal(id)} />
        </button>
        <button
          className="link marg-left-10 marg-up-10"
          value="register"
          title="View Feeds"
          onClick={() => history.push(`/feeds/${id}`)}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>

        <button
          className="link marg-left-10 marg-up-10"
          value="register"
          title="Edit Topic"
          onClick={() => handleEditClick(id)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const populateUsers = (topics) => {
    if (topics.length > 0) {
      setData(
        topics.map((topic: any, index: any) =>
          getDataObject(
            index,
            topic.username,
            topic.bussunit,
            topic.topic,
            topic.description.length > 30 ? topic.description.substring(0,30) + "..." : topic.description,
            topic.createdAt,
            topic._id
          )
        )
      );
    }
  };

  useEffect(() => {
    dispatch(getAllTopics());
    dispatch(getBussUnitsAction());
  }, [dispatch]);

  useEffect(() => {
    populateUsers(allTopics);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTopics]);

  const getDataObject = (
    index: any,
    username: any,
    bussunit: any,
    topic: any,
    description: any,
    creation_date: any,
    id: any
  ) => {
    console.log(new Date(creation_date).toISOString(), { creation_date });

    const row = {
      No: index + 1,
      Username: username,
      Bussunit: bussunit,
      Topic: topic,
      Description: description,
      Creation_Date: moment(creation_date).format(format).split(" ")[0],
      Action: actions(id),
    };
    console.log(row);
    return row;
  };

  const openDeleteModal = (topicId: any) => {
    dispatch({
      type: OPEN_DEL_MODAL,
      roomname: "",
      topicId: topicId,
    });
  };

  const handleDelModalClose = () => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };

  const handleDelete = (e: any, topicId: any) => {
    e.preventDefault();
    dispatch(handleDeleteTopic(topicId));
    handleDelModalClose();
  };

  const handleAddTopic = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (currentRole === "Admin" && (topic.trim() === "" || description.trim() === "" || bussunit === "")) {
      setWhiteSpaceErr("Topic, Description & Business unit are mandatory");
      return;
    } else if(currentRole === "Director" && (topic.trim() === "" || description.trim() === "")) {
      setWhiteSpaceErr("Topic & Description are mandatory");
      return;
    }

    dispatch(
      createTopicAction(
        currentUsername,
        topic.trim(),
        description.trim(),
        currentRole === "Admin" ? bussunit : userBussUnit,
        roles
      )
    );
    setTopic("");
    setDescription("");
    setBussUnit("");
    setTopicCount(0);
    setDescriptionCount(0);
    resetPermissionForm();
    setShowAddModal(false);
  };

  const handleEditTopic = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (currentTopic.trim() === "" || currentDescription.trim() === "") {
      setWhiteSpaceErr("Topic & Description are mandatory*");
      return;
    }
    
    if (currentRole === "Admin" && (currentTopic.trim() === "" || currentDescription.trim() === "" || bussunit === "")) {
      setWhiteSpaceErr("Topic, Description & Bussunit are mandatory");
      return;
    } else if(currentRole === "Director" && (currentTopic.trim() === "" || currentDescription.trim() === "")) {
      setWhiteSpaceErr("Topic & Description are mandatory");
      return;
    }
    
    dispatch(
      editTopicAction(
        Id,
        currentTopic.trim(),
        currentDescription.trim(),
        client,
        currentRole === "Admin" ? bussunit : userBussUnit,
        roles
      )
    );
    setShowEditModal(false);
    setCurrentTopic("");
    setCurrentDescription("");
    setId("");
    setClient("");
    resetPermissionForm();
  };

  const resetPermissionForm = () => {
    setRoles({
      Agent: ["read"],
      Supervisor: ["read"],
      Manager: ["read"],
      Director: ["read"],
      Admin: ["read"],
    });
    setWhiteSpaceErr("");
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value) {
      const filteredTopics = allTopics.filter((topic) => {
        return topic.topic.includes(e.target.value.toUpperCase());
        // return user.username.startsWith(e.target.value.toUpperCase());
      });
      populateUsers(filteredTopics);
    } else {
      populateUsers(allTopics);
    }
  };

  const handleEditClick = (id: any) => {
    setShowEditModal(true);
    const topic = allTopics.filter((topic) => topic._id === id);
    console.log({ topic });
    setCurrentTopic(topic[0].topic);
    setBussUnit(topic[0].bussunit);
    setCurrentDescription(topic[0].description);
    setClient(topic[0].client);
    setId(topic[0]._id);
    setRoles(topic[0].permissions);
    setEditTopicCount(currentTopic.length);
    setEditDescriptionCount(currentDescription.length);
  };

  const handlePermissionChange = (role, permission, isChecked) => {
    setRoles((prevRoles) => {
      let updatedRoles = { ...prevRoles };

      if (isChecked) {
        if (permission === "none") {
          updatedRoles[role] = ["none"];
        } else {
          updatedRoles[role] = updatedRoles[role].filter((p) => p !== "none");
          updatedRoles[role] = [...updatedRoles[role], permission];
        }
      } else {
        updatedRoles[role] = updatedRoles[role].filter((p) => p !== permission);
        if (updatedRoles[role].length === 0) {
          updatedRoles[role] = ["none"];
        }
      }

      return updatedRoles;
    });
  };

  const handleBussUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBussUnit(e.target.value);
  };

  useEffect(() => {
    // Automatically select 'none' when nothing is selected
    setRoles((prevRoles) => {
      let updatedRoles = { ...prevRoles };

      for (const role in updatedRoles) {
        if (updatedRoles[role].every((p) => p === "none")) {
          updatedRoles[role] = ["none"];
        }
      }

      return updatedRoles;
    });
  }, []);

  const handleModalCloseAdd = () => {
    resetPermissionForm();
    setTopic("");
    setDescription("");
    setTopicCount(0);
    setDescriptionCount(0);
  };

  const handleModalCloseEdit = () => {
    resetPermissionForm();
    setCurrentTopic("");
    setCurrentDescription("");
  };
  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg"></span>

        <div className="search-user">
          <button
            className="link marg-up-10 pull-right"
            value="register"
            onClick={() => setShowAddModal(true)}
          >
            Create Topic
          </button>
          <br />
        </div>
      </nav>
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">ALL Topics</span>

        <div className="search-user">
          <Input
            type="text"
            name="topic"
            placeholder="Search Topic"
            value={search}
            onChange={(e) => handleSearch(e)}
          />
          <br />
        </div>
      </nav>
      <DataTable
        title="ALL Topics"
        columns={columns}
        theme="solarized"
        noHeader
        data={data}
        pagination
        highlightOnHover
      />

      <Modal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton onClick={handleModalCloseAdd}>
          <Modal.Title>Create Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddTopic}>
            <div className="roomname-container">
              <h6>Topic</h6>
            </div>
            <div className="input-container">
              <span className="ws-err" style={{color:"red"}}>{whiteSpaceErr}</span>
              <input
                type="text"
                name="room-name"
                placeholder="Topic"
                value={topic}
                onChange={handleTopicChange}
                maxLength={50}
              />
            </div>
            <span className="count-span">{`(${topicCount}/50)`}</span>
            <div className="roomname-container">
              <h6>Description</h6>
            </div>
            <div className="input-container">
              <textarea
                name="room-name"
                placeholder="Description"
                value={description}
                onChange={handleDescriptionChange}
                maxLength={600}
              />
              <span className="count-span">{`(${descriptionCount}/600)`}</span>
            </div>
            {
              currentRole === "Admin" ?
              <>
            <h6>Please select Business Unit</h6>
            <div className="input-container">
                <select id="team" onChange={handleBussUnit} value={bussunit}>
                <option value="" disabled>
                  Please Select Business Unit
                </option>
                <option value="All">
                  Everyone
                </option>
                {
                  bussUnits.map((bussUnitObject, index) => {
                    return (
                      <option key={bussUnitObject._id} value={bussUnitObject.bussunit}>
                        {bussUnitObject.bussunit}
                      </option>
                    )
                  })
                }
              </select>
              {
                <span className="error-msg">
                  {bussUnits.length <= 0
                    ? "There are no Business units created. Please create a Business unit first"
                    : null}
                </span>
              }
            </div>
            </>
            : null 
            }
            <div>
              <h6>Permissions</h6>
              <table className="permission-table">
                <thead>
                  <tr>
                    <th className="t-head">Role</th>
                    <th className="t-head">Read</th>
                    <th className="t-head">Write</th>
                    <th className="t-head">None</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(roles).map((role) => (
                    <tr key={role}>
                      <td className="table-data">{role}</td>
                      <td className="table-data">
                        <input
                          type="checkbox"
                          name={`${role}-permission`}
                          value="read"
                          checked={roles[role].includes("read")}
                          onChange={(e) =>
                            handlePermissionChange(
                              role,
                              "read",
                              e.target.checked
                            )
                          }
                        />
                        
                      </td>
                      <td className="table-data">
                        <input
                          type="checkbox"
                          name={`${role}-permission`}
                          value="write"
                          checked={roles[role].includes("write")}
                          onChange={(e) =>
                            handlePermissionChange(
                              role,
                              "write",
                              e.target.checked
                            )
                          }
                        />
                        
                      </td>
                      <td className="table-data">
                        <input
                          type="checkbox"
                          name={`${role}-permission`}
                          value="none"
                          checked={roles[role].includes("none")}
                          onChange={(e) =>
                            handlePermissionChange(
                              role,
                              "none",
                              e.target.checked
                            )
                          }
                        />
                        
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="submit-btn">
              <button
                className="link mt-3"
                type="submit"
                // disabled={loading || bussUnitsList.length <= 0}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton onClick={handleModalCloseEdit}>
          <Modal.Title>Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEditTopic}>
            <div className="roomname-container">
              <h6>Topic</h6>
            </div>
            <div className="input-container" >
            <span className="ws-err" style={{color:"red"}}>{whiteSpaceErr}</span>
              <input
                type="text"
                name="room-name"
                placeholder="Topic"
                value={currentTopic}
                // onChange={currentTopicChange}
                maxLength={50}
                readOnly
                disabled
              />
              {/* <span className="count-span">{`(${
                currentTopic.length ? currentTopic.length : editTopicCount
              }/50)`}</span> */}
            </div>
            <div className="roomname-container">
              <h6>Description</h6>
            </div>
            <div className="input-container">
              <textarea
                name="room-name"
                placeholder="Description"
                value={currentDescription}
                onChange={currentDescriptionChange}
                maxLength={600}
              />
              <span className="count-span">{`(${
                currentDescription.length
                  ? currentDescription.length
                  : editDescriptionCount
              }/600)`}</span>
            </div>
            {
              currentRole === "Admin" ?
              <>
            <h6>Please select Business Unit</h6>
            <div className="input-container">
                <select id="team" onChange={handleBussUnit} value={bussunit}>
                <option value="" disabled>
                  Please Select Business Unit
                </option>
                <option value="All">
                  Everyone
                </option>
                {
                  bussUnits.map((bussUnitObject, index) => {
                    return (
                      <option key={bussUnitObject._id} value={bussUnitObject.bussunit}>
                        {bussUnitObject.bussunit}
                      </option>
                    )
                  })
                }
              </select>
              {
                <span className="error-msg">
                  {bussUnits.length <= 0
                    ? "There are no Business units created. Please create a Business unit first"
                    : null}
                </span>
              }
            </div>
            </>
            : null 
            }
            {/* <div className="radio-container">
              <div>
                <label>
                  <input
                    type="radio"
                    value="read"
                    checked={editSelectOption === "read"}
                    onChange={editHandleSelectOption}
                  />
                  Read
                </label>

                <label>
                  <input
                    type="radio"
                    value="write"
                    checked={editSelectOption === "write"}
                    onChange={editHandleSelectOption}
                  />
                  Write
                </label>
              </div>
            </div> */}

            <div>
              <h6>Permissions</h6>
              <table className="permission-table">
                <thead>
                  <tr>
                    <th className="t-head">Role</th>
                    <th className="t-head">Read</th>
                    <th className="t-head">Write</th>
                    <th className="t-head">None</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(roles).map((role) => (
                    <tr key={role}>
                      <td className="table-data">{role}</td>
                      <td className="table-data">
                        <input
                          type="checkbox"
                          name={`${role}-permission`}
                          value="read"
                          checked={roles[role].includes("read")}
                          onChange={(e) =>
                            handlePermissionChange(
                              role,
                              "read",
                              e.target.checked
                            )
                          }
                        />
                        
                      </td>
                      <td className="table-data">
                        <input
                          type="checkbox"
                          name={`${role}-permission`}
                          value="write"
                          checked={roles[role].includes("write")}
                          onChange={(e) =>
                            handlePermissionChange(
                              role,
                              "write",
                              e.target.checked
                            )
                          }
                        />
                        
                      </td>
                      <td className="table-data">
                        <input
                          type="checkbox"
                          name={`${role}-permission`}
                          value="none"
                          checked={roles[role].includes("none")}
                          onChange={(e) =>
                            handlePermissionChange(
                              role,
                              "none",
                              e.target.checked
                            )
                          }
                        />

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="submit-btn">
              <button
                className="link mt-3"
                type="submit"
                //   disabled={loading || bussUnitsList.length <= 0}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={isDelOpen} onHide={handleDelModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Do you want to continue ?</h6>

          <button
            className="link mt-3"
            onClick={(e) => handleDelete(e, topicId)}
          >
            YES
          </button>
          <button
            className="link mt-3 margin-left-10"
            onClick={handleDelModalClose}
          >
            NO
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Topics;
