export const OPEN_MODAL = "OPEN_MODAL";
export const OPEN_EDIT_MODAL = "OPEN_EDIT_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SET_LOADING = "SET_LOADING";
export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAILURE = "OTP_FAILURE";
export const SET_MFA = "SET_MFA";
export const SET_APP_UPDATE = "SET_APP_UPDATE";
export const ENABLE_DESKTOP_APP = "ENABLE_DESKTOP_APP";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";
export const GET_ROOMS_SUCCESS = "GET_ROOMS_SUCCESS";
export const GET_ROOMS_FAILURE = "GET_ROOMS_FAILURE";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS";
export const CREATE_ROOM_FAILURE = "CREATE_ROOM_FAILURE";
export const SET_CONNECTION_DETAILS = "SET_CONNECTION_DETAILS";
export const SET_AUDIO_DEVICES = "SET_AUDIO_DEVICES";
export const SET_VIDEO_DEVICES = "SET_VIDEO_DEVICES";
export const SELECT_AUDIO_DEVICE = "SELECT_AUDIO_DEVICE";
export const SELECT_VIDEO_DEVICE = "SELECT_VIDEO_DEVICE";
export const SET_MESSAGE_LIST = "SET_MESSAGE_LIST";
export const SET_TYPER_NAME = "SET_TYPER_NAME";
export const SET_FULL_MESSAGE_LIST = "SET_FULL_MESSAGE_LIST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";
export const ADD_LOGO_SUCCESS = "ADD_LOGO_SUCCESS";
export const ADD_LOGO_FAILURE = "ADD_LOGO_FAILURE";
export const CLEAR_ADDLOGO_DATA = "CLEAR_ADDLOGO_DATA";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const GET_ALL_USER_DETAILS_SUCCESS = "GET_ALL_USER_DETAILS_SUCCESS";
export const GET_ALL_USER_DETAILS_FAILURE = "GET_ALL_USER_DETAILS_FAILURE";

export const GET_ALL_ADMIN_SETTINGS_SUCCESS = "GET_ALL_ADMIN_SETTINGS_SUCCESS";
export const GET_ALL_ADMIN_SETTINGS_FAILURE = "GET_ALL_ADMIN_SETTINGS_FAILURE";
export const SET_UPDATE_SETTINGS_SUCCESS = "SET_UPDATE_SETTINGS_SUCCESS";
export const SET_UPDATE_SETTINGS_FAILURE = "SET_UPDATE_SETTINGS_FAILURE";

export const CLEAR_VIEWADMINDETAIL_DATA = "CLEAR_VIEWADMINDETAIL_DATA";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const GET_SPECIFIC_USER_DETAILS_SUCCESS =
  "GET_SPECIFIC_USER_DETAILS_SUCCESS";
export const GET_SPECIFIC_USER_DETAILS_FAILURE =
  "GET_SPECIFIC_USER_DETAILS_FAILURE";
export const GET_CURRENT_USERS_SUCCESS = "GET_CURRENT_USERS_SUCCESS";
export const GET_CURRENT_USERS_FAILURE = "GET_CURRENT_USERS_FAILURE";
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
export const GET_REPORT_DATA_FAILURE = "GET_REPORT_DATA_FAILURE";
export const GET_REPORT_NAME_SUCCESS = "GET_REPORT_NAME_SUCCESS";
export const GET_REPORT_NAME_FAILURE = "GET_REPORT_NAME_FAILURE";
export const GET_REPORT_ROOM_SUCCESS = "GET_REPORT_ROOM_SUCCESS";
export const GET_REPORT_ROOM_FAILURE = "GET_REPORT_ROOM_FAILURE";
export const GET_HOURLY_ROOM_SUCCESS = "GET_HOURLY_ROOM_SUCCESS";
export const GET_HOURLY_ROOM_FAILURE = "GET_HOURLY_ROOM_FAILURE";
export const GET_TWILIO_SETTING_DETAILS_SUCCESS =
  "GET_TWILIO_SETTING_DETAILS_SUCCESS";
export const GET_TWILIO_SETTING_DETAILS_FAILURE =
  "GET_TWILIO_SETTING_DETAILS_FAILURE";
export const CLEAR_GETTWILIODETAIL_DATA = "CLEAR_GETTWILIODETAIL_DATA";
export const CLEAR_TWILLIO_DATA = "CLEAR_TWILLIO_DATA";
export const TWILLIO_SETTING_SUCCESS = "TWILLIO_SETTING_SUCCESS";
export const TWILLIO_SETTING_FAILURE = "TWILLIO_SETTING_FAILURE";
export const DEL_ROOM_SUCCESS = "DEL_ROOM_SUCCESS";
export const DEL_ROOM_FAILURE = "DEL_ROOM_FAILURE";
export const OPEN_DEL_MODAL = "OPEN_DEL_MODAL";
export const GET_UNIQUE_LOGIN_USERS_SUCCESS = "GET_UNIQUE_LOGIN_USERS_SUCCESS";
export const GET_USERS_BY_ROOM = "GET_USERS_BY_ROOM";
export const GET_BUSS_SUCCESS = "GET_BUSS_SUCCESS";
export const GET_BUSS_FAILURE = "GET_BUSS_FAILURE";
export const CREATE_BUSS_SUCCESS = "CREATE_BUSS_SUCCESS";
export const CREATE_BUSS_FAILURE = "CREATE_BUSS_FAILURE";
export const DEL_BUSS_SUCCESS = "DEL_BUSS_SUCCESS";
export const DEL_BUSS_FAILURE = "DEL_BUSS_FAILURE";
export const SET_EXPORT_DATA = "SET_EXPORT_DATA";
export const SET_EXPORT_LOADING_TRUE = "SET_EXPORT_LOADING_TRUE";
export const SET_EXPORT_LOADING_FALSE = "SET_EXPORT_LOADING_FALSE";
export const GET_LOCATION_DATA_SUCCESS = "GET_LOCATION_DATA_SUCCESS";

// Background image

export const GET_ALL_BACKGROUND_iMAGE = "GET_ALL_BACKGROUND_iMAGE";
export const SET_DELETE_BACKGROUND_iMAGE_NAME =
  "SET_DELETE_BACKGROUND_iMAGE_NAME";
export const SET_BACKGROUND_IMAGE_LOADER = "SET_BACKGROUND_IMAGE_LOADER";

// // feedback images
// export const GET_FEEDBACKIMAGES_SUCCESS = "GET_FEEDBACKIMAGES_SUCCESS";
// export const GET_FEEDBACKIMAGES_FAILURE = "GET_FEEDBACKIMAGES_FAILURE";

// Chat Data

export const SET_CHAT_DATA = "SET_CHAT_DATA";
export const SET_CHAT_LOADING_TRUE = "SET_CHAT_LOADING_TRUE";
export const SET_CHAT_LOADING_FALSE = "SET_CHAT_LOADING_FALSE";

// feedback images
export const GET_FEEDBACKIMAGES_SUCCESS = "GET_FEEDBACKIMAGES_SUCCESS";
export const GET_FEEDBACKIMAGES_FAILURE = "GET_FEEDBACKIMAGES_FAILURE";

// Environment
export const GET_ENVIRONMENTS_SUCCESS = "GET_ENVIRONMENTS_SUCCESS";
export const GET_ENVIRONMENTS_FAILURE = "GET_ENVIRONMENTS_FAILURE";

// Admin Settings
export const SET_ADMIN_SETTINGS_VALUE = "SET_ADMIN_SETTINGS_VALUE";

//Topics settings
export const GET_ALL_TOPICS_SUCCESS = "GET_ALL_TOPICS_SUCCESS";
export const GET_ALL_TOPICS_FAILURE = "GET_ALL_TOPICS_FAILURE";
export const CLEAR_VIEWTOPICS_DATA = "CLEAR_VIEWTOPICS_DATA";
export const ADD_TOPIC_SUCCESS = "ADD_TOPIC_SUCCESS";
export const ADD_TOPIC_FAILURE = "ADD_TOPIC_FAILURE";
export const EDIT_TOPIC_SUCCESS = "EDIT_TOPIC_SUCCESS";
export const EDIT_TOPIC_FAILURE = "EDIT_TOPIC_FAILURE";
export const CLEAR_ADDTOPIC_DATA = "CLEAR_ADDTOPIC_DATA";
export const DELETE_TOPIC_SUCCESS = "DELETE_TOPIC_SUCCESS";
export const DELETE_TOPIC_FAILURE = "DELETE_TOPIC_FAILURE";

// Feeds settings
export const GET_ALL_FEEDS_SUCCESS = "GET_ALL_FEED_SUCCESS";
export const GET_ALL_FEEDS_FAILURE = "GET_ALL_FEED_FAILURE";
export const CLEAR_VIEWFEEDS_DATA = "CLEAR_VIEWFEEDS_DATA";
export const DELETE_FEED_SUCCESS = "DELETE_FEED_SUCCESS";
export const DELETE_FEED_FAILURE = "DELETE_FEED_FAILURE";

export const GET_TOPICS_SUCCESS = "GET_TOPICS_SUCCESS";
export const GET_TOPICS_FAILURE = "GET_TOPICS_FAILURE";
export const CREATE_TOPIC_SUCCESS = "CREATE_TOPIC_SUCCESS";
export const CREATE_TOPIC_FAILURE = "CREATE_TOPIC_FAILURE";
export const CLEAR_TOPICS_DATA = "CLEAR_TOPICS_DATA";
export const EDIT_FEED_SUCCESS = "EDIT_FEED_SUCCESS";
export const EDIT_FEED_FAILURE = "EDIT_FEED_FAILURE";
export const CLEAR_LOCATION_DATA = "CLEAR_LOCATION_DATA";
export const SET_USERS_LIST_SUCCESS = "SET_USERS_LIST_SUCCESS";
export const SET_USERS_LIST_FAILURE = "SET_USERS_LIST_FAILURE";

// Group Room Data
export const SET_ALL_GROUP_ROOM_DATA = "SET_ALL_GROUP_ROOM_DATA";
export const SET_LOADER_FOR_GROUP_ROOM = "SET_LOADER_FOR_GROUP_ROOM";

// Clearing Data

export const CLEAR_UNIQUE_LOGIN_USERS = "CLEAR_UNIQUE_LOGIN_USERS";
export const CLEAR_REPORT_DATA = "CLEAR_REPORT_DATA";
export const CLEAR_ADDUSER_DATA = "CLEAR_ADDUSER_DATA";
export const CLEAR_BUSSUNIT_DATA = "CLEAR_BUSSUNIT_DATA";
export const CLEAR_DELUSER_DATA = "CLEAR_DELUSER_DATA";
export const CLEAR_EDITUSER_DATA = "CLEAR_EDITUSER_DATA";
export const CLEAR_CURRENTUSER_DATA = "CLEAR_CURRENTUSER_DATA";
export const CLEAR_REPORTNAME_DATA = "CLEAR_REPORTNAME_DATA";
export const CLEAR_REPORTROOM_DATA = "CLEAR_REPORTROOM_DATA";
export const CLEAR_GETUSERDETAIL_DATA = "CLEAR_GETUSERDETAIL_DATA";
export const CLEAR_LOADER_DATA = "CLEAR_LOADER_DATA";
export const CLEAR_LOGIN_DATA = "CLEAR_LOGIN_DATA";
export const CLEAR_MODAL_DATA = "CLEAR_MODAL_DATA";
export const CLEAR_ROOM_DATA = "CLEAR_ROOM_DATA";
export const CLEAR_USERDETAIL_DATA = "CLEAR_USERDETAIL_DATA";
export const CLEAR_VIEWUSERDETAIL_DATA = "CLEAR_VIEWUSERDETAIL_DATA";
export const CLEAR_BACKGROUND_IMAGE_DATA = "CLEAR_BACKGROUND_IMAGE_DATA";
export const CLEAR_CHAT_DATA = "CLEAR_CHAT_DATA";
export const CLEAR_GROUP_ROOM_DATA = "CLEAR_GROUP_ROOM_DATA";
