import { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux";
import "./styles.css";
import SearchDropdown from "../../../components/SearchDropdown";
import { getRoomsAction } from "../../../actions/roomActions";
import { createGroupRoom, editGroupRoom } from "../../../services/groupRoom";
import { toast } from "react-toastify";
import { getBussUnitsAction } from "../../../actions/bussUnitActions";
import { getRoomsByBussUnit } from "../../../services/rooms";

interface addEditGroupRoomProps {
  editGroupData: {
    groupname: string;
    rooms: Array<string>;
    bussunit: string;
  };
  type: string;
  closeAddEditModal: () => any;
}

const AddEditGroupRoom = (props: addEditGroupRoomProps) => {
  const [selectedRooms, setSelectedRooms] = useState(() => new Set());
  const [selectedRoomsError, setSelectedRoomsError] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const [allRooms, setAllRooms] = useState([]);
  const allRoomsForDirector = useSelector((state: RootState) =>
    state.roomsData.rooms
      .filter(
        (value: any) => !value.tempRoom && value.roomtype === "supervisor"
      )
      .map((value: any) => value.roomname)
  );
  const bussUnits = useSelector((state: RootState) => state.bussUnit.bussUnits);
  const selfRole = useSelector((state: RootState) => state.userDetails.role);
  const selfBussUnit = useSelector(
    (state: RootState) => state.userDetails.bussunit
  );

  const [bussValue, setBussValue] = useState("");
  const [bussUnitError, setBussUnitError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    dispatch(getRoomsAction());
    dispatch(getBussUnitsAction());
  }, [dispatch]);

  useEffect(() => {
    if (props.type === "edit") {
      setGroupName(props.editGroupData.groupname);
      const next = new Set(props.editGroupData.rooms);
      setSelectedRooms(next);
      setBussValue(props.editGroupData.bussunit);
      fetchRooms(props.editGroupData.bussunit);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  const fetchRooms = async (bussUnit: string) => {
    let roomValue = await getRoomsByBussUnit(bussUnit);
    roomValue = roomValue.rooms.map((value) => value.roomname);
    setAllRooms(roomValue);
  };

  const setRoom = (val: string) => {
    if (selectedRooms.has(val)) {
      unselectRoom(val);
    } else {
      setSelectedRooms((prev) => {
        const next = new Set(prev); // create a new Set from the old one

        next.add(val); // add a value to the new Set

        return next;
      });
    }
  };

  const unselectRoom = (val: string) => {
    setSelectedRooms((prev) => {
      const next = new Set(prev); // create a new Set from the old one

      next.delete(val); // remove a value from the new Set

      return next;
    });
  };

  const createOrEditRoom = async () => {
    let a = true;
    setSelectedRoomsError("");
    setGroupNameError("");

    if (groupName === "") {
      a = false;
      setGroupNameError("Group Name cannot be blank");
    }

    if (groupName.length > 20) {
      a = false;
      setGroupNameError("Group name can't be more than 20 character");
    }

    if (selectedRooms.size <= 0) {
      a = false;
      setSelectedRoomsError("Rooms cannot be blank");
    }

    if (bussValue === "" && selfRole === "Admin") {
      a = false;
      setSelectedRoomsError("must select on of the buss unit");
    }

    if (a) {
      const rooms = Array.from(selectedRooms) as Array<string>;
      const finalBussUnitValue =
        selfRole === "Admin" ? bussValue : selfBussUnit;
      let value;
      if (props.type === "edit") {
        value = await editGroupRoom(
          groupName.replace(" ", ""),
          rooms,
          finalBussUnitValue
        );
      } else {
        value = await createGroupRoom(
          groupName.replace(" ", ""),
          rooms,
          finalBussUnitValue
        );
      }
      console.log(value);
      if (value.success) {
        toast(
          `${value.groupname} has been ${
            props.type === "edit" ? "updated" : "created"
          }`
        );
      } else {
        toast(`${value.message} `);
        return;
      }

      props.closeAddEditModal();
    }
  };

  const handleGroupName = (e: any) => {
    setGroupNameError(``);
    if (e.target.value.length > 20) {
      setGroupNameError(`Group name can't be more than 20 character`);
      return;
    }
    setGroupName(e.target.value.trim());
  };

  const handleBussUnit = async (e: any) => {
    setBussUnitError("");
    setBussValue(e.target.value);
    let roomValue = await getRoomsByBussUnit(e.target.value);
    roomValue = roomValue.rooms.map((value) => value.roomname);
    setAllRooms(roomValue);
    setSelectedRooms(new Set());
  };

  return (
    <section className="add_user">
      <div className="container">
        <div className="row">
          <div className="col-md-10">
            <form
              className="add_form"
              onSubmit={(e: any) => {
                e.preventDefault();
                createOrEditRoom();
              }}
            >
              <div className="group-selected-value">
                <p>Group Name *</p>
                <input
                  value={groupName}
                  type="text"
                  name="Group Name"
                  disabled={props.type === "edit" ? true : false}
                  onChange={(e) => {
                    handleGroupName(e);
                  }}
                  style={{ cursor: "text" }}
                />
                <p className="red-alert">{groupNameError} </p>
              </div>
              {selfRole === "Admin" ? (
                <div className="group-selected-value">
                  <p>Please select Business Unit *</p>
                  <select id="role" onChange={handleBussUnit} value={bussValue}>
                    <option value="" disabled>
                      Please Select
                    </option>
                    {bussUnits.map((value) => (
                      <option key={value._id} value={value.bussunit}>
                        {value.bussunit}
                      </option>
                    ))}
                  </select>
                  <p className="red-alert">{bussUnitError}</p>
                </div>
              ) : null}

              <div className="selected-rooms">
                {Array.from(selectedRooms, (value: any) => (
                  <>
                    <Badge variant="info">
                      {value}
                      <Badge
                        variant="danger"
                        onClick={() => unselectRoom(value)}
                        style={{ cursor: "pointer" }}
                      >
                        X
                      </Badge>
                    </Badge>{" "}
                  </>
                ))}
              </div>
              <p>Select Rooms *</p>
              <SearchDropdown
                options={selfRole === "Admin" ? allRooms : allRoomsForDirector}
                id="id"
                selectedVal={selectedValue}
                multiSelect={true}
                handleChange={(val) => setSelectedValue(val)}
                handleSelect={(val) => {
                  setSelectedRoomsError("");
                  setRoom(val);
                }}
                multiValue={selectedRooms}
              />
              <p className="red-alert">{selectedRoomsError} </p>

              <p>&nbsp;</p>

              <button className="link marg-up-10" value="register">
                Done
              </button>
            </form>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </section>
  );
};

export default AddEditGroupRoom;
