import React from "react";
import {IInputProps} from "./interface"
import "./styles.css"


const Input = (props: IInputProps) => {
  const {name, type, placeholder, value, onChange} = props
  return (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoComplete={type === 'password' ? "new-password"  : "off"}
    />
  );
};

export default Input;
