import {
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,
    CLEAR_ADDUSER_DATA,
    SET_MFA,
    SET_APP_UPDATE,
    ENABLE_DESKTOP_APP
  } from "../actions/actionTypes";
  
  const initialState = {
    isUserCreated: false,
    mfa:false,
    complexPassword: false,
    error: "",
    appUpdate:false,
    enableDesktopApp:false
  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const addUserReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
      case ADD_USER_SUCCESS: {
        return {
          ...state,
          isUserCreated: true,
        };
      }
      case ADD_USER_FAILURE: {
        return {
          ...state,
          isUserCreated: false,
          error: action.payload.error,
        };
      }
      case SET_MFA: {
        return {
          ...state,
          mfa: action.payload.mfa,
        };
      }
      case SET_APP_UPDATE:{
        return{
          ...state,
          appUpdate:action.payload.appUpdat,
        }
      }
      case ENABLE_DESKTOP_APP:{
          return{
            ...state,
            enableDesktopApp:action.payload.enableDesktopApp
          }
      }
      case CLEAR_ADDUSER_DATA:{
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default addUserReducer;
  