import {
    GET_UNIQUE_LOGIN_USERS_SUCCESS,
    CLEAR_UNIQUE_LOGIN_USERS,
    GET_USERS_BY_ROOM
} from "../actions/actionTypes";
  
  const initialState = {
    uniqueUsers: [],
    uniqueUsersLength:0,
    usersByRoom:[]
  };
  interface IAction {
    type: string;
    payload: any;
  }
  
  const uniqueLogInUsers = (state = initialState, action: IAction) => {
    switch (action.type) {
      case GET_UNIQUE_LOGIN_USERS_SUCCESS: {
        return {
          ...state,
          uniqueUsers: action.payload.allUsers,
          uniqueUsersLength:action.payload.usersLength
        };
      }

      case GET_USERS_BY_ROOM:{
        return{
          ...state,
          usersByRoom:action.payload
        }
      }
     
      case CLEAR_UNIQUE_LOGIN_USERS: {
        return initialState
      }
      default: {
        return state;
      }
    }
  };
  
  export default uniqueLogInUsers;
  