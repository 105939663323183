/* eslint-disable jsx-a11y/img-redundant-alt */
import { IRoomCardProps } from "./interface";
import NameAvatar from "./../NameAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faTrash,
  faEdit,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { Fragment, useState, useEffect } from "react";
import "./styles.css";
import { OPEN_DEL_MODAL, DEL_ROOM_SUCCESS } from "../../actions/actionTypes";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import { RootState } from "../../redux";
import { updateRoom } from "../../actions/roomActions";

interface IMembers {
  name?: string;
}

interface IMemberList {
  members: IMembers[];
}

const MemberList = (members: IMemberList) => {
  if (members && members.members && members.members.length) {
    return (
      <Fragment>
        {members.members.slice(0, 5).map((member: any, index: number) => {
          return <NameAvatar key={index} text={member.name} />;
        })}

        {members.members.length > 5 ? (
          <div className="pravatar">
            <p className="centered">+{members.members.length - 5}</p>
          </div>
        ) : null}
      </Fragment>
    );
  } else {
    return <Fragment></Fragment>;
  }
};

const RoomCard = (props: IRoomCardProps) => {
  const {
    name = "",
    members = [],
    background,
    colors,
    image,
    bussUnit,
    roomtype,
    audioAllowedInRoom,
    maxParticipantCount,
  } = props;
  const dispatch = useDispatch();

  const imageUrl =
    process.env.REACT_APP_API_URL + "/getscreenshotimage/" + image;

  const bussUnitsList = useSelector(
    (state: RootState) => state.bussUnit.bussUnits
  );
  const [roomTypeData, ] = useState([
    "Supervisor Room",
    "Meeting Room",
  ]);

  const [info, setInfo] = useState(false);

  const [partinfo, setPartInfo] = useState(false);
  const [teaminfo, setTeamInfo] = useState(false);
  const [roomtypeinfo, setRoomTypeInfo] = useState(false);

  const [show, setShow] = useState(false);

  const [roomNameTemp,setroomNameTemp] = useState(name);
  const [maxParticipantsTemp, setMaxParticipants] =
    useState(maxParticipantCount);
  // const [imageNameTemp, setImageName] = useState(image);
  const [bussUnitTemp, setBussUnit] = useState(bussUnit);
  const [tempImg, setTempImg] = useState<any>("");
  const [img,setImg] = useState<any>();
  const [roomTypeTemp, setRoomType] = useState("");
  const [audioAllowedInRoomTemp, setAudioAllowedInRoom] = useState(true);
  const [imageName, setImageName] = useState(null);
  const loading = useSelector((state: RootState) => state.loading.isLoading);
  const [allowPrivateCallinSilentRoom, setCallAllowedInRoom] = useState(true);

  const modalOpen = (roomname: string) => {
    dispatch({
      type: OPEN_DEL_MODAL,
      roomName: roomname,
    });
    dispatch({
      type: DEL_ROOM_SUCCESS,
      payload: `Are you sure you want to delete ${roomname}?`,
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = (room:any) => {
    setroomNameTemp(room.roomname);
    setBussUnit(room.bussunit);
    setRoomType(room.roomtype);
    setMaxParticipants(room.maxParticipantCount);
    setAudioAllowedInRoom(audioAllowedInRoom);
    //@ts-ignore
    if (roomtype === "meeting") {
      setRoomType("Meeting Room");
    } else if (roomtype === "supervisor") {
      setRoomType("Supervisor Room");
    }
    setShow(true);
  };

  const handleBussUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBussUnit(e.target.value);
  };

  const handleRoomType = (value: string) => {
    setRoomType(value);
  };

  const handleMaxParticipantsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxParticipants(e.target.value);
  };

  const handlePictureUrlChange = (e: any) => {
    console.log(e.target.files[0])
    const ext = e.target.files[0]?.name.split(".").pop();
    if (ext === "png" || ext === "jpg" || ext === "jpeg") {
      setImageName(e.target.files[0]);
      setTempImg(e.target.files[0]?.name);
      // @ts-ignore
      setImg(URL.createObjectURL(e.target.files[0]));
      // console.log("IF", { imageName });
    } else {
      setImageName(null);
      setTempImg(image);
      // console.log("ELSE", { imageName });
    }
  };

  const handleAudioSwitch = (checked: boolean) => {
    setAudioAllowedInRoom(checked);
  };

  const handleCallSwitch = (checked: boolean) => {
    setCallAllowedInRoom(checked);
  }

  var bussUnitsDisplay = bussUnitsList.map((bussUnitObject: any) => (
    <option key={bussUnitObject._id} value={bussUnitObject.bussunit}>
      {bussUnitObject.bussunit}
    </option>
  ));

  let roomTypeDisplay = roomTypeData.map((roomTypeValue: string) => (
    <option key={roomTypeValue} value={roomTypeValue}>
      {roomTypeValue}
    </option>
  ));

  const handleEditRoom = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loading) return;
    if (
      maxParticipantsTemp === "" ||
      bussUnitTemp === "" ||
      roomTypeTemp === ""
    ) {
      if (maxParticipantsTemp === "") {
        setPartInfo(true);
      } else {
        setPartInfo(false);
      }
      if (bussUnitTemp === "") {
        setTeamInfo(true);
      } else {
        setTeamInfo(false);
      }
      if (roomTypeTemp === "") {
        setRoomTypeInfo(true);
      } else {
        setRoomTypeInfo(false);
      }

      setInfo(true);
    } else {
      setInfo(false);
      let oldImageUrl = image;
      //@ts-ignore
      let roomTypeValue = roomTypeTemp.split(" ")[0].toLowerCase();
      await dispatch(
        updateRoom(
          roomNameTemp,
          maxParticipantsTemp,
          //@ts-ignore
          bussUnitTemp,
          roomTypeValue,
          audioAllowedInRoomTemp,
          imageName,
          oldImageUrl,
          allowPrivateCallinSilentRoom
        )
      );

      handleClose();
      setImg(null);
    }
    //dispatch(addRoom(roomName, maxParticipants));
  };


  useEffect(() => {
    setTempImg(image);
  }, [image]);
  return (
    <li>
      {/* <Link to={`add_room/room/${name}`}> */}
      <div
        className="box"
        style={{ backgroundColor: background, color: colors }}
      >
        <div className="imgContainer">
          <div>
            <h4 className="roomname_heading" title={name} style={{ color: colors }}>{name.length > 23 ? name.substring(0,24):name}</h4>
            <h6 style={{ color: colors }}>
              ({bussUnit}-{roomtype})
            </h6>
          </div>
          <div className="del_img">
            <div className="del_bor" onClick={() => handleShow(props.room)}>
              <FontAwesomeIcon icon={faEdit} />
            </div>
          </div>
          <div className="del_img">
            {image && <img className="imgSize" src={imageUrl} alt="new" />}
            <div className="del_bor" onClick={() => modalOpen(name)}>
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="img_usr">
          <MemberList members={members} />
          <div className="ib">{members.length}</div>
        </div>
        <div className="vcm">
          <span>
            <FontAwesomeIcon icon={faVideo} />
          </span>
        </div>
      </div>
      {/* </Link> */}

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Edit Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEditRoom}>
            <div className="roomname-container">
              <h6>Room Name</h6>
              <FontAwesomeIcon
                onClick={() => {
                  setInfo(!info);
                }}
                icon={faInfoCircle}
              />
            </div>
            <div className="input-container">
              <input
                type="text"
                name="room-name"
                placeholder="Room Name"
                value={name}
                pattern="[^\s]+"
                readOnly
                disabled
              />
            </div>
            <h6>Max. Number of Participants</h6>
            <div className="input-container">
              <input
                type="number"
                name="max-participants"
                placeholder=""
                value={maxParticipantsTemp}
                onChange={handleMaxParticipantsChange}
                min={5}
                max={100}
              />
              {info && partinfo && (
                <span className="error-msg">
                  Should have minimum one participant
                </span>
              )}
            </div>

            <h6>Please select Business Unit</h6>
            <div className="input-container">
              <select id="team" onChange={handleBussUnit} value={bussUnitTemp}>
                <option value="" disabled>
                  Please Select Business Unit
                </option>
                {bussUnitsDisplay}
              </select>
              {
                <span className="error-msg">
                  {bussUnitsList.length <= 0
                    ? "There are no Business units created. Please create a Business unit first"
                    : null}
                </span>
              }
              {info && teaminfo && (
                <span className="error-msg">
                  Business unit cannot be blank.
                </span>
              )}
            </div>

            <h6>Please select Room Type</h6>
            <div className="input-container">
              <select
                id="team"
                onChange={(e: any) => handleRoomType(e.target.value)}
                value={roomTypeTemp}
              >
                <option value="" disabled>
                  Please Select Room Type
                </option>
                {roomTypeDisplay}
              </select>

              {info && roomtypeinfo && (
                <span className="error-msg">Room Type cannot be blank.</span>
              )}
            </div>
{
  tempImg ? <div className="input-container">
  <h6>Logo Icon</h6>
  <div className="input-container-subdiv">
    <input
      type="file"
      name="picture-Url"
      placeholder=""
      id="logo"
      accept="image/jpg, image/jpeg, image/png"
      onChange={handlePictureUrlChange}
      style={{ display: "none" }}
    />
    <div className="logo-cont">
      <label className="logo-btn" htmlFor="logo">
        Choose File{" "}
      </label>
      <span>{tempImg?.split("/").pop()}</span>
    </div>
    {/* // eslint-disable-next-line jsx-a11y/img-redundant-alt */}
    <img className="form-img" src={img ? img : imageUrl} alt="image" />
  </div>
</div>:""
}

            <div className="input-container">
              <h6>Audio permissions in Room</h6>
              <label style={{ float: "left" }}>
                <Switch
                  onChange={handleAudioSwitch}
                  checked={audioAllowedInRoomTemp}
                  className="react-switch"
                />{" "}
                &nbsp;&nbsp;
                <span
                  className={`toggle-label ${
                    audioAllowedInRoomTemp && "toggle-enabled"
                  }`}
                >
                  {audioAllowedInRoomTemp
                    ? "Everyone Can Speak" : "Nobody Can Speak"}
                </span>
              </label>
            </div>
        {!audioAllowedInRoomTemp &&
              <div> <br/> <br/>
                <h6>Private Call in Silent Room</h6>
                <label style={{ float: "left" }}>
                  <Switch
                    onChange={handleCallSwitch}
                    checked={allowPrivateCallinSilentRoom}
                    className="react-switch"
                  /> &nbsp;&nbsp;
                  <span className={`toggle-label ${allowPrivateCallinSilentRoom && "toggle-enabled"}`}>
                    {allowPrivateCallinSilentRoom ? "Private Call Allowed" : "Private Call not Allowed"}
                  </span>
                </label>
              </div>
        }
            <div className="submit-btn">
              <button
                className="link mt-3"
                type="submit"
                disabled={loading || bussUnitsList.length <= 0}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </li>
  );
};

export default RoomCard;
