import request from "./index";
import qs from "querystring";

interface sendData {
  accountsid:string,
  authtoken:string,
  date:string,  
  mobile?:string,
  mobileotpmessage?:string,
}

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const saveTwilioSetting = async ( AccountSid : string, AuthToken : string, date:string,mobile?:string,mobileotpmessage?:string) => {
  let data :sendData = {    
    accountsid: AccountSid,
    authtoken: AuthToken,
    date:date,
    mobile:mobile,
    mobileotpmessage:mobileotpmessage,   
  } 
  
  const requestBody = qs.stringify({...data});

  const response = await request.post("/savetwiliosetting", requestBody, config);

  return response.data;
};