import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getGroupRooms = async () => {
  const groupRoomsResponse = await request.get("/getGroupRooms");
  return groupRoomsResponse.data;
};

export const createGroupRoom = async (
  groupname: string,
  rooms: Array<string>,
  bussunit: string
) => {
  const reqBody = qs.stringify({
    groupname,
    rooms,
    bussunit,
  });

  const createGroupRoomResponse = await request.post(
    "/createGroupRoom",
    reqBody,
    config
  );
  return createGroupRoomResponse.data;
};

export const deleteGroupRoom = async (groupname: string) => {
  const reqBody = qs.stringify({
    groupname,
  });

  const delGroupRoomResponse = await request.post(
    "/deleteGroupRoom",
    reqBody,
    config
  );
  return delGroupRoomResponse.data;
};

export const editGroupRoom = async (
  groupname: string,
  rooms: Array<string>,
  bussunit: string
) => {
  const reqBody = qs.stringify({
    groupname,
    rooms,
    bussunit,
  });

  const editGroupRoomResponse = await request.post(
    "/editGroupRoom",
    reqBody,
    config
  );
  return editGroupRoomResponse.data;
};
