import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

// export const getRooms = async () => {
//   const userDetailsResponse = await request.get("/getRooms");
//   return userDetailsResponse.data;
// };

export const addBackgroundImage = async (imageName: any, company: string) => {
  const config_img = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const data = new FormData();

  data.append("file", imageName);
  data.append("company", company);

  const uploadResponse = await request.post(
    "/uploadBackgroundImg",
    data,
    config_img
  );
  return uploadResponse.data;
};

export const getAllBackgroundImage = async (company: string) => {
  const reqBody = qs.stringify({
    company: company,
  });

  const allBackgroundImage = await request.post(
    "/getAllBackgroundImage",
    reqBody,
    config
  );
  return allBackgroundImage.data;
};

export const deleteBackgroundImage = async (
  filename: string,
  company: string
) => {
  const reqBody = qs.stringify({
    filename,
    company,
  });

  const uploadResponse = await request.post(
    "/deleteBackgroundImage",
    reqBody,
    config
  );
  return uploadResponse.data;
};
