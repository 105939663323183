import React, { useEffect, useState } from "react";
import "./WebsitesActivity.css";
import Header from "../../components/Header";
import { Button, Table, Badge } from "react-bootstrap";
import { DateTimePicker } from "react-rainbow-components";
import { getWebsitesActivity } from "../../services/desktopActivity";
// import DesktopChart from "../../components/DesktopChart/DesktopChart";
import DesktopActivityModal from "../../components/DesktopActivityModal/DesktopActivityModal";
import { reportAPI } from "../../utils/api";
import { timeZoneFormat } from "../../utils/dateformat";
import moment from "moment";
import { setLoading } from "../../actions/loaderActions";
import ActivityChart from "../../components/ActivityChart";
import { Modal } from "react-bootstrap";
import { ModalBarChart } from "../../components/ModalBarChart";
import { FormControl,Autocomplete, TextField} from "@mui/material";

function MyVerticallyCenteredModal(props) {
  const { activityDetailed, startDate, endDate, totalTime } = props;
  console.log({ activityDetailed });
  const username = activityDetailed[0]?.username;
  const { format } = timeZoneFormat();

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      dialogClassName=" modal-height-dialog"
      contentClassName="modal-height"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="modal-header-container">
            <p>
              {username} <span> Total Time - {totalTime}</span>
            </p>
            <p>{`(${moment(startDate).format(format).split(" ")[0]} - ${
              moment(endDate).format(format).split(" ")[0]
            })`}</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ModalBarChart activityDetailed={activityDetailed} />
      </Modal.Body>
    </Modal>
  );
}

const WebsitesActivity = (props: any) => {
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [name, setName] = useState("");
  const [activity, setActivity] = useState([]);
  const [activityDetailed, setActivityDetailed] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, ] = useState({});
  const [userTotalTime, ] = useState(0);
  const [nameArray, setNameArray] = useState([]);
  // const userRole = useSelector((state: RootState) => state.userDetails.role);
  const [, setRoomArray] = useState([]);
  const [room, ] = useState("");
  const [bussUnit, setBussunit] = useState("");
  const [bussUnits, setBusUnits] = React.useState([]);
  const [open, ] = useState(false);
  const [totalTime, setTotaltime] = useState("");
  const [metricsSummary, setMetricsSummary] = useState<
    Array<any>
  >([]);
  const formatDate = (date) => {
    var d = new Date(date);
    return d.toUTCString();
  };

  const getuser = () => {
    reportAPI.gettAll().then((res) => {
      let labeledUsers = [{label: "All Users", value: "All"}];
      // eslint-disable-next-line array-callback-return
      res.data.map((user: any, index) => {
        if (user && user.username) {
          // @ts-ignore
          labeledUsers.push({ label: user.username, value: user.username });
        }
      });
      labeledUsers.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return labelA.localeCompare(labelB);
      });
      //@ts-ignore
      setNameArray(labeledUsers);
    });
  };
  const getrooms = () => {
    reportAPI.gettRooms().then((res) => {
      let labeledUsers = [];
      // eslint-disable-next-line array-callback-return
      res.data.map((roomValue: any, index) => {
        if (roomValue) {
          // @ts-ignore
          labeledUsers.push(roomValue.roomname);
        }
      });
      setRoomArray(labeledUsers);
    });
  };
  const getbussunit = () => {
    const filteredBussUnits = [{label: "All Business unit", value: "All"}];
    reportAPI.getBussUnits().then((res) => {
      // eslint-disable-next-line array-callback-return
      res.data.map((unit) => {
        // @ts-ignore
        filteredBussUnits.push({label: unit.bussunit, value: unit.bussunit});
      });
    });
    //@ts-ignore
    setBusUnits(filteredBussUnits);
  };
  function EnableF() {
    getuser();
    getrooms();
    getbussunit();
  }

  const handleDatechange = (e: any, flag) => {
    if (flag === "start") {
      setStartdate(new Date(e));
    } else {
      setEnddate(new Date(e));
    }
  };

  const handleNameChange = (s: any) => {
    console.log({ s });
    if (s.value === "All") {
      setName("");
      return;
    }
    setName(s.value);
  };

  
  const handleBussUnitChange = (r: any) => {
    if (r.value === "All") {
      setBussunit("");
      return;
    } else {
      setBussunit(r.value);
    }
    return;
  };

  // function calculateSumOfAttribute(arr,attribute){
  //   let sum = 0;
  //   arr.map((item) => {
  //     sum += item.attribute;
  //   });
  //   return sum;
  // }
  useEffect(() => {
    EnableF();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  useEffect(() => {
    if (activity && activity.length) {
      const sumProductiveTime = activity.reduce(
        // @ts-ignore
        (sum, item) => sum + item.totalProductiveTime,
        0
      );
      const sumNonProductiveTime = activity.reduce(
        // @ts-ignore
        (sum, item) => sum + item.totalNonProductiveTime,
        0
      );
      const sumNeutralTime = activity.reduce(
        // @ts-ignore
        (sum, item) => sum + item.totalNeutralTime,
        0
      );

      // Get the length of the array
      const arrayLength = activity.length;

      const newSummary = [
        { title: "Number of Users:", value: arrayLength },
        {
          title: "Total Productive Time:",
          value: secondsToHms(sumProductiveTime),
        },
        {
          title: "Total Non-Productive Time:",
          value: secondsToHms(sumNonProductiveTime),
        },
        { title: "Total Unclassified Time:", value: secondsToHms(sumNeutralTime) },
        
      ];
      setMetricsSummary(newSummary);
      console.log("----->", {
        metricsSummary,
        sumNeutralTime,
        sumProductiveTime,
        sumNonProductiveTime,
      });
    }
    // console.log("----->mee",{metricsSummary})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second " : " seconds") : "";

    return hDisplay + mDisplay + sDisplay;
  };

  const getSumOfWholeTime = (productive, nProductive, neutral) => {
    let totalTime = productive + nProductive + neutral;
    let secToHms = secondsToHms(totalTime);
    return secToHms;
  };

  const DoSearch = async () => {
    setLoading(true);
    try {
      const desktopActivity = await getWebsitesActivity(
        formatDate(startdate),
        formatDate(enddate),
        name,
        "true",
        bussUnit,
        room
      );
      console.log("Abstract: ", desktopActivity);
      if (desktopActivity.status) {
        setActivity(desktopActivity.data);
      }
      setLoading(false);
    } catch (e) {
      console.log("could not fetch data");
      setLoading(false);
    }
  };

  // console.log({ activityDetailed });
  const doSearchForUser = async (username, totalTime) => {
    setTotaltime(totalTime);
    setModalShow(true);
    setLoading(true);
    try {
      const desktopActivity = await getWebsitesActivity(
        formatDate(startdate),
        formatDate(enddate),
        username,
        "false",
        "",
        ""
      );
      console.log("Detailed: ", desktopActivity);
      if (desktopActivity.status) {
        setActivityDetailed(desktopActivity.data);
      }
      setLoading(false);
    } catch (e) {
      console.log("could not fetch data");
      setLoading(false);
    }
  };

  console.log({ startdate, enddate });
  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">Websites Activity</span>

        <div className="datepicker-container">
          <DateTimePicker
            value={startdate}
            label="Start"
            onChange={(e: any) => handleDatechange(e, "start")}
            className="rainbow-m-around_small datepicker-space"
            hour24
            // format="yyyy-MM-dd HH:mm:ss"
          />
          <DateTimePicker
            value={enddate}
            label="End"
            onChange={(e: any) => handleDatechange(e, "end")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <br />

          <div className="text-center mt-4">
            <Button id="search" variant="info" onClick={() => DoSearch()}>
              Search
            </Button>
          </div>
        </div>
        <div className="filters-container mt-4" style={{marginLeft: "auto", width: "50%"}}>
              <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // name="username"
                          value={name}
                          options={nameArray}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="All User" />
                          )}
                          onChange={(event, value) => handleNameChange(value)}
                        />
                </FormControl>

                <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          // name="username"
                          value={bussUnit}
                          options={bussUnits}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="All Business unit" />
                          )}
                          onChange={(event, value) => handleBussUnitChange(value)}
                        />
                </FormControl>
        </div>
      </nav>
      <div className="container-fluid hw" id="main">
        {metricsSummary && metricsSummary.length ? (
          <div className="stats-container">
            {metricsSummary.map((val) => (
              <div>
                <h1>{val.title}</h1>
                <p>{val.value}</p>
              </div>
            ))}

           
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="report-container">
            <div className="header">
              <h1 className="session-chart">Websites Activity</h1>
              <div className="disp-flex-left"></div>
            </div>

            <Table striped bordered hover>
              <thead className="activity-thead">
                <tr>
                  <th>Username</th>
                  <th>Applications Used</th>
                  <th>Activity</th>
                  <th>Total Tracking Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/* @ts-ignore  */}
                {activity
                  ? activity?.map((val, key) => {
                      const {
                        username,
                        totalProductiveTime,
                        totalNonProductiveTime,
                        totalNeutralTime,
                        allApps,
                      } = val;
                      const control = `example-collapse-text-${key}`;
                      return (
                        <>
                          <tr key={key}>
                            <td
                              className="analytics"
                              aria-controls={control}
                              aria-expanded={open}
                            >
                              {username}
                            </td>

                            <td>
                              {/* @ts-ignore */}
                              {allApps.map(
                                (app, index) =>
                                  index < 7 && (
                                    <Badge className="badge-info mr-1">
                                      {app}
                                    </Badge>
                                  )
                              )}
                              {
                                // @ts-ignore 
                                allApps && allApps.length > 7 ?
                                <>
                                ... +
                                <Badge className="badge-warning mr-1">
                                  {/* @ts-ignore */}
                                  {allApps.length - 7}
                                </Badge>
                                </>:""
                              }
                              
                            </td>

                            <td>
                              <ActivityChart
                                totalProductiveTime={totalProductiveTime}
                                totalNonProductiveTime={totalNonProductiveTime}
                                totalNeutralTime={totalNeutralTime}
                                secondsToHms={secondsToHms}
                              />
                            </td>
                            <td>
                              <p>
                                {getSumOfWholeTime(
                                  totalProductiveTime,
                                  totalNonProductiveTime,
                                  totalNeutralTime
                                )}
                              </p>
                            </td>
                            <td>
                              <Button
                                className="view-details-btn"
                                variant="info"
                                size="sm"
                                onClick={() =>
                                  doSearchForUser(
                                    username,
                                    getSumOfWholeTime(
                                      totalProductiveTime,
                                      totalNonProductiveTime,
                                      totalNeutralTime
                                    )
                                  )
                                }
                              >
                                View Details
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : ""}
              </tbody>
            </Table>
            <DesktopActivityModal
              setModal={modalOpen}
              setModalOpen={setModalOpen}
              totalTime={userTotalTime}
              activity={modalData}
            />
          </div>
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        activityDetailed={activityDetailed}
        startDate={startdate}
        endDate={enddate}
        totalTime={totalTime}
      />
    </>
  );
};

export default WebsitesActivity;
