import axios from "axios";
import {getActivityChannelUrl, getToken} from "../utils/Token";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": getToken()
  },
};

export const getTopics = async () => {
  config['headers']['Authorization'] = getToken();
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
      baseURL: activityChannelBaseUrl + "/TrainingRoom",
      withCredentials: true,
  });

  const topicsResponse = await requestActivityChannel.get("/getTopics", config);
  return topicsResponse.data;
};

export const addTopic = async (
  username: String,
  topic: String,
  description: String,
  bussunit: String,
  permissions: Object
) => {
  let body = {
    username,
    topic,
    description,
    bussunit,
    permissions
  };
  config['headers']['Authorization'] = getToken();
  // if(option === 'read'){
  //   body = {
  //     ...body,
  //     read:true,
  //     write:false
  //   }
  // }
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
      baseURL: activityChannelBaseUrl + "/TrainingRoom",
      withCredentials: true,
  });

  const topicsResponse = await requestActivityChannel.post(
    "/createTopic",
    body,
    config
  );
  return topicsResponse.data;
};

export const editTopic = async (
  topicId: string,
  topic: string,
  description: string,
  client: string,
  bussunit: string,
  permissions: Object
) => {
  let body = {
    topicId,
    topic,
    description,
    client,
    bussunit,
    permissions
  };
  config['headers']['Authorization'] = getToken();
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
      baseURL: activityChannelBaseUrl + "/TrainingRoom",
      withCredentials: true,
  });

  const topicsResponse = await requestActivityChannel.post(
    "/updateTopic",
    body,
    config
  );
  return topicsResponse.data;
};

export const deleteTopic = async (topicId: String) => {
  config['headers']['Authorization'] = getToken();
  let body = {
    topicId: topicId,
  };
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
      baseURL: activityChannelBaseUrl + "/TrainingRoom",
      withCredentials: true,
  });

  const topicsResponse = await requestActivityChannel.post(
    "/deleteTopic",
    body,
    config
  );
  return topicsResponse.data;
};
