import { useEffect, useState } from "react";
import "./AddLogo.css";
import Header from "./../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { Alert, Spinner } from "react-bootstrap";
import React from "react";
import {getEnvironmentsAction} from "../../actions/environmentActions";
import {addLogoAction} from "../../actions/addLogo";

const AddLogo = () => {
    const dispatch = useDispatch();

    const environment = useSelector((state: RootState) => state.environmentsReducers.environments);
    const isLogoUpdated = useSelector((state: RootState) => state.addLogoReducer.isLogoUpdated);
    const [logoName, setLogoName] = useState(null);
    const [logoSizeError, setLogoSizeError] = useState("")
    const [logoDimensionError, setLogoDimensionError] = useState("")
    const [loader, setLoader] = useState(false);
    const [fileFormatError, setFileFormatError] = useState("");

    if(isLogoUpdated) {
        console.log("logo updated");
    }
    const handleLogoChange = (e: any) => {
        setLogoName(e.target.files[0]);
        setFileFormatError("");
        setLogoSizeError("");
        setLogoDimensionError("");
        let logoSize = Math.floor(e.target.files[0].size/1000000);
        const supportedFormats = ["png", "jpg", "jpeg", "svg", "PNG", "JPG", "JPEG", "SVG"];
        const fileFormat = e.target.files[0].type.split("/")[1]

        const img = new Image();
        img.src = URL.createObjectURL(e.target.files[0])

        img.onload = () => {
            console.log(img.height);
            console.log(img.width);
            let logoHeight = img.height
            let logoWidth = img.width
            if(logoHeight > 150 || logoWidth > 300) {
                setLogoDimensionError(
                    `Please upload logo not greater than recommended size 150 X 300, selected logo is of size ${logoHeight} X ${logoWidth}`
                )
            }
        }
        if(logoSize > 1) {
            setLogoSizeError(`You are allowed to upload logo upto 1MB, selected logo is of ${logoSize}MB`);
        }

        if(!supportedFormats.includes(fileFormat)) {
            setFileFormatError("Please select allowed format type, png, jpg, jpeg, svg");
        }

    };
    const uploadLogo = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(logoName && !logoSizeError && !logoDimensionError && !fileFormatError) {
            dispatch(addLogoAction(logoName, setLoader));
        }
    }

    useEffect(() => {
        dispatch(getEnvironmentsAction())
    },[dispatch, isLogoUpdated])

    return (
        <>
            <Header />
            <section className="add_user">

                <div className="container">
                    <div className="row">
                        <div className="col-md-10">
                            <form className="add_form" onSubmit={uploadLogo}>
                                <h1>
                                    <span>Add Logo</span>
                                </h1>
                                {fileFormatError && <Alert variant="danger">{fileFormatError}</Alert>}
                                {logoSizeError && <Alert variant="danger">{logoSizeError}</Alert>}
                                {logoDimensionError && <Alert variant="danger">{logoDimensionError}</Alert>}
                                {isLogoUpdated && <Alert variant="success">Logo updated successfully</Alert>}
                                <p>LOGO * (recommended 150 X 300)</p>
                                <input
                                    accept="image/png, image/jpg, image/jpeg, image/svg"
                                    type="file"
                                    name="logo"
                                    placeholder="logo file"
                                    onChange={handleLogoChange}
                                />

                                <p>&nbsp;</p>

                                <button
                                    className="link marg-up-10"
                                    value="register"
                                    disabled={logoName == null}
                                >
                                    Add Logo {loader ?<Spinner animation={"border"} size="sm"></Spinner> : ""}
                                </button>
                            </form>
                        </div>
                        <div className="col-md-2">
                            {
                                environment.APP_LOGO ?
                                    // eslint-disable-next-line jsx-a11y/alt-text
                                    <img className="mt-5" src={environment.APP_LOGO.value}/>
                                    : ""
                            }

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AddLogo;
