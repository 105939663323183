import { useEffect, useState } from "react";
import "./AdminSetting.css";
import Header from "../../components/Header";
import  { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  handleAdminSettings,
  updateAdminSettingsValue,
  updateAlertPermission,
  updateEmailPermission,
  updateStatus,
  updateValStatus,
  updateViolationPermission,
  updateNonePermission
} from "../../actions/AdminSettings";
import { RootState } from "../../redux";
import { Form } from "react-bootstrap";

import React from "react";
import Switch from "react-switch";

createTheme("solarized", {
  text: {
    primary: "#1e3869",
    secondary: "#1e3869",
  },
  background: {
    default: "#ffffff",
  },
  context: {
    background: "#cb4b16",
    text: "#000000",
  },
  divider: {
    default: "#ada8a8",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

const AdminSettings = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  // const [, setOpt] = useState(true);
  // const [txtval, setTxt] = useState("");
  const [, setIsAdminSettings] = useState(false);

  const handleTxt = (variable: string, value: string) => {
    if (value === "") {
      value = " ";
    }

    if (variable === "LUNCH_TIME_LIMIT_MINUTES" || variable === "BREAK_TIME_LIMIT_MINUTES" || variable === "BRB_TIME_LIMIT_MINUTES") {
      // Validate that the string contains only numbers
      const onlyNumbers = /^[0-9 ]*$/;
      const check = onlyNumbers.test(value);
      if (!check) {
        return;
      }
    }
    dispatch(updateAdminSettingsValue(variable, value));
  };

  // const handleOption = (chk: boolean) => {
  //   setOpt(chk);
  // };

  // const columns = [
  //   {
  //     name: "Option",
  //     sortable: true,
  //     selector: "label",
  //     center: true,
  //   },
  //   {
  //     name: "Status",
  //     sortable: true,
  //     selector: "variable",
  //     center: true,
  //   },

  //   /*
  //    {
  //     name: "Creation Date",
  //     sortable: true,
  //     selector: "Creation_Date",
  //     center: true,
  //   }   */
  // ];

  let settinglist = useSelector(
    (state: RootState) => state.AdminSettingsReducer.settinglist
  );
  useEffect(() => {
    dispatch(handleAdminSettings());
  }, [dispatch]);

  // const getWholeDate = (dateObj: Date) => {
  //   var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  //   var date = ("0" + dateObj.getDate()).slice(-2);
  //   var year = dateObj.getFullYear();
  //   return year + "/" + month + "/" + date;
  // };

  // const getDataObject = (
  //   variable: any,
  //   label: any,
  //   status: any,
  //   value: any
  //   //  creation_date: any,
  // ) => {
  //   // console.log(new Date(creation_date).toISOString())

  //   console.log(value);

  //   const abc = {
  //     variable: variable,
  //     label: label,
  //     status: status,
  //     value: value,
  //     //  Creation_Date: getWholeDate(new Date(creation_date)),
  //   };

  //   //console.log(abc);

  //   return abc;
  // };

  /*
  React.useEffect(() => {
    if (isSettingSaved) {
      // history.go(0);
      setAccountSid("");
      setAuthToken("");
      setMobile("");
    }
  }, [isSettingSaved, history]);
*/

  const handleSetting = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // let a = true;
    //setAccountSid("");
  };

  return (
    <>
      <Header />

      <div className="admin_container mt-3">
        <Tabs
          defaultActiveKey="admin-settings"
          id="justify-tab-example"
          className="mb-1  tabs-container"
          justify
        >
          <Tab
            eventKey="admin-settings"
            className=""
            title="Admin Settings"
            onClick={() => {
              setIsAdminSettings(true);
            }}
          >
            <div className="tab-content-container">
              <form className="add_form" onSubmit={handleSetting}>
                <div className="row all_users">
                  <div className="report-container">
                    <div className="single_user_name">
                      <h5>
                        <b>Option</b>{" "}
                      </h5>
                    </div>
                    <div className="single_user_index">
                      <h5>
                        <b>Status</b>
                      </h5>
                    </div>
                    <div className="single_user_index">
                      <h5>
                        <b>Value</b>
                      </h5>
                    </div>
                  </div>
                </div>
                <span className="line"></span>

                {settinglist.length > 0
                  ? settinglist
                    .filter((val) => val.type === "ADMIN")
                    .map((value, index) => (
                      <React.Fragment key={value._id}>
                        <div className="row all_users">
                          <div className="report-container">
                            <div className="single_user_name">
                              <h5>{value.label}</h5>
                            </div>
                            <div className="single_user_index">
                              <h5>
                                {" "}
                                {value.status}
                                <Switch
                                  id={value._id}
                                  onChange={() =>
                                    dispatch(
                                      updateStatus(
                                        value.type,
                                        value.variable,
                                        value.status
                                      )
                                    )
                                  }
                                  checked={value.status}
                                  className="react-switch"
                                />
                              </h5>
                            </div>

                            {value.value && (
                              <>
                                <div className="single_user_name">
                                  <h5>
                                    <input
                                      type="text"
                                      name="value-name-field"
                                      placeholder="Value"
                                      value={value.value}
                                      onChange={(e: any) =>
                                        handleTxt(
                                          value.variable,
                                          e.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        dispatch(
                                          updateValStatus(
                                            value.variable,
                                            value.value,
                                            value.type
                                          )
                                        )
                                      }
                                    />
                                  </h5>
                                </div>
                              </>
                            )}

                            {!value.value && (
                              <>
                                <div className="single_user_name">
                                  <h5>&nbsp;</h5>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        <span className="line"></span>
                      </React.Fragment>
                    ))
                  : null}
              </form>
            </div>
          </Tab>
          <Tab eventKey="admin-permission" title="Admin Permission">
            <div className="tab-content-container">
              <TabContent
                handleSetting={handleSetting}
                valueType="Admin_Permission"
                settinglist={settinglist}
                updateStatus={updateStatus}
              />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default AdminSettings;

const TabContent = ({
  handleSetting,
  valueType,
  settinglist,
  updateStatus,
}) => {
  const dispatch = useDispatch();

  const permissionState = {
    APP_VIOLATION: {
      email: false,
      saveviolation: false,
      alert: false,
    },
    CELL_PHONE_VIOLATION: {
      email: false,
      saveviolation: false,
      alert: false,
    },
    DESKTOP_ACTIVITY_SCREENSHOT: {
      email: false,
      saveviolations: false,
      alert: false,
    },

    DESKTOP_ACTIVITY_ALERT: {
      email: false,
      saveviolation: false,
      alert: false,
    },
  };

  const [, setPermissions] = useState(permissionState);
  useEffect(() => {
    // const adminPermissions = settinglist.filter(
    //   (settings) => settings.type === valueType
    // );
    setPermissions((prev) => ({
      ...prev,
    }));
  }, [settinglist]);

  const handleEmailPermissionChange = (e, type, variable, email) => {
    console.log(variable, email);
    dispatch(updateEmailPermission(type, variable, email));
  };

  const handleAlertPermissionChange = (e, type, variable, alert) => {
    console.log(variable, alert);
    dispatch(updateAlertPermission(type, variable, alert));
  };

  const handleSaveViolationPermissionChange = (
    e,
    type,
    variable,
    saveviolation
  ) => {
    console.log(variable, saveviolation);
    dispatch(updateViolationPermission(type, variable, saveviolation));
  };


  const handleNoneChange = (e, type, variable) => {
    dispatch(updateNonePermission(type, variable))
  }

  return (
    <>
      <Form className="add_form" onSubmit={handleSetting}>
        <div className="row all_users">
          <div className="report-container">
            <div className="single_user_name">
              <h5>
                <b>Option</b>{" "}
              </h5>
            </div>
            <div className="single_user_index">
              <h5>
                <b>Email</b>
              </h5>
            </div>
            <div className="single_user_index">
              <h5>
                <b>Alert</b>
              </h5>
            </div>
            <div className="single_user_index">
              <h5>
                <b>Save Violation</b>
              </h5>
            </div>
            <div className="single_user_index">
              <h5>
                <b>None</b>
              </h5>
            </div>
          </div>
        </div>
        <span className="line"></span>
        {console.log({ settinglist, valueType })}
        {settinglist.length > 0
          ? settinglist
            .filter((val) => val.type === valueType)
            .map((value, index) => {
              console.log({ value });
              return (
                <React.Fragment key={value._id}>
                  <div className="row all_users">
                    <div className="report-container">
                      <div className="single_user_name">
                        <h5>{value.label}</h5>
                      </div>
                      <div className="single_user_index">
                        <h5>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`default-${value.type}`}
                            name="Email"
                            onChange={(e) => {
                              handleEmailPermissionChange(
                                e,
                                valueType,
                                value.variable,
                                value.email
                              );
                            }}
                            checked={value.email}
                          // label={`${value.type}`}
                          />
                        </h5>
                      </div>
                      <div className="single_user_index">
                        <h5>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`default-${value.type}`}
                            name="Alert"
                            onChange={(e) => {
                              handleAlertPermissionChange(
                                e,
                                valueType,
                                value.variable,
                                value.alert
                              );
                            }}
                            checked={value.alert}
                          // label={`${value.type}`}
                          />
                        </h5>
                      </div>
                      <div className="single_user_index">
                        <h5>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`default-${value.type}`}
                            name="Save_Violation"
                            onChange={(e) => {
                              handleSaveViolationPermissionChange(
                                e,
                                valueType,
                                value.variable,
                                value.saveviolation
                              );
                            }}
                            checked={value.saveviolation}
                          // label={`${value.type}`}
                          />
                        </h5>
                      </div>
                      <div className="single_user_index">
                        <h5>
                          <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`default-${value.type}`}
                            name="None"
                            checked={
                              !value.email &&
                              !value.alert &&
                              !value.saveviolation
                            }
                            // label={`${value.type}`}
                            onChange={(e) => {
                              handleNoneChange(e, valueType, value.variable);
                            }}
                          />
                        </h5>
                      </div>
                    </div>
                  </div>

                  <span className="line"></span>
                </React.Fragment>
              );
            })
          : null}
      </Form>
    </>
  );
};
