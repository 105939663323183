import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { CSVLink } from "react-csv";

const ExportModal = (props) => {
  const [clickedOnGenerate, setClickedOnGenerate] = useState(false);
  const exportData = useSelector(
    (state: RootState) => state.getReportData.exportData
  );
  const loader = useSelector(
    (state: RootState) => state.getReportData.isExportLoading
  );

  const handleModalClose = () => {
    props.handleShow((value) => !value);
    setClickedOnGenerate(false);
  };

  const generateData = (dataType: string) => {
    setClickedOnGenerate(true);
    props.generateData(dataType);
  };

  return (
    <Modal show={props.show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Export Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!clickedOnGenerate ? (
          <div>
            <div
              className="submit-btn mt-3 del_mod exp_mod_class"
              style={{ display: "flex" }}
              onClick={handleModalClose}
            >
              <button
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                  generateData("expression");
                }}
              >
                Generate Expression Data
              </button>
              <button
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                  generateData("session");
                }}
              >
                Generate Session Data
              </button>
              <button
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                  generateData("summary");
                }}
              >
                Generate Summary Data
              </button>
              <button
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                  generateData("notLogged");
                }}
              >
                Login Exception Users
              </button>
              <button
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                  generateData("violation");
                }}
              >
                Generate Violation Data
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="message_export_data">
              {!loader && exportData.length > 0 ? (
                <>
                  <h5 style={{ textAlign: "center", width: "100%" }}>
                    Your Data is ready.
                  </h5>
                  <h4 style={{ textAlign: "center", width: "100%" }}>
                    Click on Download.
                  </h4>
                </>
              ) : (
                <>
                  <h5 style={{ textAlign: "center", width: "100%" }}>
                    Your Data is getting ready...
                  </h5>
                  <Spinner
                    animation="border"
                    variant="info"
                    className="export_spinner"
                  />
                </>
              )}
            </div>

            <div
              className="submit-btn mt-3 del_mod"
              style={{ display: "flex" }}
              onClick={handleModalClose}
            >
              {!loader && exportData.length > 0 ? (
                <CSVLink className="link" data={exportData}>
                  Download
                </CSVLink>
              ) : null}
              <button
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalClose();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ExportModal;
