import request from "./index";


interface sendData {
  username: string;
  email: string;
  password: string;
  role: string;
  first_name: string;
  last_name: string;
  date: string;
  mfa: boolean;
  appUpdate: boolean;
  faceVerification: boolean;
  enableDesktopApp: boolean;
  // complex_password: boolean,
  bussunit?: string;
  team?: string;
  mobile?: string;
  grouprooms?: Array<string>;
}

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const addUser = async (
  username: string,
  email: string,
  password: string,
  role: string,
  firstName: string,
  lastName: string,
  date: string,
  mfa: boolean,
  faceVerification: boolean,
  appUpdate: boolean,
  enableDesktopApp: boolean,
  complexPassword?: boolean,
  team?: string,
  bussUnit?: string,
  mobile?: string,
  selectedGroupRoom?: Array<string>
) => {
  let data: sendData = {
    username: username,
    email: email,
    password: password,
    role: role,
    first_name: firstName,
    last_name: lastName,
    date: date,
    mfa: mfa,
    // complex_password: complexPassword
    appUpdate: appUpdate,
    faceVerification: faceVerification,
    enableDesktopApp: enableDesktopApp,
  };

  console.log(bussUnit);
  if (
    bussUnit !== "" &&
    ((selectedGroupRoom && selectedGroupRoom.length > 0) ||
      role === "Director" ||
      role === "Manager")
  ) {
    data = { ...data, bussunit: bussUnit };
  } else if ((role === "Supervisor" || role === "Agent") && bussUnit && team) {
    data = { ...data, bussunit: bussUnit, team: team };
  }
  if (selectedGroupRoom && selectedGroupRoom.length > 0 && role !== "Admin") {
    data = { ...data, grouprooms: selectedGroupRoom };
  }

  if (mfa) {
    data = { ...data, mobile: mobile };
  }

  console.log({ data });

  const response = await request.post("/useradd", data, config);

  return response.data;
};

export const addMultipleUsers = async (file) => {
  // console.log("oops", users);
  let formData = new FormData();
  formData.append("file", file);
  const response = await request.post("/addBulkUsers", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
