import axios from "axios";
import { getActivityChannelUrl } from "../utils/Token";
import { getToken } from "../utils/Token";
const config = {
  headers: {
    "Content-Type": "application/json",
    "Authorization": getToken()
  },
};

export const getFeeds = async (topicId: any) => {
  config['headers']['Authorization'] = getToken();
  let body = {
    topicId: topicId,
  };

  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl + "/TrainingRoom",
    withCredentials: true,
  });

  const feedsResponse = await requestActivityChannel.post("/getAdminFeeds", body, config);
  return feedsResponse.data;
};

export const deleteFeed = async (feedId: string) => {
  config['headers']['Authorization'] = getToken();
  let body = {
    id: feedId,

  };
  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl + "/TrainingRoom",
    withCredentials: true,
  });

  const feedResponse = await requestActivityChannel.post("/deleteFeed", body, config);
  return feedResponse.data;
};

export const editFeed = async (feedId: string, topicId: string, title: string, description: string, type: string) => {
  config['headers']['Authorization'] = getToken();
  let body = {
    id: feedId,
    topicId,
    title,
    description,
    type
  };
  // Repeative code written intentionally. Do not remove
  const activityChannelBaseUrl = getActivityChannelUrl();
  const requestActivityChannel = axios.create({
    baseURL: activityChannelBaseUrl + "/TrainingRoom",
    withCredentials: true,
  });

  const feedResponse = await requestActivityChannel.post("/updateFeed", body, config);
  return feedResponse.data;
};

