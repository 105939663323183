import {
    GET_ALL_USER_DETAILS_SUCCESS,
    GET_ALL_USER_DETAILS_FAILURE,
    CLEAR_VIEWUSERDETAIL_DATA,
    SET_USERS_LIST_SUCCESS,
    SET_USERS_LIST_FAILURE
   } from "../actions/actionTypes";
   
   const initialState = {
     isViewAllGetUsers: false,
     userlist: [],
     error: "",
     exportedUsers:[]
   };
   interface IAction {
     type: string;
     payload: any;
   }
   
   const viewAllUsersReducer = (state = initialState, action: IAction) => {
     switch (action.type) {
       case GET_ALL_USER_DETAILS_SUCCESS: {
         return {
           ...state,
           isViewAllGetUsers: true,
           userlist: action.payload.users,
         };
       }
       case GET_ALL_USER_DETAILS_FAILURE: {
         return {
           ...state,
           isViewAllGetUsers: false,
           error: action.payload.error,
         };
       }
       case SET_USERS_LIST_SUCCESS :{
        return{
          ...state,
          exportedUsers:action.payload.users
        }

       }
       case SET_USERS_LIST_FAILURE:{
        return{
          ...state,
          error:action.payload.msg
        }
       }
       case CLEAR_VIEWUSERDETAIL_DATA:{
        return initialState
      }
       default: {
         return state;
       }
     }
   };
   
   export default viewAllUsersReducer;
   