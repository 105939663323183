import {
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_FAILURE,
} from "../actions/actionTypes";

interface environmentDataType {
  _id: string;
  value: string;
  data: Array<any>;
  type: string;
  variable: string;
  status: boolean;
}

interface environmentsType {
  REACT_APP_AUDIO_TO_ALL: environmentDataType;
  REACT_APP_PAGE_SIZE: environmentDataType;
  REACT_APP_PAGE_SIZE_COLLAB: environmentDataType;
  REACT_APP_FACESECONDS: environmentDataType;
  REACT_APP_ENABLESCREENSHOT: environmentDataType;
  REACT_APP_RECORDING_ENABLED: environmentDataType;
  REACT_APP_FACE_DETECTION: environmentDataType;
  REACT_APP_EMOTION_DETECTION: environmentDataType;
  REACT_APP_SCREENCONTROL: environmentDataType;
  REACT_APP_AUTOLOGINSEED: environmentDataType;
  VIDEO_SIMULCAST_ENCODINGS_SM: environmentDataType;
  REACT_APP_VERSION: environmentDataType;
  VIDEO_KSVC_ENCODINGS: environmentDataType;
  SCREEN_SHARING_SIMULCAST_ENCODINGS: environmentDataType;
  AGENT_TO_AGENT_CHAT: environmentDataType;
  AGENT_TO_AGENT_PRIVATE_CALL: environmentDataType;
  REACT_APP_DETECTION_TOKEN: environmentDataType;
  STORE_DETECTION_IMAGES: environmentDataType;
  CELLPHONE_FACE_DETECTION_SECONDS: environmentDataType;
  REACT_APP_COMPLEX_PASSWORD: environmentDataType;
}

const initialState = {
  environments: {} as environmentsType,
  successMessage: "",
  failureMessage: "",
};

interface IAction {
  type: string;
  payload: any;
}

const environmentReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ENVIRONMENTS_SUCCESS: {
      return {
        ...state,
        environments: action.payload,
      };
    }

    case GET_ENVIRONMENTS_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload?.errorMessage,
      };
    }
    default: {
      return state;
    }
  }
};

export default environmentReducer;
