import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";

import { DateTimePicker } from "react-rainbow-components";
import Grid from "@mui/material/Grid";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import BasicTable from "../../components/BasicTable";
import { reportAPI } from "../../utils/api";
import LoadingSpinner from "../../components/Spinner";
import Header from "../../components/Header";
import moment from "moment";
import { timeZoneFormat } from "../../utils/dateformat";

// import _ from "lodash";
function PrivateCallReport() {
  const [value, setValue] = React.useState(new Date());
  const [value2, setValue2] = React.useState(new Date());
  const [value3, setValue3] = React.useState();
  const [, setUser] = React.useState();
  const [rooms, setRooms] = React.useState();
  const [sumaryData, setSumaryData] = React.useState();
  const [isActive, setActive] = useState(false);
  // const [userData, ] = React.useState();
  // const [dataByDay, ] = React.useState([]);
  // const [, setCsvData] = React.useState([]);
  const [loadCsvData, ] = React.useState("");
  const [users, setUsers] = useState([{ label: "All Users", value: "" }]);
  const handleChange = (newValue) => {
    console.log("newValue", newValue);
    setValue(newValue);

    formik.setFieldValue("dateFrom", new Date(newValue));
  };
  const handleChange2 = (newValue) => {
    setValue2(newValue);

    formik.setFieldValue("dateTo", new Date(newValue));
  };
  const { format } = timeZoneFormat();
  const handleChange3 = (newValue) => {
    console.log(newValue);
    if (newValue !== null) {
      setValue3(newValue.value);
      formik.setFieldValue("username", newValue.value);
    } else {
      setValue3("");
      formik.setFieldValue("username", "");
    }
  };
  const initialValues = {
    dateFrom: new Date(),
    dateTo: new Date(),
    username: "",
    roomname: "",
  };
  const validationSchema = Yup.object().shape({
    dateFrom: Yup.date().nullable(),
    dateto: Yup.date().nullable(),
  });
  function onSubmit(values) {
    const { dateFrom, dateTo, username, roomname, bussunit } = values;
    // alert(JSON.stringify(values));
    // setShow(false);
    // setShowMore(false);
    setActive(true);
    console.log(dateFrom, dateTo);
    reportAPI
      .getPrivateCallReport({ dateFrom, dateTo, username, roomname, bussunit })
      .then((res) => {
        console.log("responseeeeeeeee", res);
        setActive(false);
        setSumaryData(res?.data);
      });
  }

  const getuser = () => {
    reportAPI.gettAll().then((res) => {
      setUser(res.data);
      let labeledUsers = [];
      // eslint-disable-next-line array-callback-return
      res.data.map((user, index) => {
        labeledUsers.push({ label: user.username, value: user.username });
      });
      labeledUsers.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return labelA.localeCompare(labelB);
      });
      console.log({ labeledUsers });
      setUsers(labeledUsers);
      console.log(labeledUsers);
    });
  };
  const getrooms = () => {
    reportAPI.gettRooms().then((res) => {
      setRooms(res.data);
    });
  };

  useEffect(() => {
    getuser();
    getrooms();
  }, []);

  // const dateFormat = (date) => {
  //   const newdate = new Date(date).toDateString();
  //   return newdate;
  // };
  // const gettime = (date) => {
  //   return moment(date).format(format);
  // };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  // const getSumColumn = (arr, column) => {
  //   let sum = 0;
  //   arr?.forEach((el) => (sum += el[column]));
  //   return sum.toFixed(2);
  // };

  // const tempForAll = [
  //   "videoMintTotal",
  //   "screenMintTotal",
  //   "screenTwoMintTotal",
  //   "audioMintTotal",
  //   "lunchMintTotal",
  //   "breakMintTotal",
  //   "engagedEmotionMintTotal",
  //   "disengagedEmotionMintTotal",
  //   "otherEmotionsMintTotal",
  // ];

  // const templateSummaryData = ["_id", "roomname", "session", ...tempForAll];
  // const templateUserData = [
  //   "username",
  //   "_id",
  //   "roomname",
  //   "session",
  //   ...tempForAll,
  // ];

  // const templateDataByDay = [
  //   "selectedDay",
  //   "createdAt",
  //   "exitAt",
  //   "roomname",
  //   ...tempForAll,
  // ];

  // const csvHeaderTemplate = [
  //   "Video Minutes",
  //   "Screen Minutes",
  //   "Screen 2 Minutes",
  //   "Audio Minutes",
  //   "Lunch Minutes",
  //   "Break Minutes",
  //   "Minutes Engaged",
  //   "Minutes Disengaged",
  //   "Minutes Other Emotions",
  // ];

  // const exportToSummaryData = () => {
  //   setCsvData([]);
  //   setLoadCsvData("loading");
  //   const summaryArray = [
  //     ["Agent Name", "Room", "Session Minutes", ...csvHeaderTemplate],
  //   ];

  //   sumaryData.map((value) => {
  //     summaryArray.push(
  //       templateSummaryData.map((templateValue) => {
  //         let val;
  //         switch (templateValue) {
  //           case "_id":
  //             val = value[templateValue].username;
  //             break;
  //           case "roomname":
  //             val = value[templateValue]
  //               .filter((v, i, a) => a.indexOf(v) === i)
  //               .toString();
  //             break;
  //           case "videoMintTotal":
  //           case "screenMintTotal":
  //           case "screenTwoMintTotal":
  //           case "audioMintTotal":
  //             val = Math.abs(value[templateValue]);
  //             break;
  //           default:
  //             val = value[templateValue];
  //             break;
  //         }
  //         return val;
  //       })
  //     );
  //   });

  //   setCsvData(summaryArray);
  //   setLoadCsvData("complete");
  // };

  // const exportToUserData = () => {
  //   setCsvData([]);
  //   setLoadCsvData("loading");
  //   const userDataArray = [
  //     ["Agent Name", "Date", "Room", "Session Minutes", ...csvHeaderTemplate],
  //   ];

  //   userData.map((value) => {
  //     userDataArray.push(
  //       templateUserData.map((templateValue) => {
  //         let val;
  //         switch (templateValue) {
  //           case "username":
  //             val = value[templateValue][0];
  //             break;
  //           case "roomname":
  //             val = value[templateValue]
  //               .filter((v, i, a) => a.indexOf(v) === i)
  //               .toString();
  //             break;
  //           case "videoMintTotal":
  //           case "screenMintTotal":
  //           case "screenTwoMintTotal":
  //           case "audioMintTotal":
  //             val = Math.abs(value[templateValue]);
  //             break;
  //           default:
  //             val = value[templateValue];
  //             break;
  //         }
  //         return val;
  //       })
  //     );
  //   });

  //   setCsvData(userDataArray);
  //   setLoadCsvData("complete");
  // };

  // const exportToDataByDay = () => {
  //   setCsvData([]);
  //   setLoadCsvData("loading");
  //   const dataByDayArray = [
  //     ["Date", "Session Start", "Session End", "Room", ...csvHeaderTemplate],
  //   ];

  //   dataByDay.map((value) => {
  //     dataByDayArray.push(
  //       templateDataByDay.map((templateValue) => {
  //         let val;
  //         switch (templateValue) {
  //           case "createdAt":
  //           case "exitAt":
  //             val = gettime(value[templateValue]);
  //             break;
  //           case "videoMintTotal":
  //           case "screenMintTotal":
  //           case "screenTwoMintTotal":
  //           case "audioMintTotal":
  //             val = Math.abs(value[templateValue]);
  //             break;
  //           default:
  //             val = value[templateValue];
  //             break;
  //         }
  //         return val;
  //       })
  //     );
  //   });

  //   setCsvData(dataByDayArray);
  //   setLoadCsvData("complete");
  // };

  const calculateMinutes = (callAt, callEndAt, callAction) => {
    if (callAction === "CALL_RECEIVED") {
      let startDate = new Date(callAt);
      let endDate = new Date(callEndAt);
      let seconds = Math.round(
        (endDate.getTime() - startDate.getTime()) / 1000
      );
      let date = new Date(1970, 0, 1);
      date.setSeconds(seconds);
      return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
    }
    return null;
  };
  const columns = [
    {
      Header: "Agent (Caller)",
      accessor: "username",
    },
    {
      Header: "Receiver",
      accessor: "receiver",
    },
    {
      Header: "Call Status",
      accessor: "callAction",
    },
    {
      Header: "Room",
      accessor: "roomname",
    },
    {
      Header: "Duration",
      Cell: (props) => {
        return (
          <>
            {calculateMinutes(
              props.row.original.callAt,
              props.row.original.callEndAt,
              props.row.original.callAction
            )}
          </>
        );
      },
    },
    {
      Header: "Call Started",
      accessor: "callAt",
      Cell: ({ value }) => {
        return moment(value).format(format);
      },
    },
    {
      Header: "Call Ended",
      accessor: "callEndAt",
      Cell: ({ value }) => {
        return moment(value).format(format);
      },
      // Footer: getSumColumn(sumaryData, "callEndAt"),
    },
  ];

  return (
    <>
      <Header />
      <Container>
        <Typography mt={5} textAlign={"center"} variant="h3">
          Reports
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          display={"flex"}
          justifyContent={"Center"}
        >
          <Grid
            container
            spacing={4}
            mt={5}
            display={"flex"}
            justifyContent={"Center"}
          >
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={4}
                    display={"flex"}
                    justifyContent={"Center"}
                    alignItems="center"
                  >
                    <DateTimePicker
                      name="dateFrom"
                      label="Start Date&Time"
                      value={value}
                      formik={formik}
                      onChange={handleChange}
                      className="rainbow-m-around_small datepicker-space"
                      hour24
                    />
                    <DateTimePicker
                      name="dateTo"
                      label="End Date&Time"
                      value={value2}
                      formik={formik}
                      onChange={handleChange2}
                      className="rainbow-m-around_small datepicker-space"
                      hour24
                    />

                    <Grid item xs={6}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}></Grid>
                  </Grid>

                  <Grid
                    mt={2}
                    container
                    spacing={4}
                    display={"flex"}
                    justifyContent={"Center"}
                    alignItems="center"
                  >
                    <Grid item xs={5}>
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="username"
                          value={value3}
                          options={users}
                          renderInput={(params) => (
                            <TextField {...params} label="All User" />
                          )}
                          onChange={(event, value) => handleChange3(value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          All Rooms
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="roomname"
                          label="Rooms"
                          formik={formik}
                          value={formik.values.roomname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.roomname &&
                            Boolean(formik.errors.roomname)
                          }
                          helperText={
                            formik.touched.roomname && formik.errors.roomname
                          }
                        >
                          {<MenuItem value={""}>All Rooms</MenuItem>}
                          {rooms
                            ? rooms.map((item, index) => {
                                return (
                                  <MenuItem value={item.roomname}>
                                    {item.roomname}
                                  </MenuItem>
                                );
                              })
                            : "Loading"}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11} display={"flex"} justifyContent={"end"}>
              <Button type="submit" variant="contained">
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <LoadingSpinner active={isActive || loadCsvData === "loading"} />
      {/*{sumaryData ? (*/}
      {/*  <CSVLink*/}
      {/*    className="link"*/}
      {/*    data={csvData}*/}
      {/*    onClick={(event) => {*/}
      {/*      exportToSummaryData();*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Export Summary Data*/}
      {/*  </CSVLink>*/}
      {/*) : null}*/}
      <BasicTable columns={columns} data={sumaryData ?? []} />
    </>
  );
}

export default PrivateCallReport;
