import React, { useMemo } from "react";
import { useTable } from "react-table";
import {
  TableContainer,
  Table as MUITable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  CircularProgress,
  TablePagination,
  TableFooter
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as ViewIcon,
} from "@mui/icons-material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

function BasicTable({
  columns: Columns,
  data: Data,
  loading,
  edit,
  remove,
  view,
  addImage,
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = useMemo(() => Columns, [Columns]);
  const data = useMemo(() => Data, [Data]);
  const initialState = { hiddenColumns: ["is_percent"] };
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow,footerGroups } =
    useTable({
      columns,
      data,
      initialState,
    });

  return (
    <>
      <TableContainer sx={{ mt: 3 }}>
        <MUITable {...getTableProps()} stickyHeader>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    sx={{
                      bgcolor: "primary.main",
                      color: "white",
                      fontWeight: "bold",
                      fontSize:"13px"
                    }}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
                {/* actions */}
                {(edit || remove || view || addImage) && (
                  <TableCell
                    sx={{
                      bgcolor: "primary.main",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    align="center"
                  >
                    Action
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {loading && (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}

            {!loading &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} hover>
                      {row.cells.map((cell) => (
                        // console.log()
                        // {row.values?.status_displayname != null ? "yes" : null}
                        <TableCell {...cell.getCellProps()} size="small">
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                      {/* actions */}

                      {(edit || remove || view || addImage) && (
                        <TableCell align="center" size="small">
                          {addImage && (
                            <IconButton
                              color="primary"
                              onClick={() => addImage(row.values.id)}
                            >
                              <AddPhotoAlternateIcon />
                            </IconButton>
                          )}
                          {view && (
                            <IconButton
                              color="primary"
                              onClick={() => view(row.values.id)}
                            >
                              <ViewIcon />
                            </IconButton>
                          )}
                          {edit && (
                            <IconButton
                              color="info"
                              onClick={() => edit(row.values.id)}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          {remove && (
                            <IconButton
                              color="error"
                              onClick={() => remove(row.values.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            {!loading && data.length < 1 && (
              <TableRow>
                <TableCell
                  colSpan={columns.length + (remove || edit ? 1 : 0)}
                  align="center"
                >
                  <Typography color="GrayText">No records found!</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          
          <TableFooter>
      
        {footerGroups.map(group => (
          <TableRow {...group.getFooterGroupProps()}>
            {group.headers.map(column => (
              <TableCell sx={{
                bgcolor: "grey !important",
                color: "white",
                fontWeight: "bold",
              }}
              align="left" {...column.getFooterProps()}>{column.render('Footer')}</TableCell>
            ))}
          </TableRow>
        ))}
     
      </TableFooter>
        </MUITable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default BasicTable;
