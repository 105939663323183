import request from "./index";

// const config = {
//   headers: {
//     "Content-Type": "application/json",
//   },
// };

export const getEnvironments = async () => {
  const environmentsResponse = await request.get('/getEnv');
  return environmentsResponse.data;
};
