import { useEffect, useState } from "react";
import "./AddUser.css";
import Header from "./../../components/Header";
import Input from "./../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { handleAddUser, setMfaAction } from "../../actions/addUserActions";
import { RootState } from "../../redux";
import { Alert, Badge } from "react-bootstrap";
import { getRoomsAction } from "../../actions/roomActions";
import { getBussUnitsAction } from "../../actions/bussUnitActions";
import React from "react";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import { REACT_APP_COMPLEX_PASSWORD } from "../../App/constants";
import { getEnvironmentsAction } from "../../actions/environmentActions";
import {
  ADD_USER_FAILURE,
  ENABLE_DESKTOP_APP,
  SET_APP_UPDATE,
} from "../../actions/actionTypes";
import SearchDropdown from "../../components/SearchDropdown";
import { getAllGroupRoom } from "../../actions/groupRoomAction";

const AddUser = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const bussUnitsList = useSelector(
    (state: RootState) => state.bussUnit.bussUnits
  );
  const addUserError = useSelector((state: RootState) => state.addUser.error);

  // ============== groupRoom multiselect state ====================
  let AllgroupNameList = useSelector(
    (state: RootState) => state.groupRoomReducer.groupRoomList
  );

  const [groupNameList, setGroupNameList] = useState([]);
  const [selectedGroupRoom, setSelectedGroupRoom] = useState(() => new Set());
  const [selectedValue, setSelectedValue] = useState("");
  const [teamType, setTeamType] = useState("");
  const [teamTypeError, setTeamTypeError] = useState("");
  const [groupRoomError, setGroupRoomError] = useState("");

  // ============== END groupRoom multiselect state ==================
  const environment = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );
  const [username, setUsername] = useState("");
  const [usernameerror, setUsernameError] = useState("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [faceVerification, setFaceVerification] = useState(false);
  const [complexPassword, setComplexPassword] = useState(
    environment.REACT_APP_COMPLEX_PASSWORD
      ? environment.REACT_APP_COMPLEX_PASSWORD.status
      : REACT_APP_COMPLEX_PASSWORD
  );
  const [complexPasswordMessage, setComplexPasswordMessage] = useState({
    lowerCase: "",
    upperCase: "",
    numeric: "",
    specialChar: "",
    passwordLength: "",
  });

  const handleUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
    setUsernameError("");
  };
  const history = useHistory();

  const mfa = useSelector((state: RootState) => state.addUser.mfa);
  const handleMfa = (checked: boolean) => {
    dispatch(setMfaAction(checked));
  };

  const appUpdate = useSelector((state: RootState) => state.addUser.appUpdate);
  const handleAppUpdate = (checked: boolean) => {
    dispatch({
      type: SET_APP_UPDATE,
      payload: { appUpdat: checked },
    });
  };

  const handleFaceVerification = (checked: boolean) => {
    setFaceVerification(checked);
  };

  //console.log({ appUpdate });

  const enableDesktopApp = useSelector(
    (state: RootState) => state.addUser.enableDesktopApp
  );
  const handleEnableDesktopApp = (checked: boolean) => {
    dispatch({
      type: ENABLE_DESKTOP_APP,
      payload: {
        enableDesktopApp: checked,
      },
    });
  };
  // const handleComplexPassword = (checked: boolean) => {
  //   if(complexPassword) {
  //     setComplexPassword(false)
  //     setComplexPasswordMessage({
  //       lowerCase: "",
  //       upperCase: "",
  //       numeric: "",
  //       specialChar: "",
  //       passwordLength: ""})
  //   } else {
  //     setComplexPassword(true)
  //   }
  // };

  const [pword, setPword] = useState("");
  const [pworderror, setPwordError] = useState("");

  // Function to validate the
  // International Phone Numbers
  function isValidPhoneNumber(phonenumber) {
    // Regex to check valid
    // International Phone Numbers
    let regex = new RegExp("^\\+\\d{7,10}\\d{4}$");

    // if phonenumber
    // is empty return false
    if (phonenumber == null) {
      return true;
    }

    // Return true if the phonenumber
    // matched the ReGex
    // eslint-disable-next-line eqeqeq
    if (regex.test(phonenumber) == true) {
      return false;
    } else {
      return true;
    }
  }

  function checkpassword(password) {
    let strength = 0;
    let upperCaseMessage = "";
    let lowerCaseMessage = "";
    let numericMessage = "";
    let specialCharMessage = "";
    let passwordLengthMessage = "";
    if (password.match(/[a-z]+/)) {
      strength = strength + 1;
    } else {
      lowerCaseMessage = "Atleast one lowercase character required";
    }
    if (password.match(/[A-Z]+/)) {
      strength = strength + 1;
    } else {
      upperCaseMessage = "Atleast one uppercase character required";
    }
    if (password.match(/[0-9]+/)) {
      strength = strength + 1;
    } else {
      numericMessage = "Atleast one numeric required";
    }
    if (password.match(/[$@#&!]+/)) {
      strength = strength + 1;
    } else {
      specialCharMessage = "Atleast one special character required";
    }
    if (password.length < 8) {
      passwordLengthMessage = "Minimum length of password is 8 characters";
    } else {
      strength = strength + 1;
    }

    setPasswordStrength(strength);
    setComplexPasswordMessage({
      lowerCase: lowerCaseMessage,
      upperCase: upperCaseMessage,
      numeric: numericMessage,
      specialChar: specialCharMessage,
      passwordLength: passwordLengthMessage,
    });
  }

  const handlePword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPword(e.target.value);
    setPwordError("");
    setCpwordError("");
    if (complexPassword) checkpassword(e.target.value);
  };
  const [cpword, setCpword] = useState("");
  const [cpworderror, setCpwordError] = useState("");
  const handleCpword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCpword(e.target.value);
    setPwordError("");
    setCpwordError("");
  };
  const [role, setRole] = useState("Agent");
  const [roleerror, setRoleError] = useState("");
  const handleRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRole(e.target.value);
    emptyForSelectTeam();

    setTeamType("");
  };

  const [team, setTeam] = useState("");
  const [teamerror, setTeamError] = useState("");
  const handleTeam = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTeam(e.target.value);
    let room = rooms.find((value) => value.roomname === e.target.value);
    if (room) setBussUnit(room.bussunit);
    setTeamError("");
  };

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
    setFirstNameError("");
  };
  const [lastName, setLastName] = useState("");
  const [lastNameerror, setlastNameerror] = useState("");
  const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
    setlastNameerror("");
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const [mobile, setMobile] = useState("");
  const [MobileError, setMobileError] = useState("");
  const handleMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobileError("");
    setMobile(e.target.value);
  };

  const [bussUnit, setBussUnit] = useState("");
  const [bussUnitError, setBussUnitError] = useState("");
  const handleBussUnit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBussUnit(e.target.value);
    if (teamType === "group") {
      setSelectedGroupRoom(new Set());
      setGroupNameList(() =>
        AllgroupNameList.filter(
          (value) => value.bussunit === e.target.value
        ).map((value) => value.groupname)
      );
    }
  };

  const isUserAdded = useSelector(
    (state: RootState) => state.addUser.isUserCreated
  );

  useEffect(() => {
    dispatch({
      type: ADD_USER_FAILURE,
      payload: {
        error: "",
      },
    });
    dispatch(getRoomsAction());
    dispatch(getBussUnitsAction());
    dispatch(getEnvironmentsAction());
    dispatch(getAllGroupRoom());
  }, [dispatch]);
  React.useEffect(() => {
    if (isUserAdded) {
      // history.go(0);
      setPword("");
      setUsername("");
      setEmail("");
      setCpword("");
      setFirstName("");
      setLastName("");
      setTeam("");
      setRole("Agent");
      setBussUnit("");
      setMobile("");
      setSelectedGroupRoom(new Set());
      setTeamType("");
    }
  }, [isUserAdded, history]);

  const handleRegister = (e: React.FormEvent<HTMLFormElement>) => {
    let a = true;
    e.preventDefault();
    setUsernameError("");
    setEmailError("");
    setFirstNameError("");
    setPwordError("");
    setCpwordError("");
    setRoleError("");
    setTeamError("");
    setBussUnitError("");
    setMobileError("");
    setGroupRoomError("");
    setTeamTypeError("");
    if (username === "") {
      a = false;
      setUsernameError("Username cannot be blank");
    }
    if (complexPassword && passwordStrength * 20 < 100) {
      a = false;
      setPwordError(
        "Please enter complex password or disable complex password option"
      );
    }
    if (pword !== cpword) {
      a = false;
      setPwordError("Passwords do no match");
    }
    // if(email === ""){
    //   a = false;
    //   setEmailError("Email cannot be blank");
    // }
    if (firstName === "") {
      a = false;
      setFirstNameError("First name cannot be blank");
    }
    if (lastName === "") {
      a = false;
      setlastNameerror("Last name cannot be blank");
    }
    if (pword === "") {
      a = false;
      setPwordError("Password cannot be blank");
    }
    if (cpword === "") {
      a = false;
      setCpwordError("Confirm Password cannot be blank");
    }
    if (role === "") {
      a = false;
      setRoleError("Role cannot be blank");
    }

    if (teamType === "" && role !== "Admin") {
      a = false;
      setTeamTypeError("team type cannot be blank");
    }
    if (
      team === "" &&
      (role === "Supervisor" || role === "Agent") &&
      selectedGroupRoom.size <= 0 &&
      teamType === "room"
    ) {
      a = false;
      setTeamError("Team cannot be blank");
    }
    if ((teamType === "buss" || teamType === "group") && bussUnit === "") {
      a = false;
      setBussUnitError("Business unit cannot be blank");
    }

    if (
      role !== "Admin" &&
      selectedGroupRoom.size <= 0 &&
      teamType === "group"
    ) {
      a = false;
      setGroupRoomError("Group Room cannot be blank");
    }

    if (
      mfa &&
      (mobile === "" ||
        mobile.length < 10 ||
        mobile.length > 15 ||
        isValidPhoneNumber(mobile))
    ) {
      a = false;
      setMobileError("Please enter valid mobile number with country code");
    }
    console.log(a);
    if (a) {
      let groupRooms;
      if (selectedGroupRoom.size > 0) {
        groupRooms = Array.from(selectedGroupRoom) as Array<string>;
      }
      dispatch(
        handleAddUser(
          username,
          email,
          pword,
          role,
          firstName,
          lastName,
          mfa,
          complexPassword,
          new Date().toUTCString(),
          faceVerification,
          appUpdate,
          enableDesktopApp,
          team,
          bussUnit,
          mobile,
          groupRooms
        )
      );
    }
  };
  let rooms = useSelector((state: RootState) => state.roomsData.rooms);
  if (userDetails.role === "Director") {
    rooms = rooms.filter((room) => room.bussunit === userDetails.bussunit);
  }

  useEffect(() => {
    if (environment.REACT_APP_COMPLEX_PASSWORD) {
      setComplexPassword(environment.REACT_APP_COMPLEX_PASSWORD.status);
    }
  }, [environment]);
  var roomListDisplay = rooms.map((room: any, index: any) => (
    <option key={index} value={room.roomname}>
      {room.roomname}
    </option>
  ));

  var bussUnitsDisplay = bussUnitsList.map((bussUnitObject: any) => (
    <option key={bussUnitObject._id} value={bussUnitObject.bussunit}>
      {bussUnitObject.bussunit}
    </option>
  ));

  // logic for multiselect group room
  const setGroupRoom = (val: string) => {
    setGroupRoomError("");
    if (selectedGroupRoom.has(val)) {
      unselectGroupRoom(val);
    } else {
      setSelectedGroupRoom((prev) => {
        const next = new Set(prev); // create a new Set from the old one

        next.add(val); // add a value to the new Set

        return next;
      });
    }
  };

  const unselectGroupRoom = (val: string) => {
    setSelectedGroupRoom((prev) => {
      const next = new Set(prev); // create a new Set from the old one

      next.delete(val); // remove a value from the new Set

      return next;
    });
  };

  const handleTeamType = (e: any) => {
    setTeamType(e.target.value);
    emptyForSelectTeam();
  };

  const emptyForSelectTeam = () => {
    setBussUnit("");
    setSelectedGroupRoom(new Set());
    setTeam("");
  };

  return (
    <>
      <Header />
      <section className="add_user">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form
                className="add_form"
                autoComplete="off"
                onSubmit={handleRegister}
              >
                <h1>
                  <span>Add User</span>
                </h1>
                {addUserError && <Alert variant="danger">{addUserError}</Alert>}
                <p>User Name *</p>
                <Input
                  type="text"
                  name="email-field"
                  placeholder="info@trainingroom.com"
                  value={username}
                  onChange={handleUsername}
                />
                <p className="red-alert">{usernameerror}</p>
                <p>First Name *</p>
                <Input
                  type="text"
                  name="first-name-field"
                  placeholder="First Name"
                  value={firstName}
                  onChange={handleFirstName}
                />
                <p className="red-alert">{firstNameError}</p>
                <p>Last Name *</p>
                <Input
                  type="text"
                  name="last-name-field"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={handleLastName}
                />
                <p className="red-alert">{lastNameerror}</p>
                <p>Email</p>
                <Input
                  type="email"
                  name="email-field"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmail}
                />
                <p className="red-alert">{emailError}</p>
                {/*<label className="toggle-switch">*/}
                {/*<span*/}
                {/*    className={`toggle-label ${complexPassword && "toggle-enabled"}`}*/}
                {/*>*/}
                {/*  Complex Password* {complexPassword ? "Enabled": "Disabled"  }*/}
                {/*</span>*/}
                {/*  <Switch*/}
                {/*      onChange={handleComplexPassword}*/}
                {/*      checked={complexPassword}*/}
                {/*      className="react-switch"*/}
                {/*  />*/}
                {/*</label>*/}
                <p>
                  Password * &nbsp;
                  {complexPassword ? (
                    <progress
                      className={"prog" + passwordStrength * 20}
                      max="100"
                      value={passwordStrength * 20}
                      id="meter"
                    ></progress>
                  ) : (
                    ""
                  )}
                </p>
                <Input
                  type="password"
                  name="password-field"
                  placeholder="Password"
                  value={pword}
                  onChange={handlePword}
                />
                <p className="red-alert">{pworderror}</p>
                {complexPasswordMessage.lowerCase ? (
                  <p className="warning-alert">
                    {complexPasswordMessage.lowerCase}
                  </p>
                ) : (
                  ""
                )}
                {complexPasswordMessage.upperCase ? (
                  <p className="warning-alert">
                    {complexPasswordMessage.upperCase}
                  </p>
                ) : (
                  ""
                )}
                {complexPasswordMessage.numeric ? (
                  <p className="warning-alert">
                    {complexPasswordMessage.numeric}
                  </p>
                ) : (
                  ""
                )}
                {complexPasswordMessage.specialChar ? (
                  <p className="warning-alert">
                    {complexPasswordMessage.specialChar}
                  </p>
                ) : (
                  ""
                )}
                {complexPasswordMessage.passwordLength ? (
                  <p className="warning-alert">
                    {complexPasswordMessage.passwordLength}
                  </p>
                ) : (
                  ""
                )}
                <p>Confirm Password *</p>
                <Input
                  type="password"
                  name="confirm-password-field"
                  placeholder="Confirm Password"
                  value={cpword}
                  onChange={handleCpword}
                />
                <p className="red-alert">{cpworderror}</p>
                <p>Please select Role *</p>
                <select id="role" onChange={handleRole} value={role}>
                  <option value="" disabled>
                    Please Select
                  </option>
                  <option value="Agent">Agent</option>
                  <option value="Supervisor">Supervisor</option>
                  <option value="Manager">Manager</option>
                  {userDetails.role !== "Director" && (
                    <option value="Admin">Admin</option>
                  )}
                  <option value="Director">Director</option>
                </select>
                <p className="red-alert">{roleerror}</p>

                {role !== "Admin" ? (
                  <>
                    <p>Please select type *</p>
                    <select
                      id="team"
                      onChange={handleTeamType}
                      value={teamType}
                    >
                      <option value="" disabled={true}>
                        Please Select type
                      </option>

                      {role !== "Director" ? (
                        <option value="group">Group Room</option>
                      ) : null}

                      {role === "Manager" || role === "Director" ? (
                        <option value="buss">Business Unit</option>
                      ) : role !== "Manager" &&
                        role !== "Admin" &&
                        role !== "Director" ? (
                        <option value="room">Room</option>
                      ) : null}
                    </select>
                    <p className="red-alert">{teamTypeError} </p>
                  </>
                ) : null}

                {teamType === "buss" || teamType === "group" ? (
                  <>
                    <p>Please select Business Unit *</p>
                    <select
                      id="role"
                      onChange={handleBussUnit}
                      value={bussUnit}
                    >
                      <option value="" disabled>
                        Please Select Business Unit
                      </option>
                      {bussUnitsDisplay}
                    </select>
                    <p className="red-alert">
                      {bussUnitError}{" "}
                      {bussUnitsList.length <= 0
                        ? "There are no Business units created. Please create a Business unit first"
                        : null}
                    </p>
                  </>
                ) : null}

                {teamType === "room" ? (
                  <>
                    <p>Please select Room *</p>
                    <select id="team" onChange={handleTeam} value={team}>
                      <option value="" disabled={true}>
                        Please Select Room
                      </option>
                      {roomListDisplay}
                    </select>
                    <p className="red-alert">
                      {teamerror}{" "}
                      {rooms.length <= 0 ? "Please Create A Room First" : null}
                    </p>
                  </>
                ) : null}

                {teamType === "group" ? (
                  <>
                    {" "}
                    <div className="selected-rooms">
                      {Array.from(selectedGroupRoom, (value: any) => (
                        <>
                          <Badge variant="info">
                            {value}
                            <Badge
                              variant="danger"
                              onClick={() => unselectGroupRoom(value)}
                              style={{ cursor: "pointer" }}
                            >
                              X
                            </Badge>
                          </Badge>{" "}
                        </>
                      ))}
                    </div>
                    <p>Please Select Group Room *</p>
                    <SearchDropdown
                      options={groupNameList}
                      id="id"
                      selectedVal={selectedValue}
                      multiSelect={true}
                      handleChange={(val) => setSelectedValue(val)}
                      handleSelect={(val) => setGroupRoom(val)}
                      multiValue={selectedGroupRoom}
                    />
                    <p className="red-alert">
                      {groupRoomError}{" "}
                      {groupNameList.length <= 0 && bussUnit !== ""
                        ? "Please Create A Group Room First"
                        : null}
                    </p>
                  </>
                ) : null}

                {role === "Agent" && (
                  <label className="toggle-switch">
                    <span
                      className={`toggle-label ${
                        appUpdate && "toggle-enabled"
                      }`}
                    >
                      App Update* {appUpdate ? "ON" : "OFF"}
                    </span>
                    <Switch
                      onChange={handleAppUpdate}
                      checked={appUpdate}
                      className="react-switch"
                    />
                  </label>
                )}

                {role === "Agent" && (
                  <label className="toggle-switch">
                    <span
                      className={`toggle-label ${
                        faceVerification && "toggle-enabled"
                      }`}
                    >
                      Face Verification* {faceVerification ? "ON" : "OFF"}
                    </span>
                    <Switch
                      onChange={handleFaceVerification}
                      checked={faceVerification}
                      className="react-switch"
                    />
                  </label>
                )}
                {/* enableDesktopApp */}
                {role === "Agent" && (
                  <label className="toggle-switch">
                    <span
                      className={`toggle-label ${
                        enableDesktopApp && "toggle-enabled"
                      }`}
                    >
                      Enable Desktop App* {enableDesktopApp ? "ON" : "OFF"}
                    </span>
                    <Switch
                      onChange={handleEnableDesktopApp}
                      checked={enableDesktopApp}
                      className="react-switch"
                    />
                  </label>
                )}

                <label className="toggle-switch">
                  <span className={`toggle-label ${mfa && "toggle-enabled"}`}>
                    MFA* {mfa ? "Enabled" : "Disabled"}
                  </span>
                  <Switch
                    onChange={handleMfa}
                    checked={mfa}
                    className="react-switch"
                  />
                </label>

                {mfa ? (
                  <>
                    <p>Mobile *</p>
                    <Input
                      type="text"
                      name="mobile-field"
                      placeholder="Mobile"
                      value={mobile}
                      onChange={handleMobile}
                    />
                    <p className="red-alert">{MobileError}</p>
                  </>
                ) : null}

                <p>&nbsp;</p>

                <button
                  className="link marg-up-10"
                  value="register"
                  disabled={bussUnitsList.length <= 0 && role === "Manager"}
                >
                  Create User
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddUser;
