import {
  SET_CHAT_DATA,
  CLEAR_CHAT_DATA,
  SET_CHAT_LOADING_FALSE,
  SET_CHAT_LOADING_TRUE,
} from "../actions/actionTypes";

const initialState = {
  isExportChatLoading: false,
  exportChatData: [],
  error: "",
};
interface IAction {
  type: string;
  payload: any;
}

const chatDataReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_CHAT_DATA: {
      return {
        ...state,
        exportChatData: action.payload,
      };
    }

    case SET_CHAT_LOADING_TRUE: {
      return {
        ...state,
        isExportChatLoading: true,
      };
    }

    case SET_CHAT_LOADING_FALSE: {
      return {
        ...state,
        isExportChatLoading: false,
      };
    }

    case CLEAR_CHAT_DATA: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};

export default chatDataReducer;
