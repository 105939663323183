import {
  GET_ROOMS_SUCCESS,
  GET_ROOMS_FAILURE,
  CREATE_ROOM_SUCCESS,
  CREATE_ROOM_FAILURE,
  SET_CONNECTION_DETAILS,
  DEL_ROOM_FAILURE,
  DEL_ROOM_SUCCESS,
  CLEAR_ROOM_DATA
} from "../actions/actionTypes";

const initialState = {
  rooms: [],
  successMessage: "",
  failureMessage: "",
  delSuccessMessage:"",
  delFailureMessage:"",
  connectionDetails: {},
};
interface IAction {
  type: string;
  payload: any;
}

const roomsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ROOMS_SUCCESS: {
      return {
        ...state,
        rooms: action.payload?.rooms,
      };
    }
    case GET_ROOMS_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload?.errorMessage,
      };
    }
    case CREATE_ROOM_SUCCESS: {
      return {
        ...state,
        successMessage: "Created room successfully",
      };
    }

    case CREATE_ROOM_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload,
      };
    }

    case DEL_ROOM_SUCCESS: {
      return {
        ...state,
        delSuccessMessage: action.payload,
      };
    }

    case DEL_ROOM_FAILURE: {
      return {
        ...state,
        delFailureMessage: action.payload,
      };
    }


    case SET_CONNECTION_DETAILS: {
      return {
        ...state,
        connectionDetails: action.payload,
      };
    }

    case CLEAR_ROOM_DATA:{
      return initialState
    }
    default: {
      return state;
    }
  }
};

export default roomsReducer;
