import request from "./index";
import { timeZoneFormat } from "../utils/dateformat/index";

const { format } = timeZoneFormat();
export const viewAllUsers = async () => {
  const userDetailsResponse = await request.get("/getusers");
  return userDetailsResponse.data;
};

export const listAllUsers = async () => {
  const body = {
    format,
  };
  const usersListResponse = await request.post("/getUsersList", body);
  return usersListResponse.data;
};
