import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "react-rainbow-components";
import Grid from "@mui/material/Grid";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Chip,
  Autocomplete,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import BasicTable from "../../components/BasicTable";
import { reportAPI } from "../../utils/api";
import LoadingSpinner from "../../components/Spinner";
import Header from "../../components/Header";
import { CSVLink } from "react-csv";
import { timeZoneFormat } from "../../utils/dateformat";
import moment from "moment";
import { useSelector } from "react-redux";

// import _ from "lodash";
function Report() {
  const [value, setValue] = React.useState(new Date());
  const [value2, setValue2] = React.useState(new Date());
  const [value3, setValue3] = React.useState();
  const [, setUser] = React.useState();
  const [rooms, setRooms] = React.useState();
  const [bussUnits, setBusUnits] = React.useState();
  const [show, setShow] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const [sumaryData, setSumaryData] = React.useState();
  const [isActive, setActive] = useState(false);
  const [userData, setUserData] = React.useState();
  const [dataByDay, setDataByDay] = React.useState([]);
  const [csvData, setCsvData] = React.useState([]);
  const [loadCsvData, setLoadCsvData] = React.useState("");
  const [users, setUsers] = useState([{ label: "All Users", value: "" }]);
  const userRole = useSelector((state) => state.userDetails.role);
  const { format } = timeZoneFormat();
  const handleChange = (newValue) => {
    console.log("newValue", newValue);
    setValue(newValue);

    formik.setFieldValue("dateFrom", new Date(newValue));
  };
  const handleChange2 = (newValue) => {
    setValue2(newValue);

    formik.setFieldValue("dateTo", new Date(newValue));
  };

  const handleChange3 = (newValue) => {
    console.log(newValue);
    if (newValue !== null) {
      setValue3(newValue.value);
      formik.setFieldValue("username", newValue.value);
    } else {
      setValue3("");
      formik.setFieldValue("username", "");
    }
  };
  const initialValues = {
    dateFrom: new Date(),
    dateTo: new Date(),
    username: "",
    role: "",
    roomname: "",
    bussunit: "",
  };
  const validationSchema = Yup.object().shape({
    dateFrom: Yup.date().nullable(),
    dateto: Yup.date().nullable(),
  });
  function onSubmit(values) {
    const { dateFrom, dateTo, username,role , roomname, bussunit } = values;
    // alert(JSON.stringify(values));
    setShow(false);
    setShowMore(false);
    setActive(true);
    console.log(dateFrom, dateTo);
    reportAPI
      .getSummary({ dateFrom, dateTo, username, role, roomname, bussunit })
      .then((res) => {
        console.log("responseeeeeeeee", res);
        setActive(false);
        setSumaryData(res?.data);
      });
  }

  const getuser = () => {
    reportAPI.gettAll().then((res) => {
      setUser(res.data);
      let labeledUsers = [];
      // eslint-disable-next-line array-callback-return
      res.data.map((user, index) => {
        labeledUsers.push({ label: user.username, value: user.username });
      });

      labeledUsers.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return labelA.localeCompare(labelB);
      });

      setUsers(labeledUsers);
      console.log(labeledUsers);
    });
  };
  const getrooms = () => {
    reportAPI.gettRooms().then((res) => {
      setRooms(res.data);
    });
  };

  const getbussunit = () => {
    reportAPI.getBussUnits().then((res) => {
      setBusUnits(res.data);
    });
  };

  useEffect(() => {
    getuser();
    getrooms();
    getbussunit();
  }, []);

  // const dateFormat = (date) => {
  //   const newdate = new Date(date).toDateString();
  //   return newdate;
  // };
  const gettime = (date) => {
    if (date == null) {
      return "Session is still in progress";
    }
    const newtime = new Date(date).toLocaleTimeString();
    return newtime;
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleShow = (selectedUser) => {
    console.log(formik.values);
    setShow(true);
    setDataByDay([]);
    localStorage.setItem("selectedUser", JSON.stringify(selectedUser));
    const { dateFrom, dateTo, roomname, bussunit } = formik.values;
    console.log(
      "dateFrom, dateTo, username, roomname",
      dateFrom,
      dateTo,
      value
    );
    reportAPI
      .getSummaryByUser({ dateFrom, dateTo, selectedUser, roomname, bussunit })
      .then((res) => {
        console.log("responseeeeeeeee", res);
        setUserData(res?.data);
      });
  };
  const handleShowMore = (selectedDay) => {
    setShowMore(true);
    let selectedUser = JSON.parse(localStorage.getItem("selectedUser") || "");
    const { dateFrom, dateTo, roomname, bussunit } = formik.values;
    console.log(
      "dateFrom, dateTo, username, roomname",
      dateFrom,
      dateTo,
      value
    );
    reportAPI
      .getSummaryByDay({
        dateFrom,
        dateTo,
        selectedUser,
        selectedDay,
        roomname,
        bussunit,
      })
      .then((res) => {
        console.log("responseeeeeeeee", res);
        setDataByDay(res?.data);
      });
  };
  const getSumColumn = (arr, column) => {
    let sum = 0;
    arr?.forEach((el) => (sum += el[column]));
    return sum.toFixed(2);
  };

  const tempForAll = [
    "videoMintTotal",
    "screenMintTotal",
    "screenTwoMintTotal",
    "audioMintTotal",
    "lunchMintTotal",
    "brbMintTotal",
    "breakMintTotal",
    "engagedEmotionMintTotal",
    "disengagedEmotionMintTotal",
    // "otherEmotionsMintTotal",
  ];

  const templateSummaryData = ["_id","role","first_name","last_name", "roomname", "session", ...tempForAll];
  const templateUserData = [
    "username",
    "_id",
    "role",
    "first_name",
    "last_name",
    "roomname",
    "session",
    ...tempForAll,
  ];

  const templateDataByDay = [
    "selectedDay",
    "createdAt",
    "exitAt",
    "roomname",
    ...tempForAll,
  ];

  const csvHeaderTemplate = [
    "Video Minutes",
    "Screen Minutes",
    "Screen 2 Minutes",
    "Audio Minutes",
    "Lunch Minutes",
    "BrB Minutes",
    "Break Minutes",
    "Minutes Engaged",
    "Minutes Disengaged",
    // "Minutes Other Emotions",
  ];

  const exportToSummaryData = () => {
    setCsvData([]);
    setLoadCsvData("loading");
    const summaryArray = [
      ["Username","Role","First Name","Last Name", "Room", "Session Minutes", ...csvHeaderTemplate],
    ];

    // eslint-disable-next-line array-callback-return
    sumaryData.map((value) => {
      summaryArray.push(
        templateSummaryData.map((templateValue) => {
          let val;
          switch (templateValue) {
            case "_id":
              val = value[templateValue].username;
              break;
            case "role":
              val = value[templateValue];
              break;
            case "roomname":
              val = value[templateValue]
                .filter((v, i, a) => a.indexOf(v) === i)
                .toString();
              break;
            case "videoMintTotal":
            case "screenMintTotal":
            case "screenTwoMintTotal":
            case "audioMintTotal":
              val = Math.abs(value[templateValue]);
              break;
            default:
              val = value[templateValue];
              break;
          }
          return val;
        })
      );
    });

    setCsvData(summaryArray);
    setLoadCsvData("complete");
  };

  const exportToUserData = () => {
    setCsvData([]);
    setLoadCsvData("loading");
    const userDataArray = [
      ["username", "Date","Role","First Name","Last Name", "Room", "Session Minutes", ...csvHeaderTemplate],
    ];

    // eslint-disable-next-line array-callback-return
    userData.map((value) => {
      userDataArray.push(
        templateUserData.map((templateValue) => {
          let val;
          switch (templateValue) {
            case "username":
              val = value[templateValue][0];
              break;
            case "roomname":
              val = value[templateValue]
                .filter((v, i, a) => a.indexOf(v) === i)
                .toString();
              break;
            case "videoMintTotal":
            case "screenMintTotal":
            case "screenTwoMintTotal":
            case "audioMintTotal":
              val = Math.abs(value[templateValue]);
              break;
            default:
              val = value[templateValue];
              break;
          }
          return val;
        })
      );
    });

    setCsvData(userDataArray);
    setLoadCsvData("complete");
  };

  const exportToDataByDay = () => {
    setCsvData([]);
    setLoadCsvData("loading");
    const dataByDayArray = [
      ["Date", "Session Start", "Session End", "Room", ...csvHeaderTemplate],
    ];

    // eslint-disable-next-line array-callback-return
    dataByDay.map((value) => {
      dataByDayArray.push(
        templateDataByDay.map((templateValue) => {
          let val;
          switch (templateValue) {
            case "createdAt":
            case "exitAt":
              val = gettime(value[templateValue]);
              break;
            case "videoMintTotal":
            case "screenMintTotal":
            case "screenTwoMintTotal":
            case "audioMintTotal":
              val = Math.abs(value[templateValue]);
              break;
            default:
              val = value[templateValue];
              break;
          }
          return val;
        })
      );
    });

    setCsvData(dataByDayArray);
    setLoadCsvData("complete");
  };

  const columns = [
    {
      Header: "Username",
      accessor: "_id",
      Cell: ({ value }) => (
        <Box
          sx={{ "&:hover": { color: "blue" }, cursor: "pointer" }}
          onClick={() => handleShow(value.username)}
        >
          {value.username}
        </Box>
      ),
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "Room",
      accessor: "roomname",
      Cell: ({ value }) =>
        value
          .filter((v, i, a) => a.indexOf(v) === i)
          .map((value) => <Chip label={value} />),
      Footer: "Total",
    },
    {
      Header: "Session Minutes",
      accessor: "session",
      Footer: getSumColumn(sumaryData, "session"),
    },
    {
      Header: "Video Minutes",
      accessor: "videoMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(sumaryData, "videoMintTotal"),
    },
    {
      Header: "Screen Minutes",
      accessor: "screenMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(sumaryData, "screenMintTotal"),
    },
    {
      Header: "Screen 2 Minutes",
      accessor: "screenTwoMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(sumaryData, "screenTwoMintTotal"),
    },
    {
      Header: "Audio Minutes",
      accessor: "audioMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(sumaryData, "audioMintTotal"),
    },
    {
      Header: "Lunch Minutes",
      accessor: "lunchMintTotal",
      Footer: getSumColumn(sumaryData, "lunchMintTotal"),
    },
    {
      Header: "Brb Minutes",
      accessor: "brbMintTotal",
      Footer: getSumColumn(sumaryData, "brbMintTotal"),
    },
    {
      Header: "Break Minutes",
      accessor: "breakMintTotal",
      Footer: getSumColumn(sumaryData, "breakMintTotal"),
    },
    {
      Header: "Minutes Engaged",
      accessor: "engagedEmotionMintTotal",
      Footer: getSumColumn(sumaryData, "engagedEmotionMintTotal"),
    },
    {
      Header: "Minutes Disengaged",
      accessor: "disengagedEmotionMintTotal",
      Footer: getSumColumn(sumaryData, "disengagedEmotionMintTotal"),
    },
    // {
    //   Header: "Minutes Other Emotions",
    //   accessor: "otherEmotionsMintTotal",
    //   Footer: getSumColumn(sumaryData, "otherEmotionsMintTotal"),
    // },
  ];
  const columns2 = [
    {
      Header: "Username",
      accessor: "username",
      Cell: ({ value }) => <Box>{value ? value[0] : null}</Box>,
    },
    {
      Header: "Date",
      accessor: "_id",
      Cell: ({ value }) => (
        <Box
          sx={{ "&:hover": { color: "blue" }, cursor: "pointer" }}
          onClick={() => handleShowMore(value)}
        >
          {moment(value).format(format).split(" ")[0]}
        </Box>
      ),
    },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "Room",
      accessor: "roomname",
      Cell: ({ value }) =>
        value
          .filter((v, i, a) => a.indexOf(v) === i)
          .map((value) => <Chip label={value} />),
      Footer: "Total",
    },
    {
      Header: "Session Minutes",
      accessor: "session",
      Footer: getSumColumn(userData, "session"),
    },
    {
      Header: "Video Minutes",
      accessor: "videoMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(userData, "videoMintTotal"),
    },
    {
      Header: "Screen Minutes",
      accessor: "screenMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(userData, "screenMintTotal"),
    },
    {
      Header: "Screen 2 Minutes",
      accessor: "screenTwoMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(userData, "screenTwoMintTotal"),
    },
    {
      Header: "Audio Minutes",
      accessor: "audioMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(userData, "audioMintTotal"),
    },
    {
      Header: "Lunch Minutes",
      accessor: "lunchMintTotal",
      Footer: getSumColumn(userData, "lunchMintTotal"),
    },
    {
      Header: "Brb Minutes",
      accessor: "brbMintTotal",
      Footer: getSumColumn(userData, "brbMintTotal"),
    },
    {
      Header: "Break Minutes",
      accessor: "breakMintTotal",
      Footer: getSumColumn(userData, "breakMintTotal"),
    },
    {
      Header: "Minutes Engaged",
      accessor: "engagedEmotionMintTotal",
      Footer: getSumColumn(userData, "engagedEmotionMintTotal"),
    },
    {
      Header: "Minutes Disengaged",
      accessor: "disengagedEmotionMintTotal",
      Footer: getSumColumn(userData, "disengagedEmotionMintTotal"),
    },
    // {
    //   Header: "Minutes Other Emotions",
    //   accessor: "otherEmotionsMintTotal",
    //   Footer: getSumColumn(userData, "otherEmotionsMintTotal"),
    // },
  ];
  const columns3 = [
    {
      Header: "Date",
      accessor: "selectedDay",
      Cell: ({ value }) => (
        <Box>{moment(value).format(format).split(" ")[0]}</Box>
      ),
    },
    {
      Header: "Session Start",
      accessor: "createdAt",
      Cell: ({ value }) => <Box>{gettime(value)}</Box>,
    },
    {
      Header: "Session End",
      accessor: "exitAt",
      Cell: ({ value }) => <Box>{gettime(value)}</Box>,
      Footer: "Total",
    },
    {
      Header: "Room",
      accessor: "roomname",
      Cell: ({ value }) => <Chip label={value} />,
      Footer: "Total",
    },
    {
      Header: "Video Minutes",
      accessor: "videoMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(dataByDay, "videoMintTotal"),
    },
    {
      Header: "Screen Minutes",
      accessor: "screenMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(dataByDay, "screenMintTotal"),
    },
    {
      Header: "Screen 2 Minutes",
      accessor: "screenTwoMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(dataByDay, "screenTwoMintTotal"),
    },
    {
      Header: "Audio Minutes",
      accessor: "audioMintTotal",
      Cell: ({ value }) => <span>{Math.abs(value)}</span>,
      Footer: getSumColumn(dataByDay, "audioMintTotal"),
    },
    {
      Header: "Lunch Minutes",
      accessor: "lunchMintTotal",
      Footer: getSumColumn(dataByDay, "lunchMintTotal"),
    },
    {
      Header: "Brb Minutes",
      accessor: "brbMintTotal",
      Footer: getSumColumn(dataByDay, "brbMintTotal"),
    },
    {
      Header: "Break Minutes",
      accessor: "breakMintTotal",
      Footer: getSumColumn(dataByDay, "breakMintTotal"),
    },
    {
      Header: "Minutes Engaged",
      accessor: "engagedEmotionMintTotal",
      Footer: getSumColumn(dataByDay, "engagedEmotionMintTotal"),
    },
    {
      Header: "Minutes Disengaged",
      accessor: "disengagedEmotionMintTotal",
      Footer: getSumColumn(dataByDay, "disengagedEmotionMintTotal"),
    },
    // {
    //   Header: "Minutes Other Emotions",
    //   accessor: "otherEmotionsMintTotal",
    //   Footer: getSumColumn(dataByDay, "otherEmotionsMintTotal"),
    // },
  ];

  return (
    <>
      <Header />
      <Container>
        <Typography mt={5} textAlign={"center"} variant="h3">
          Reports
        </Typography>

        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          display={"flex"}
          justifyContent={"Center"}
        >
          <Grid
            container
            spacing={4}
            mt={5}
            display={"flex"}
            justifyContent={"Center"}
          >
            <DateTimePicker
              value={value}
              name="dateFrom"
              label="Start Date&Time"
              formik={formik}
              onChange={handleChange}
              className="rainbow-m-around_small datepicker-space"
              hour24
            />
            <DateTimePicker
              value={value2}
              name="dateTo"
              label="End Date&Time"
              formik={formik}
              onChange={handleChange2}
              className="rainbow-m-around_small datepicker-space"
              hour24
            />

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={4}
                    display={"flex"}
                    justifyContent={"Center"}
                    alignItems="center"
                  >
                    <Grid item xs={5}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5}></Grid>
                  </Grid>

                  <Grid
                    mt={2}
                    container
                    spacing={4}
                    display={"flex"}
                    justifyContent={"Center"}
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          name="username"
                          value={value3}
                          options={users}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="All User" />
                          )}
                          onChange={(event, value) => handleChange3(value)}
                        />
                        {/*<Select*/}
                        {/*  labelId="demo-simple-select-label"*/}
                        {/*  id="demo-simple-select"*/}
                        {/*  name="username"*/}
                        {/*  label="Select Type"*/}
                        {/*  formik={formik}*/}
                        {/*  value={formik.values.username}*/}
                        {/*  onChange={formik.handleChange}*/}
                        {/*  onBlur={formik.handleBlur}*/}
                        {/*  error={*/}
                        {/*    formik.touched.username &&*/}
                        {/*    Boolean(formik.errors.username)*/}
                        {/*  }*/}
                        {/*  helperText={*/}
                        {/*    formik.touched.username && formik.errors.username*/}
                        {/*  }*/}
                        {/*>*/}
                        {/*  {<MenuItem value={""}>All Users</MenuItem>}*/}
                        {/*  {user*/}
                        {/*    ? user.map((item, index) => {*/}
                        {/*        return (*/}
                        {/*          <MenuItem value={item.username}>*/}
                        {/*            {item.username}*/}
                        {/*          </MenuItem>*/}
                        {/*        );*/}
                        {/*      })*/}
                        {/*    : null}*/}
                        {/*</Select>*/}
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    {/* <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          All Roles
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="role"
                          label="Role"
                          formik={formik}
                          value={formik.values.role}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.role &&
                            Boolean(formik.errors.role)
                          }
                          helperText={
                            formik.touched.role && formik.errors.role
                          }
                        >
                          <MenuItem value={""}>All Roles</MenuItem>
                          <MenuItem value={"Agent"}>Agent</MenuItem>
                          <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                          <MenuItem value={"Manager"}>Manager</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          All Rooms
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="roomname"
                          label="Rooms"
                          formik={formik}
                          value={formik.values.roomname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.roomname &&
                            Boolean(formik.errors.roomname)
                          }
                          helperText={
                            formik.touched.roomname && formik.errors.roomname
                          }
                        >
                          {/* {userRole !== "Director" ? ( */}
                          <MenuItem value={""}>All Rooms</MenuItem>
                          {/* ) : (
                            ""
                          )} */}

                          {rooms
                            ? rooms.map((item, index) => {
                                return (
                                  <MenuItem value={item.roomname}>
                                    {item.roomname}
                                  </MenuItem>
                                );
                              })
                            : "Loading"}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {userRole === "Supervisor" ? bussUnits?.length > 0 && bussUnits[0]?.bussunit : "All Business Units"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="bussunit"
                          label="Business Units"
                          formik={formik}
                          value={formik.values.bussunit}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.bussunit && Boolean(formik.errors.bussunit)}
                          helperText={formik.touched.bussunit && formik.errors.bussunit}
                          disabled={userRole === "Supervisor"}  // Disable the dropdown for Supervisors
                        >
                          
                          <MenuItem value={""}>All Business Units</MenuItem>
                          {bussUnits
                            ? bussUnits.map((item, index) => (
                                <MenuItem key={index} value={item.bussunit}>
                                  {item.bussunit}
                                </MenuItem>
                              ))
                            : "Loading"
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11} display={"flex"} justifyContent={"end"}>
              <Button type="submit" variant="contained">
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <LoadingSpinner active={isActive || loadCsvData === "loading"} />
      {sumaryData ? (
        <CSVLink
          className="link"
          data={csvData}
          onClick={(event) => {
            exportToSummaryData();
          }}
        >
          Export Summary Data
        </CSVLink>
      ) : null}
      <BasicTable columns={columns} data={sumaryData ?? []} />

      {show ? (
        <>
          <CSVLink
            className="link"
            data={csvData}
            onClick={(event) => {
              exportToUserData();
            }}
          >
            Export User Data
          </CSVLink>
          <BasicTable columns={columns2} data={userData ?? []} />
        </>
      ) : null}

      {showMore ? (
        <>
          <CSVLink
            className="link"
            data={csvData}
            onClick={(event) => {
              exportToDataByDay();
            }}
          >
            Export Data By Day
          </CSVLink>
          <BasicTable columns={columns3} data={dataByDay ?? []} />
        </>
      ) : null}
    </>
  );
}

export default Report;
