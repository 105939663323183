import {
  GET_SPECIFIC_USER_DETAILS_SUCCESS,
  GET_SPECIFIC_USER_DETAILS_FAILURE,
  CLEAR_GETUSERDETAIL_DATA,
  SET_MFA,
} from "../actions/actionTypes";

const initialState = {
  userName: "",
  firstName: "",
  lastName: "",
  role: "",
  mfa: true,
  complexPassword: false,
  team: "",
  bussunit: "",
  mobile: "",
  loginlock: false,
  errorMessage: "",
  appUpdate: false,
  enableDesktopApp: false,
  email: "",
  faceVerification: false,
  grouprooms: [],
};
interface IAction {
  type: string;
  payload: any;
}

const getUserDetail = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_SPECIFIC_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userName: action.payload.details.username,
        firstName: action.payload.details.first_name,
        lastName: action.payload.details.last_name,
        role: action.payload.details.role,
        mfa: action.payload.details.mfa,
        complexPassword: action.payload.details?.complex_password,
        team: action.payload.details.team,
        bussunit: action.payload.details.bussunit,
        mobile: action.payload.details.mobile,
        loginlock: action.payload.details.loginlock,
        appUpdate: action.payload.details.appUpdate,
        enableDesktopApp: action.payload.details.enableDesktopApp,
        email: action.payload.details.email,
        faceVerification:
          action.payload.details.faceVerification.isFaceVerificationEnabled,
          grouprooms:action.payload.details.grouprooms
      };
    }
    case SET_MFA: {
      return {
        ...state,
        mfa: action.payload.mfa,
      };
    }

    case GET_SPECIFIC_USER_DETAILS_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload?.errorMessage,
      };
    }
    case CLEAR_GETUSERDETAIL_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default getUserDetail;
