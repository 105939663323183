import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  CLEAR_USERDETAIL_DATA,
} from "../actions/actionTypes";

const initialState = {
  userName: "",
  firstName: "",
  lastName: "",
  role: "",
  errorMessage: "",
  bussunit:""
};
interface IAction {
  type: string;
  payload: any;
}

const modalReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userName: action.payload?.username,
        firstName: action.payload?.firstName,
        lastName: action.payload?.lastName,
        role: action.payload?.role,
        bussunit: action.payload?.bussunit
      };
    }
    case GET_USER_DETAILS_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload?.errorMessage,
      };
    }
    case CLEAR_USERDETAIL_DATA:{
      return initialState
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
