import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
// import reportWebVitals from './reportWebVitals';
import { createReduxStore } from "./redux";
import { Provider } from "react-redux";
import ErrorBoundary from "./components/ErrorBoundary";


// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

const store = createReduxStore();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

