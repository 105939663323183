import { Dispatch } from "react";
import { toast } from "react-toastify";
import {
  CLEAR_CHAT_DATA,
  SET_CHAT_DATA,
  SET_CHAT_LOADING_FALSE,
  SET_CHAT_LOADING_TRUE,
} from "./actionTypes";
import { getChatData } from "../services/chatData";

export const getExportChatDataActions = (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: Array<string>
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: SET_CHAT_LOADING_TRUE });
    try {
      let responseData;

      responseData = await getChatData(starttime, endtime, rname, roomname);

      if (responseData.success) {
        dispatch({
          type: SET_CHAT_DATA,
          payload: responseData.data,
        });
      } else {
        toast.error("Please ensure start time is less than end time");
      }
      // return responseData;
      return Promise.resolve();
    } catch (error: any) {
      if (error.message && error.message.message) {
        toast.error(error.message.message);
      } else {
        toast.error("Incorrect Fields");
      }
    } finally {
      dispatch({ type: SET_CHAT_LOADING_FALSE });
    }
  };
};

export const clearExportChatDataActions = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({ type: CLEAR_CHAT_DATA });
  };
};
