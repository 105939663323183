/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Colors,
} from "chart.js";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { Badge } from "react-bootstrap";
import "./index.css";
import { timeZoneFormat } from "../../utils/dateformat";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { getSignedUrl } from "../../services/desktopActivity";
import { getDesktopApps } from "../../services/desktopActivity";
import { toast } from "react-toastify";

import { CSVLink } from "react-csv";

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

// interface AppsState {
//   productive: string[];
//   nonProductive: string[];
//   others: string[];
// }
interface Props {
  doughNutData: any;
  activityFilter: any;
}
const ModalDoughnutChart: React.FC<Props> = ({ doughNutData, activityFilter }) => {
  const doughNutRef = React.useRef<any>();

  let appNames = Object.keys(doughNutData.activity);
  let appsDataArray = Object.entries(doughNutData.activity);
  const tableDataRef = React.useRef<any>();
  const [tableData, setTableData] = useState<any>([]);
  const { format } = timeZoneFormat();
  const [csvData, setCsvData] = React.useState<any>([]);
  const [browsers, setBrowsers] = useState<any>([]);
  const [desktopApps, setDesktopApps] = useState<any>({
    productive: [],
    nonProductive: [],
    others: [],
  })
  const [apps, setApps] = useState<any>({
    productive: [],
    nonProductive: [],
    others: [],
  });
  let time = 0;
  const convertToMinutes = (time: number) => {
    // @ts-ignore
    return Math.floor(time / 60) || Number(parseFloat(time / 60).toFixed(2));
  };

  const valueInPercent = (seriesData: Array<any>) => {
    var accumulatedValue = seriesData.reduce((a, v) => a + v);
    var percentValue = seriesData.map((v) =>
      Math.max((v / accumulatedValue) * 100, 1)
    );

    return percentValue;
  };

  const calculateDataSet = (dataSet) => {
    let ans = [];
    dataSet.forEach((ele) => {
      let sum = 0;
      let minsSum = 0;
      Object.entries(ele[1]).forEach((elem: any) => {
        sum = sum + elem[1].time;
      });

      minsSum = convertToMinutes(sum);
      // @ts-ignore
      ans.push(minsSum);
    });
    return ans;
  };

  const desktopAppsList = async () => {
    const apiResponse = await getDesktopApps();
    if (apiResponse.success) {
      setBrowsers(apiResponse.data.browser.browsers);
      setDesktopApps({
        productive: apiResponse.data.productive,
        nonProductive: apiResponse.data.nonProductive,
        ideal: apiResponse.data.ideal
      });
      delete apiResponse.data.browser.browsers;
      setApps(apiResponse.data.browser);
    } else {
      toast("Unable to fetch list of Applications");
    }
    console.log("APPS FROM DB: ", apiResponse);
  };

  useEffect(() => {
    setTableData([]);
    desktopAppsList();
  }, [doughNutData, activityFilter]);
  const dataSet = calculateDataSet(appsDataArray);
  var seriesData = dataSet;
  // @ts-ignore
  var percentValue = valueInPercent(seriesData);

  let data = {
    labels: appNames,
    datasets: [
      {
        label: "Time Activity in minutes.",
        data: percentValue,
        hoverOffset: 4,
      },
    ],
  };
  const [appsData, setAppsData] = React.useState<any>(data);
  // const appDataRef = React.useRef<any>();

  const handleClick = (event) => {
    const elem = getElementAtEvent(doughNutRef.current, event);
    setTableData(appsDataArray[elem[0].index]);
    tableDataRef.current = appsDataArray[elem[0].index];
    console.log(Object.entries(tableDataRef.current[1]), tableDataRef.current, {
      elem,
    });
  };

  const secondsToHms = (d) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    const sDisplay = s > 0 ? s + (s === 1 ? " second " : " seconds") : "";

    return hDisplay + mDisplay + sDisplay;
  };

  const options = {
    scale: {
      type: "radialLinear",
      ticks: {
        display: false,
        min: 0,
        max: 20,
        stepSize: 1,
      },
      gridLines: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      colors: {
        forceOverride: true,
      },
      tooltips: {
        enabled: true,
        mode: "nearest",
        callbacks: {
          label: function (tooltipItem, data) {
            var value = seriesData[tooltipItem.index];
            var label = appNames[tooltipItem.index];
            return `${label}: ${value}`;
          },
        },
      },
    },
  };

  const fetchImage = async (e: any, url: any) => {
    e.preventDefault();
    const res = await getSignedUrl(url);
    window.open(res);
    console.log(res);
  };

  const exportToUserActivityByDay = () => {
    setCsvData([]);
    // setLoadCsvData("loading");
    const summaryArray: any = [
      ["Date", "Room", "App", "Total Time", "Type"]
    ];
    const templateSummaryData = ["createdAt", "room", "app", "time", "type"];

    let appNames = Object.keys(doughNutData.activity);
    console.log(appNames);
    let timeSpentOnApps = calculateDataSet(Object.entries(doughNutData.activity));
    appNames.forEach((value, index) => {
      let timeSpentOnWebsites: any = [];
      summaryArray.push(
        templateSummaryData.map((templateValue) => {
          let val = value[templateValue];
          switch (templateValue) {
            case "createdAt":
              val = moment(doughNutData.createdAt).format(format).split(" ")[0];
              break;
            case "room":
              val = doughNutData.room;
              break;
            case "app":
              val = value;
              break;
            case "time":
              val = secondsToHms(timeSpentOnApps[index] * 60);
              break;
            case "type":
              val = "Unclassified";
              console.log(desktopApps.productive, value);
              if (desktopApps.productive.includes(value)) val = "Productive";
              if (desktopApps.nonProductive.includes(value)) val = "Non Productive";
          }
          return val;
        })
      );
      if (browsers.includes(value)) {
        let websites = Object.keys(doughNutData.activity[value]);
        summaryArray.push(["", "", "WEBSITE", "TIME", "TYPE"]);
        websites.forEach((website, idx) => {
          let timeOnWebsite = secondsToHms(doughNutData.activity[value][website].time);
          timeSpentOnWebsites.push(timeOnWebsite);
          let type = "Unclassified";
          if (apps.productive.includes(website)) type = "Productive";
          if (apps.nonProductive.includes(website)) type = "Non Productive";
          summaryArray.push(["", "", website, timeOnWebsite, type])
        });
      }
    });

    setCsvData(summaryArray);
    console.log(summaryArray);
  };

  React.useEffect(() => {
    let updatedAppNames = Object.keys(doughNutData.activity);
    const updatedDataSet = calculateDataSet(
      Object.entries(doughNutData.activity)
    );

    console.log(updatedAppNames, updatedDataSet);
    var seriesData = updatedDataSet;
    // @ts-ignore
    var inPercent = valueInPercent(seriesData);

    console.log({ appsData, doughNutData });
    setAppsData({
      labels: updatedAppNames,
      datasets: [
        {
          label: "Time Activity in Percentage",
          data: inPercent,
          hoverOffset: 4,
        },
      ],
    });

    console.log(doughNutData?.screenshots);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doughNutData]);

  return (
    <>
      {doughNutData.activity ? <CSVLink
        className="link"
        filename={`summary-by-day-${Date.now()}`}
        data={csvData}
        onClick={(event) => {
          exportToUserActivityByDay();
        }}
      >
        Export Activity By Day
      </CSVLink>
        : null}
      <div className="chart-container-doughnut">
        <div style={{ width: "30%", height: "30%" }}>
          <Doughnut
            onClick={handleClick}
            data={appsData}
            options={options}
            ref={doughNutRef}
          ></Doughnut>
        </div>
        {!tableData.length ? (
          <p className="pre-info">
            Click Chart on the left to see more information
          </p>
        ) : (
          ""
        )}
        {tableData.length ? (
          <table className="tbl">
            <thead>
              <tr>
                <th colSpan={2}>
                  {tableData[0]} -

                  {
                    // eslint-disable-next-line array-callback-return
                    Object.entries(tableData[1]).map((ele: any, key) => {
                      time = time + ele[1].time;
                    })}
                  ({secondsToHms(time)})
                </th>
              </tr>
              <tr>
                <th>Name</th>
                <th>Time Spent</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(tableData[1]).map((ele: any, key) => (
                <tr>
                  <td className="dnt-td" title={ele[0]}>
                    {ele[0]
                      ? ele[0].length < 25
                        ? ele[0]
                        : ele[0].substring(0, 25) + "..."
                      : "-"}
                    {apps.productive.includes(ele[0])
                      ? <Badge className="badge-success mr-1">
                        productive
                      </Badge> : ""
                    }
                    {apps.nonProductive.includes(ele[0]) ?
                      <Badge className="badge-warning mr-1">
                        nonproductive
                      </Badge> : ""
                    }
                    {apps.others.includes(ele[0]) ? <Badge className="badge-secondary mr-1">
                      unclassified
                    </Badge> : ""
                    }
                  </td>
                  <td className="dnt-td">{secondsToHms(ele[1].time)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>
      <div>
        <hr />
        {doughNutData?.screenshots && doughNutData?.screenshots.length ? (
          <>
            <p className="app-time-heading">Violated Applications</p>
            <table className="mdl-tbl-dnt violated-apps-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>App Name</th>
                  <th>Image URL</th>
                  <th>Date/Time</th>
                </tr>
              </thead>
              <tbody>
                {doughNutData?.screenshots.map((ele: any, key) => (
                  <tr>
                    <td>{ele.title}</td>
                    <td title={ele.appName}>{ele.appName}</td>
                    <td>
                      <a
                        href={ele.url}
                        onClick={(e) => fetchImage(e, ele.url)}
                        title="Click to view Image"
                        target="_blank"
                        style={{ color: "#111", fontSize: "1.2rem" }}
                      >
                        <FontAwesomeIcon icon={faImage} />
                      </a>
                    </td>
                    <td>{moment(ele.time).format(format)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ModalDoughnutChart;
