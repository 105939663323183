import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { CLOSE_MODAL } from "../../actions/actionTypes";
import { RootState } from "../../redux";
import { delRoom } from "../../actions/roomActions";

import "./styles.css";

const ModalComponent = () => {
  const modalShow = useSelector((state: RootState) => state.modal.isDelOpen);
  const roomname = useSelector((state: RootState) => state.modal.roomName);
  const modalMessage = useSelector((state: RootState) => state.roomsData.delSuccessMessage);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };

  const confirmDelete = (e)=> {
      e.preventDefault();
    dispatch(delRoom(roomname));
    handleModalClose();
  }

  return (
    <Modal show={modalShow} onHide={handleModalClose}>
       <Modal.Header closeButton>
            <Modal.Title>Delete Room</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="roomname-container">
                <h5 style={{textAlign:"center",width:"100%"}}>{modalMessage}</h5>
              </div>
             
              <div className="submit-btn mt-3 del_mod" style={{display:"flex",}}>
                <button className="link" onClick={confirmDelete}>
                  Confirm
                </button>
                <button className="link" onClick={handleModalClose}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
