import {
  GET_ALL_FEEDS_SUCCESS,
  GET_ALL_FEEDS_FAILURE,
  EDIT_FEED_SUCCESS,
  EDIT_FEED_FAILURE,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_FAILURE,
  CLEAR_VIEWFEEDS_DATA,
} from "../actions/actionTypes";


// _id(pin):"64b904355a1fdc53aa9abbb2"
// username(pin):"JAGDEEPSINGH"
// topicId(pin):"64ad1bfeda2077e00bccfe35"
// title(pin):"Hey"
// description(pin):"this is just a simple post, nothing useful in this other than words but words are important so this post is also important."
// createdAt(pin):"2023-07-20T09:53:57.786Z"
// __v(pin):0


interface Feed{
  _id:string,
  username:string,
  topicId:string,
  title:string,
  description:string,
  type:string,
  createdAt:string,
  __v:number
}

interface IFeedDataType{
  feedList: Array<Feed>,
  successMessage: string,
  failureMessage: string,
  delSuccessMessage: string,
  delFailureMessage: string,
  editSuccessMessage: string,
  editFailureMessage: string,
}
const initialState:IFeedDataType = {
  feedList: [],
  successMessage: "",
  failureMessage: "",
  delSuccessMessage: "",
  delFailureMessage: "",
  editSuccessMessage: "",
  editFailureMessage: "",
};
interface IAction {
  type: string;
  payload: any;
}

const viewAllFeedsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ALL_FEEDS_SUCCESS: {
      return {
        ...state,
        feedList: action.payload.feeds,
      };
    }
    case GET_ALL_FEEDS_FAILURE: {
      return {
        ...state,
        failureMessage: action.payload.error,
        
      };
    }

    case EDIT_FEED_SUCCESS: {
      return{
        ...state,
        editSuccessMessage: action.payload.message,
        feedList:state.feedList.map((feed:any) => feed._id !== action.payload.feedId ? feed : {...feed,title:action.payload.title,description:action.payload.description,type:action.payload.type} )
      }
    }

    case EDIT_FEED_FAILURE: {
      return{
        ...state,
        editFailureMessage:action.payload.message
      }
    }

    case DELETE_FEED_SUCCESS:{
      return{
        ...state,
        delSuccessMessage:action.payload.message,
        feedList:state.feedList.filter((feed:Feed) => feed._id !== action.payload.feedId )

      }
    }
    case DELETE_FEED_FAILURE: {
      return {
        ...state,
        delFailureMessage: action.payload.error,
      };
    }


    case CLEAR_VIEWFEEDS_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default viewAllFeedsReducer;
