import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getBussUnits = async () => {
  const businessUnitsResponse = await request.get("/getBussUnit");
  return businessUnitsResponse.data;
};

export const createBussUnit = async (bussunit: string) => {
  const reqBody = qs.stringify({
    bussunit,
  });

  const createBussUnitResponse = await request.post(
    "/createBussUnit",
    reqBody,
    config
  );
  return createBussUnitResponse.data;
};

export const deleteBussUnit = async (bussunit: string) => {
  const reqBody = qs.stringify({
    bussunit,
  });

  const delBussUnitResponse = await request.post(
    "/deleteBussUnit",
    reqBody,
    config
  );
  return delBussUnitResponse.data;
};
