import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getRooms = async () => {
  const userDetailsResponse = await request.get("/getRooms");
  return userDetailsResponse.data;
};
export const getRoomsByBussUnit = async (bussunit:string) => {
  const reqBody = qs.stringify({
    bussunit,
  });
  const userDetailsResponse = await request.post("/getRoomsByBussUnit", reqBody, config);
  return userDetailsResponse.data;
};

export const createRoom = async (
roomName: string, maxParticipants: string, imageName: any, bussUnit: string, roomTypeValue: string, audioAllowedInRoom: boolean, allowPrivateCallinSilentRoom: boolean) => {
  const config_img = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const data = new FormData();
  data.append("roomname", roomName);
  data.append("maxParticipantCount", maxParticipants);
  data.append("file", imageName);
  data.append("bussunit", bussUnit);
  data.append("roomtype", roomTypeValue);
  data.append("audioAllowedInRoom", audioAllowedInRoom.toString());
  data.append("allowPrivateCallinSilentRoom", allowPrivateCallinSilentRoom.toString());

  const uploadResponse = await request.post("/createRoom", data, config_img);
  return uploadResponse.data;
};

export const deleteRoom = async (roomName: string) => {
  const reqBody = qs.stringify({
    roomname: roomName,
  });

  const uploadResponse = await request.post("/deleteRoom", reqBody, config);
  return uploadResponse.data;
};

export const getConnectionDetails = async (roomname: string) => {
  const reqBody = qs.stringify({
    roomname: roomname,
  });
  const connectionDetailsData = await request.post(
    "/getConnectionDetails",
    reqBody,
    config
  );
  return connectionDetailsData?.data?.connectionDetails;
};

export const editRoom = async (
  roomName: string,
  maxParticipants: string,
  bussUnit: string,
  roomTypeValue: string,
  audioAllowedInRoom: boolean,
  imageName: any,
  oldImageUrl: string,
  allowPrivateCallinSilentRoom: boolean
) => {
  const config_img = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const data = new FormData();
  data.append("roomname", roomName)
  data.append("maxParticipantCount", maxParticipants);
  data.append("bussunit", bussUnit);
  data.append("roomtype", roomTypeValue);
  data.append("audioAllowedInRoom", audioAllowedInRoom.toString());
  data.append("file", imageName);
  data.append("oldImageUrl", oldImageUrl);
  data.append("allowPrivateCallinSilentRoom", allowPrivateCallinSilentRoom.toString());


  const uploadResponse = await request.post("/editroom", data, config_img);
  return uploadResponse.data;
};
