import request from "./index";
import qs from "querystring";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const login = async (email?: string, password?: string) => {
  const requestBody = qs.stringify({
    username: email,
    password: password,
    access_type: "admin",
  });

  const response = await request.post("/adminlogin", requestBody, config);

  return response.data;
};

export const verifyOTP = async (
  email?: string, 
  OTP?: string 
) => {
  let requestBody = qs.stringify({
    username: email,    
    OTP,
    password: 'mfalogin',
  }); 

  const response = await request.post("/verifyotp", requestBody, config);
  return response.data;
};

export const logout = async () => {
  const response = await request.get("/logout");
  return response.data;
};
interface isSessionActiveResp {
  message: string;
  success: boolean;
}

export const isSessionActive = async (): Promise<isSessionActiveResp> => {
  const response = await request.get("/isSessionActive");
  return response.data;
};