import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_REPORT_NAME_FAILURE,
  GET_REPORT_NAME_SUCCESS,
} from "./actionTypes";
import { getReportName } from "../services/getReportName";

export const handleGetReportName = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await getReportName();
      if (responseData) {
        dispatch(setSuccess(responseData));
      } else {
        dispatch(setGetReportNameError("Incorrect Fields"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setGetReportNameError(error.message.message));
      } else {
        dispatch(setGetReportNameError("Incorrect Fields"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setSuccess = (responsedata: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_REPORT_NAME_SUCCESS,
      payload: responsedata.sort(),
    });
  };
};

const setGetReportNameError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_REPORT_NAME_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};
