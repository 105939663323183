import {
  CLEAR_DELUSER_DATA,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS
   } from "../actions/actionTypes";
   
   const initialState = {
     isUserDeleted: false,
     error: "",
   };
   interface IAction {
     type: string;
     payload: any;
   }
   
   const deleteUserReducer = (state = initialState, action: IAction) => {
     switch (action.type) {
       case DELETE_USER_SUCCESS: {
         return {
           ...state,
           isUserDeleted: true,
         };
       }
       case DELETE_USER_FAILURE: {
         return {
           ...state,
           isUserDeleted: false,
           error: action.payload.error,
         };
       }
       case CLEAR_DELUSER_DATA:{
        return initialState
      }
       default: {
         return state;
       }
     }
   };
   
   export default deleteUserReducer;
   