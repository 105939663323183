import {Dispatch} from "react";
import {getFeedbackImages} from "../services/feedbackImages";
import {GET_FEEDBACKIMAGES_SUCCESS, GET_FEEDBACKIMAGES_FAILURE} from "./actionTypes";
import {setLoading} from "./loaderActions";

export const getFeedbackImagesAction = () => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const feedbackImagesData = await getFeedbackImages();
            if (feedbackImagesData) {
                dispatch({
                    type: GET_FEEDBACKIMAGES_SUCCESS,
                    payload: feedbackImagesData
                });
            } else {
                dispatch({
                    type: GET_FEEDBACKIMAGES_FAILURE,
                    payload: "Could not fetch feedback images. Please try again"
                })
            }

            return feedbackImagesData;
        } catch (error: any) {
            dispatch({
                type: GET_FEEDBACKIMAGES_FAILURE,
                payload: error.message || "Could not fetch feedback images. Please try again"
            })
        } finally {
            dispatch(setLoading(false));
        }
    };
};
