import request from "./index";
import qs from "querystring";

const headers = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getWebrtcStats = async () => {
  const webrtcStats = await request.get("/getwebrtcstats");
  return webrtcStats.data;
};

export const getFilteredStats = async (starttime: string, endtime: string, username: string,roomname: string) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: username,
    roomname
  });
  const webrtcStats = await request.post("/getfilteredstats", requestBody, headers);
  return webrtcStats.data;
};

export const clearStats = async (type: string) => {
  const requestBody = qs.stringify({
    type
  });
  const webrtcStats = await request.post("/clearstats", requestBody, headers);
  return webrtcStats.data;
};