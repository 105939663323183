import { Dispatch } from "react";
import { SET_LOADING } from "./actionTypes";

export const setLoading = (isLoading: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_LOADING,
      payload: isLoading,
    });
  };
};
