import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const ActivityChart = ({
  totalProductiveTime,
  totalNonProductiveTime,
  totalNeutralTime,
  secondsToHms,
}) => {
  const data = {
    labels: [`Productive`, "Non Productive", "Unclassified"],
    datasets: [
      {
        label: "Time Activity in sec.",
        data: [totalProductiveTime, totalNonProductiveTime, totalNeutralTime],
        backgroundColor: ["#36A2EB", "#FF6384", "#abaaa9"],
        borderColor: ["#36A2EB", "#FF6384", "#abaaa9"],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    scale: {
      type: "radialLinear",
      ticks: {
        
        display: false,
        min: 0,
        max: 20,
        stepSize: 1,
      },
      gridLines: {
       
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  
  return (
    <div style={{ width: "100px", height: "100px",zIndex:2 }}>
      {/* @ts-ignore  */}
      <Doughnut onHover={() => {}} data={data} options={options}></Doughnut>
    </div>
  );
};

export default ActivityChart;
