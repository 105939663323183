import { useEffect, useState, useRef } from "react";
import Loader2 from "../../assets/images/loader2.gif";
import "./UserGeoLocationReport.css";
import Header from "./../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { RootState } from "../../redux";
import { DateTimePicker } from "react-rainbow-components";
import { getUserLocation } from "../../actions/getReportData";
import Collapsible from "react-collapsible";
import { reportAPI } from "../../utils/api";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L, { LatLngExpression, Map } from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { timeZoneFormat } from "../../utils/dateformat";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "leaflet.heat";
import moment from "moment";
import { CLEAR_LOCATION_DATA } from "../../actions/actionTypes";
import { FormControl, Autocomplete, TextField } from "@mui/material";
import greenMarker from "../../../public/green-marker.png";
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});



L.Marker.prototype.options.icon = DefaultIcon;

interface HeatmapDataPoint {
  latitude: number;
  longitude: number;
  intensity?: number;
  username: string;
  logintime: string;
}

const UserGeoLocationReport = (props: any) => {
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [, setPageno] = useState(1);
  const [isTrueName, setIsTrueName] = useState(false);
  const [name, setName] = useState("All");
  const [isTrueRoom, ] = useState(false);
  const [room] = useState("");

  const { format } = timeZoneFormat();
  const [activeTab, setActiveTab] = useState<string>("maps");

  const ReportData = useSelector(
    (state: RootState) => state.getReportData.locationReportData
  );

  const coordinatesData = useSelector(
    (state: RootState) => state.getReportData.Coordinates
  );
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map | null>(null);
  const heatLayerRef = useRef<any | null>(null);
  const markerLayerRef = useRef<L.LayerGroup | null>(null);
  const [mapMoving, ] = useState(false);
  const isReport = useSelector(
    (state: RootState) => state.getReportData.isReport
  );

  var LeafIcon = L.Icon.extend({
    options: {
        iconAnchor:   [22, 90],
        shadowAnchor: [4, 62],
        popupAnchor:  [-3, -76]
    }});

    //@ts-ignore
  const greenIcon = new LeafIcon({iconUrl: 'green-marker.png', iconAnchor: [22,100]});
  //@ts-ignore
  const blackIcon = new LeafIcon({iconUrl: 'black-marker.png'});
  //@ts-ignore
  const blueIcon = new LeafIcon({iconUrl: 'blue-marker.png', iconAnchor: [22,80]});

  const [nameArray, setNameArray] = useState([]);

  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  const dispatch = useDispatch();

  const formatDate = (date) => {
    var d = new Date(date);
    return d.toUTCString();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch({
        type: CLEAR_LOCATION_DATA,
      });
    };
  }, [dispatch]);

  const handleTabSelect = (tabKey) => {
    console.log(tabKey);
    setActiveTab(tabKey);
  };
  const getuser = () => {
    reportAPI.gettAll().then((res) => {
      let labeledUsers:any = [];
      // eslint-disable-next-line array-callback-return
      res.data.map((user: any, index) => {
        if (user && user.username) {
          // @ts-ignore
          labeledUsers.push({ label: user.username, value: user.username });
        }
      });
      labeledUsers.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return labelA.localeCompare(labelB);
      });
      labeledUsers.unshift({ label: "All Users", value: "All" })
      //@ts-ignore
      setNameArray(labeledUsers);
    });
  };

  function EnableF() {
    getuser();
    dispatch({
      type: "SET_LOADING",
      isLoading: false,
    });
  }

  const handleDatechange = (e: any, flag) => {
    if (flag === "start") {
      setStartdate(new Date(e));
    } else {
      setEnddate(new Date(e));
    }
  };

  const handleNameChange = (s: any) => {
    if (s.value === "All") {
      setIsTrueName(true);
      setName("");
      // return DoSearch(false, isTrueRoom, "", room);
    }
    setIsTrueName(true);
    setName(s.value);
    // return DoSearch(true, isTrueRoom, s.value, room);
  };

  const DoSearch = (
    nameCheck: boolean,
    roomCheck: boolean,
    nameData?: any,
    roomData?: any
  ) => {
    nameData = nameData === "All" ? "": nameData;
    setPageno(1);
    if (nameCheck && roomCheck) {
      dispatch(
        getUserLocation(
          formatDate(startdate),
          formatDate(enddate),
          nameData,
          roomData,
          1
        )
      );
    } else if (nameCheck) {
      dispatch(
        getUserLocation(
          formatDate(startdate),
          formatDate(enddate),
          nameData,
          "",
          1
        )
      );
    } else if (roomCheck) {
      dispatch(
        getUserLocation(
          formatDate(startdate),
          formatDate(enddate),
          "",
          roomData,
          1
        )
      );
    } else {
      dispatch(
        getUserLocation(formatDate(startdate), formatDate(enddate), "", "", 1)
      );
    }
  };

  const gettime = (date) => {
    return moment(date).format(format);
  };

  useEffect(() => {
    EnableF();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addMarkers = () => {
    if (mapRef.current && markerLayerRef.current) {
      const currentZoomLevel = mapRef.current.getZoom();
      const maxZoomLevelToShowMarkers = 14;

      // Clear previous markers
      markerLayerRef.current.clearLayers();

      if (currentZoomLevel >= maxZoomLevelToShowMarkers) {
        console.log(coordinatesData);
        coordinatesData.forEach((point: HeatmapDataPoint) => {
          if (point.latitude && point.longitude) {
            const marker = L.marker([point.latitude, point.longitude]);
            marker.bindPopup(
              `${point.username} was here<br>${moment(point.logintime).format(
                format
              )}`
            );
            markerLayerRef.current!.addLayer(marker);
          }
        });
      }

      console.log({ currentZoomLevel });
    }
  };

  const getLatLong = (data: any): LatLngExpression | undefined => {
    if(data.latitude && data.longitude) {
      return [data.latitude, data.longitude];
    } else if(data.ipdata.data && data.ipdata.data.latitude && data.ipdata.data.longitude) {
      return [data.ipdata.data.latitude, data.ipdata.data.longitude];
    } else if (data.webrtc && data.webrtc.ipdata.latitude && data.webrtc.ipdata.longitude) {
      return [data.webrtc.ipdata.latitude, data.webrtc.ipdata.longitude]
    } else {
      return [0, 0]
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleZoomEnd = () => {
    addMarkers();
  };

  useEffect(() => {
    if (
      !isLoading &&
      coordinatesData &&
      coordinatesData.length > 0 &&
      activeTab === "maps" &&
      mapContainerRef.current &&
      !mapRef.current
    ) {
      // Create a new Leaflet map instance
      const map = L.map(mapContainerRef.current).setView(
        [37.0902, -95.7129],
        4
      );
      mapRef.current = map;

      // https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png
      L.tileLayer("https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png", {
        minZoom: 3,
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      const heatLayer = (L as any).heatLayer([], { radius: 25 }).addTo(map);
      heatLayerRef.current = heatLayer;

      const markerLayer = L.layerGroup().addTo(map);
      markerLayerRef.current = markerLayer;

      map.on("zoomend", handleZoomEnd);
    }

    return () => {
      // Clean up when component unmounts
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [isLoading, activeTab, coordinatesData, handleZoomEnd]);

  useEffect(() => {
    if (
      !isLoading &&
      activeTab === "maps" &&
      coordinatesData &&
      coordinatesData.length > 0 &&
      heatLayerRef.current
    ) {
      const latLngs = coordinatesData.map((point: HeatmapDataPoint) => [
        point.latitude,
        point.longitude,
      ]);

      var filtered = latLngs.filter(function (a) {
        return a.some(Boolean);
      });

      filtered.map((point) => point.push(70));

      heatLayerRef.current.setLatLngs(filtered);

      addMarkers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, activeTab, coordinatesData, mapMoving]);

  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">
          User Geo Location Report
        </span>

        <div className="datepicker-container">
          <DateTimePicker
            value={startdate}
            label="Start"
            onChange={(e: any) => handleDatechange(e, "start")}
            className="rainbow-m-around_small datepicker-space"
            hour24
            // format="yyyy-MM-dd HH:mm:ss"
          />
          <DateTimePicker
            value={enddate}
            label="End"
            onChange={(e: any) => handleDatechange(e, "end")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <br />
          <div className="text-center mt-4">
            <Button
              id="search"
              variant="info"
              onClick={() => DoSearch(isTrueName, isTrueRoom, name, room)}
            >
              Search
            </Button>
          </div>
        </div>
      </nav>
      <>
        <div
          className="filters-container mt-4"
          style={{ marginBottom: "10px" }}
        >
          <FormControl fullWidth style={{ width: "58%", marginLeft: "auto" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              // name="username"
              value={name}
              options={nameArray}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="All Users" />
              )}
              onChange={(event, value) => handleNameChange(value)}
            />
          </FormControl>
          {/* <Dropdown className="select-item">
            <Dropdown.Toggle
              variant="success"
              id="menu-dropdown-name"
              style={{ width: "90%" }}
            >
              {name ? name : "All"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: "400px",
                width: "90%",
                overflowY: "scroll",
              }}
            >
              <Dropdown.Item
                onClick={() => {
                  handleNameChange("All");
                }}
              >
                All
              </Dropdown.Item>
              {nameArray?.map((m, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    handleNameChange(m);
                  }}
                >
                  {m}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
        <Tabs
          defaultActiveKey="maps"
          id="uncontrolled-tab-example"
          className="mb-3 geo-tabs-container"
          onSelect={handleTabSelect}
        >
          <Tab eventKey="maps" title="View Heat Map" className="event-tab">
            {isLoading ? (
              <img src={Loader2} alt="" />
            ) : coordinatesData && coordinatesData.length ? (
              <div style={{ display: activeTab === "maps" ? "block" : "none" }}>
                <div
                  id="map"
                  style={{ width: "100%", height: "600px", zIndex: 0 }}
                  ref={mapContainerRef}
                ></div>
              </div>
            ) : (
              <div className="no-chart-div">No Map To Display</div>
            )}
          </Tab>
          <Tab eventKey="list" title="Users List" className="event-tab">
            <div className="row">
              <div className="report-container">
                {ReportData && ReportData.length > 0 ? (
                  <p className="text-align-center">
                    <b>
                      Note: (*) user IP is not available
                      {/* your browser location permission is disallowed,
                      and hence your location may not be accurate as it is your
                      internet IP location */}
                    </b>
                  </p>
                ) : null}
                <br />
                <br />
                <div id="idSessions">
                  {isLoading ? (
                    <img src={Loader2} alt="" />
                  ) : isReport &&
                    ReportData &&
                    ReportData.length > 0 &&
                    ReportData[0].username !== undefined ? (
                    ReportData.map((m) => (
                      <Collapsible
                        key={m._id}
                        lazyRender
                        transitionTime={800}
                        easing={"cubic-bezier(0.175, 0.885, 0.32, 2.275)"}
                        overflowWhenOpen="visible"
                        // trigger={
                        //   m.address === undefined
                        //     ? <div style={{display: "flex", flexDirection: "column"}}>
                        //       <h1>`${m.username}`</h1>
                        //       <h2>`${(m.fromApi ? "*" : "")},`</h2>
                        //       <h3>`${gettime(m.logintime)}</h3>
                        //     </div>
                        //     : `${m.username} ${(m.fromApi ? "*" : "")} ${gettime(m.logintime)} ${m.address}  IP : ${m.ipaddress} `
                        // }
                        trigger={<div style={{display: "flex", flexDirection: "column",justifyContent:"flex-start",alignItems:"flex-start",fontSize:"0.8rem"}}>
                        <p style={{fontSize:"0.8rem",lineHeight:1,margin:"5px 0"}}>{m.username} {gettime(m.logintime)}</p>
                        <p style={{fontSize:"0.8rem",lineHeight:1,margin:"5px 0"}}><span style={{color: "#2c83bb"}}>From Browser</span>: 
                          {m.address ? m.address : "* No data found."}
                        </p>
                        <p style={{fontSize:"0.8rem",lineHeight:1,margin:"5px 0"}}><span style={{color: "#5fcd6d"}}>From Remote IP</span>:  
                          {m.ipdata && m.ipdata.data && m.ipdata.data.address ? m.ipdata.data.address : "* No data available"}
                          {m.ipdata && m.ipdata.data && m.ipdata.data.ip_address ? `, IP: ${m.ipdata.data.ip_address}` : ""}
                        </p>
                        <p style={{fontSize:"0.8rem",lineHeight:1,margin:"5px 0"}}><span style={{color: "#293539"}}>From Client IP</span>:  
                          {m.webrtc && m.webrtc.address ? m.webrtc.address : "* No data available"}
                          {m.webrtc && m.webrtc.ipdata && m.webrtc.ipdata.ip_address ? `, IP: ${m.webrtc.ipdata.ip_address}` : ""}
                        </p>
                      </div>}
                        // triggerStyle={{color: "green", display: "flex", flexDirection: "column"}}
                      >
                        {m.latitude || (m.ipdata && m.ipdata.data) || (m.webrtc && m.webrtc.ipdata)? (
                          <MapContainer
                            style={{ height: "550px", width: "100%" }}
                            center={getLatLong(m)}
                            zoom={13}
                            scrollWheelZoom={false}
                          >
                            <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {
                              m.latitude && m.longitude ?
                                <Marker position={[m.latitude, m.longitude]} icon={blueIcon}>
                                  <Popup>
                                    {/* {m.username} was here */}
                                    {m.username} Browser Location: {m.address}
                                    <br />
                                    {gettime(m.logintime)}
                                  </Popup>
                                </Marker>
                                : ""
                            }
                            
                            {
                              m.ipdata && m.ipdata.data && m.ipdata.data.latitude && m.ipdata.data.longitude ?
                                <Marker position={[m.ipdata.data.latitude, m.ipdata.data.longitude]} icon={greenIcon}>
                                  <Popup>
                                    {m.username} Remote IP location: {m.ipdata && m.ipdata.data.address ? m.ipdata.data.address : ""}
                                    <br />
                                    {gettime(m.logintime)}
                                  </Popup>
                                </Marker>
                                : ""
                            }
                            
                            {
                              m.webrtc && m.webrtc.ipdata && m.webrtc.ipdata.latitude && m.webrtc.ipdata.longitude ?
                                <Marker position={[m.webrtc.ipdata.latitude, m.webrtc.ipdata.longitude]} icon={blackIcon}>
                                  <Popup>
                                    {m.username} Client IP location: {m.webrtc && m.webrtc.address ? m.webrtc.address : ""}
                                    <br />
                                    {gettime(m.logintime)}
                                  </Popup>
                                </Marker>
                                : ""
                            }
                            
                            
                          </MapContainer>
                        ) : (
                          <p>Latitute-Longitude Not Found</p>
                        )}
                      </Collapsible>
                    ))
                  ) : (
                    <div className="no-chart-div">No Maps To Display</div>
                  )}
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </>
    </>
  );
};

const Elem = () => {
  return (<>
  <div style={{display: "flex", flexDirection: "column"}}>
    <h1>
      lorem
    </h1>
    <h2>epsum</h2>
  </div>
  </>)
}
export default UserGeoLocationReport;
