import {
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAILURE,
  SET_EXPORT_DATA,
  CLEAR_REPORT_DATA,
  SET_EXPORT_LOADING_FALSE,
  SET_EXPORT_LOADING_TRUE,
  GET_LOCATION_DATA_SUCCESS,
  CLEAR_LOCATION_DATA,
} from "../actions/actionTypes";

const initialState = {
  isExportLoading: false,
  exportData: [],
  isReport: false,
  ReportData: [],
  Coordinates: [],
  locationReportData:[],
  error: "",
};
interface IAction {
  type: string;
  payload: any;
}

const editUserReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_REPORT_DATA_SUCCESS: {
      return {
        ...state,
        isReport: true,
        ReportData: action.payload,
      };
    }
    case GET_REPORT_DATA_FAILURE: {
      return {
        ...state,
        isReport: false,
        error: action.payload.error,
      };
    }
    case SET_EXPORT_DATA: {
      return {
        ...state,
        exportData: action.payload,
      };
    }

    case SET_EXPORT_LOADING_TRUE: {
      return {
        ...state,
        isExportLoading: true,
      };
    }

    case SET_EXPORT_LOADING_FALSE: {
      return {
        ...state,
        isExportLoading: false,
      };
    }

    case CLEAR_REPORT_DATA: {
      return initialState;
    }
    case GET_LOCATION_DATA_SUCCESS: {
      return {
        ...state,
        isReport: true,
        locationReportData: action.payload.data,
        Coordinates: action.payload.coordinates,
      };
    }
    case CLEAR_LOCATION_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default editUserReducer;
