import modalReducer from "./modalReducer";
import loginReducer from "./loginReducer";
import userDetailsReducer from "./userDetailsReducer";
import roomsReducer from "./roomsReducer";
// import deviceReducer from "./deviceReducer";
// import chatReducer from "./chatReducer";
import addUserReducer from "./addUserReducer";
import twilioSettingReducer from "./twilioSettingReducer";
import getTwilioDetailReducer from "./getTwilioDetail";
import loaderReducer from "./loaderReducer";
import viewAllUsersReducer from "./viewAllUsersReducer";
import AdminSettingsReducer from "./AdminSettingsReducer";
import getUserDetailReducer from "./getUserDetail";
import getCurrentUsersReducer from "./getCurrentUsersReducer";
import editUserReducer from "./editUserReducer";
import deleteUserReducer from "./deleteUserReducer";
import getReportDataReducer from "./getReportDataReducer";
import getReportNameReducer from "./getReportNameReducer";
import getReportRoomReducer from "./getReportRoomReducer";
import uniqueLogInUsers from "./uniqueLoginUserReducer";
import bussUnitReducer from "./bussUnitReducer";
import backgroundImageReducer from "./backgroundImageReducer";
import feedbackImagesReducer from "./feedbackImagesReducer";
import chatDataReducer from "./chatDataReducer";
import environmentReducer from "./environmentReducer";
import addLogoReducer from "./addLogoReducer";

import viewAllFeedsReducer from "./feedsReducers";
import { topicsReducer } from "./TopicsReducer";
import groupRoomReducer from "./groupRoomReducer";

const reducers = {
  modal: modalReducer,
  login: loginReducer,
  getCurrentUsers: getCurrentUsersReducer,
  addUser: addUserReducer,
  editUser: editUserReducer,
  twilioSetting: twilioSettingReducer,
  getTwilioDetailData: getTwilioDetailReducer,
  userDetails: userDetailsReducer,
  roomsData: roomsReducer,
  getAllUserData: viewAllUsersReducer,
  AdminSettingsReducer: AdminSettingsReducer,
  getUserDetailData: getUserDetailReducer,
  deleteUser: deleteUserReducer,
  getReportData: getReportDataReducer,
  getReportRoom: getReportRoomReducer,
  getReportName: getReportNameReducer,
  loading: loaderReducer,
  uniqueLoginUsers: uniqueLogInUsers,
  bussUnit: bussUnitReducer,
  backgroundImage: backgroundImageReducer,
  feedbackImages: feedbackImagesReducer,
  chatData: chatDataReducer,
  environmentsReducers: environmentReducer,
  addLogoReducer: addLogoReducer,
  topicsReducer: topicsReducer,
  viewAllFeedsReducer: viewAllFeedsReducer,
  groupRoomReducer: groupRoomReducer,
};

export default reducers;
