import request from "./index";
import qs from "querystring";
const config = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
};

export const getDesktopActivity = async (
    starttime: any,
    endtime: any,
    username: string,
    abstractData: string,
    bussUnit: string,
    room:string
) => {
    const requestBody = qs.stringify({
        dateFrom: starttime,
        dateTo: endtime,
        name: username,
        abstractData,
        bussUnit,
        room

    });

    const response = await request.post("/getdesktopactivity", requestBody, config);

    return response.data;
};

export const getWebsitesActivity = async (
    starttime: any,
    endtime: any,
    username: string,
    abstractData: string,
    bussUnit: string,
    room:string
) => {
    const requestBody = qs.stringify({
        dateFrom: starttime,
        dateTo: endtime,
        name: username,
        abstractData,
        bussUnit,
        room

    });

    const response = await request.post("/getwebsitesactivity", requestBody, config);

    return response.data;
};

export const getDesktopApps = async () => {
    const response = await request.get("/getdesktopapps");
    return response.data;
};

export const getSignedUrl =async (url:any) => {
    const requestBody = qs.stringify({
        url: url
    });
    const response = await request.post("/getSignedUrl",requestBody, config);
    return response.data;
}

export const updateApplications = async (
    apps: any
) => {
    const requestBody = JSON.stringify(apps)
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await request.post("/updatedesktopapps", requestBody, config);

    return response.data;
};

export const updateWebsites = async (
    apps: any
) => {
    const requestBody = JSON.stringify(apps)
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await request.post("/updatewebsites", requestBody, config);

    return response.data;
};