import request from "./index";

export const addLogo = async (logoName) => {
    const config_img = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    const data = new FormData();
    data.append("file", logoName);
    const uploadResponse = await request.post("/addlogo", data, config_img);
    return uploadResponse.data;
};