import request from "./index";
// import qs from "querystring";
// const config = {
//     headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//     },
// };

export const getFeedbackImages = async () => {
    const feedbackImagesResponse = await request.get("/getfeedbackimg");
    return feedbackImagesResponse.data;
};