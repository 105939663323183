import Logo2 from "../../assets/images/logo-2.jpg";
import LogoutImage from "../../assets/images/logout.svg";
import Profile from "./../../components/Profile";
import { Dropdown, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetailsAction } from "../../actions/userActions";
import { RootState } from "../../redux";
import "./header.css";
import { logOutAction } from "../../actions/loginActions";
import { useHistory } from "react-router";
import { clearAllReducerData } from "../../actions/clearAction";
import { getLogo } from "../../services/getLogo";
interface IHeaderProps {
  handleActiveAudioDeviceChange?: () => void;
  handleActiveVideoDeviceChange?: () => void;
}

const Header = (props: IHeaderProps) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [logo, setlogo] = useState(Logo2);

  const history = useHistory();
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const enviornments = useSelector(
    (state: RootState) => state.environmentsReducers.environments
  );
  const appVersion = enviornments.REACT_APP_VERSION
    ? enviornments.REACT_APP_VERSION.value
    : process.env.REACT_APP_VERSION;
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUserDetailsAction());
    }
    fetchLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userDetails = useSelector((state: RootState) => state.userDetails);
  const logOut = async () => {
    let value: any = await dispatch(logOutAction());
    if (value.success) {
      history.push("/login");
      dispatch(clearAllReducerData());
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const fetchLogo = async () => {
    let logoResponse = await getLogo();
    if (logoResponse) {
      setlogo(logoResponse.logoUrl);
    }
  };

  return (
    <header id="myHeader">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="left-box">
              <ul>
                <li className="logo">
                  <a href="/">
                    <img src={logo} alt="logo2" />
                  </a>
                </li>
                <li>
                  <h4>Admin Panel</h4>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="right-box">
              <ul>
                <li>
                  <Profile
                    name={userDetails.userName}
                    role={userDetails.role}
                  />
                </li>
                <li>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="menu-dropdown">
                      Menu
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          history.push("/dashboard");
                        }}
                      >
                        Dashboard
                      </Dropdown.Item>
                      {userDetails.role === "Admin" && (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/add_logo");
                            }}
                          >
                            Add / Edit Logo
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/businessunit");
                            }}
                          >
                            Add Business Unit
                          </Dropdown.Item>
                        </>
                      )}
                      {userDetails.role === "Admin" ||
                      userDetails.role === "Director" ? (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/add_room");
                            }}
                          >
                            Add / Edit Room
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/add_user");
                            }}
                          >
                            Add User
                          </Dropdown.Item>

                          {userDetails.role === "Admin" ||
                          userDetails.role === "Director" ? (
                            <Dropdown.Item
                              onClick={() => {
                                history.push("/grouping_room");
                              }}
                            >
                              Add Group Room
                            </Dropdown.Item>
                          ) : null}
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/view_all_users");
                            }}
                          >
                            View / Edit User
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/add_bulk_users");
                            }}
                          >
                            Add Multiple Users
                          </Dropdown.Item>
                          {userDetails.role === "Admin" ? (
                            <Dropdown.Item
                              onClick={() => {
                                history.push("/activity_channel");
                              }}
                            >
                              Activity Channel
                            </Dropdown.Item>
                          ) : (
                            ""
                          )}
                        </>
                      ) : null}
                      <Dropdown.Divider />
                      {userDetails.role === "Admin" && (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/admin_settings");
                            }}
                          >
                            Admin Settings
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/twilio_setting");
                            }}
                          >
                            Twilio Setting
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/webrtcstats");
                            }}
                          >
                            WebRTC Stats
                          </Dropdown.Item>
                        </>
                      )}
                      <Dropdown.Divider />
                      {userDetails.role === "Admin" ||
                      userDetails.role === "Director" ||
                      userDetails.role === "Manager" || userDetails.role === "Supervisor" ? (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/tabular_report");
                            }}
                          >
                            Drilled Down Report
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/privatecall");
                            }}
                          >
                            Private Call Report
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/chat");
                            }}
                          >
                            Chat Report
                          </Dropdown.Item>
                        </>
                      ) : null}
                      {userDetails.role === "Admin" ||
                      userDetails.role === "Director" ||
                      userDetails.role === "Manager" || userDetails.role === "Supervisor"  ? (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/geolocationreport");
                            }}
                          >
                            Geo Location Report
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/desktopactivity");
                            }}
                          >
                            Desktop Activity
                          </Dropdown.Item>
                          {/* <Dropdown.Item
                            onClick={() => {
                              history.push("/websitesactivity");
                            }}
                          >
                            Websites Activity
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/classifyapps");
                            }}
                          >
                            Classify Apps
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/classifywebsites");
                            }}
                          >
                            Classify Websites
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/report");
                            }}
                          >
                            Graphical Report
                          </Dropdown.Item>
                        </>
                      ) : null}
                      {userDetails.role === "Admin" ||
                      userDetails.role === "Director" ||
                      userDetails.role === "Manager"  ? (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/license");
                            }}
                          >
                            License
                          </Dropdown.Item>
                        </>
                      ) : null}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          history.push("/background");
                        }}
                      >
                        Background Image
                      </Dropdown.Item>
                      {userDetails.role === "Admin" ||
                      userDetails.role === "Director" ||
                      userDetails.role === "Manager" || userDetails.role === "Supervisor"  ? (
                        <>
                          <Dropdown.Item
                            onClick={() => {
                              history.push("/feedbackimages");
                            }}
                          >
                            Feedback Images
                          </Dropdown.Item>
                        </>
                      ) : null}
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => setShowModal(true)}>
                        About
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li onClick={(e) => e.preventDefault()}>
                  <img
                    className="logout"
                    src={LogoutImage}
                    onClick={logOut}
                    alt="logout"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal size="sm" show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>Version No: {appVersion}</Modal.Header>
      </Modal>
    </header>
  );
};

export default Header;
