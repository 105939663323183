import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import { login, verifyOTP, logout } from "../services/login";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  OTP_SUCCESS,
  OTP_FAILURE,
  SET_MFA,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
} from "../actions/actionTypes";

export const handleLogin = (username?: string, password?: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (username && password) {
        const responseData = await login(username, password);
        if (responseData.success ) {
          localStorage.setItem('token',responseData?.token);
          dispatch(setSuccess(responseData));
        } else {

          if(responseData.otpverify){
            /// verify OTP 
            dispatch(setMFAEnable());
          }else{
            dispatch(setLoginError("Incorrect Username/password"));
          }
          //dispatch(setLoginError("Incorrect Username/password"));
        }
        return responseData;
      } else {
        const responseData = await login();
        if (responseData.success) {
          dispatch(setSuccess(responseData));
        }else {
          localStorage.removeItem('token');
          dispatch(setLoginError(""))
        }
        return responseData;
      }
    } catch (error: any) {
      if (username && password) {
        if (error.message && error.message.message) {
          dispatch(setLoginError(error.message.message));
        } else {
          dispatch(setLoginError("Incorrect Username/password"));
        }
      } else {
        dispatch(setLoginError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleOTP = (username?: string,OTP?: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));

    //console.log("Username "+username);
    //console.log("OTP "+OTP);

    try {
    //  if (OTP!="") {
        if (username && OTP) {  
      
        const responseData = await verifyOTP(username,OTP);
        if (responseData.success) {
          localStorage.setItem('token',responseData?.token);
          dispatch(setOTPSuccess(responseData));
        } else {
          dispatch(setOTPError("Incorrect OTP, Please try again"));
        }
      
        return responseData;
      } else {
          /*
        const responseData = await login();
        if (responseData.success) {
          dispatch(setSuccess());
          await loginActiveTime();
        } else {
          dispatch(setLoginError(""));
        }
        return responseData;
        */
      }
    } catch (error: any) {
      if (OTP) {
        if (error.message && error.message.message) {
          dispatch(setLoginError(error.message.message));
        } else {
          dispatch(setLoginError("Incorrect OTP, Please try again"));
        }
      } else {
        dispatch(setLoginError(""));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};


const setOTPError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: OTP_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

const setSuccess = (loginData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: loginData,
    });
  };
};

const setMFAEnable = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_MFA,
      payload: {
        mfa: true,
      },
    });
  };
};

const setOTPSuccess = (loginData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: OTP_SUCCESS,
      payload: loginData,
    });
  };
};


const setLoginError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: LOGIN_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

const setLogoutError = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: LOGOUT_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

const setLogout = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    
  };
};

export const logOutAction = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const logoutData = await logout();
      if (logoutData) {
        dispatch(setLogout());
      } else {
        dispatch(setLogoutError("Could not logout. Please try again"));
      }
      return logoutData;
    } catch (error: any) {
      setLogoutError(
        error?.message || "Could Could not logout. Please try again"
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
};
