import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getChatData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: Array<string>
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: rname,
    roomName: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const response = await request.post("/getChat", requestBody, config);
  console.log({response})
  return response.data;
};
