import { useEffect, useState } from "react";
import Loader2 from "../../assets/images/loader2.gif";
import "./Chat.css";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { RootState } from "../../redux";
import React from "react";
import { DateTimePicker } from "react-rainbow-components";
import Pagination from "react-js-pagination";
import {
  handleGetChatRoomByUser,
  handleGetReportRoom,
} from "../../actions/getReportRoom";


import ExportModal from "./ExportModal";
import { toast } from "react-toastify";
import {
  clearExportChatDataActions,
  getExportChatDataActions,
} from "../../actions/chatDataAction";
import { reportAPI } from "../../utils/api";
import { FormControl, Autocomplete, TextField } from "@mui/material";

const Chat = (props: any) => {
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [exportModal, setExportModal] = useState(false);
  const [pageno, setPageno] = useState(1);
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [nameArray, setNameArray] = useState([]);
  const [roomArray, setRoomArray] = useState([]);
  // const userRole = useSelector((state: RootState) => state.userDetails.role);

  const exportData = useSelector(
    (state: RootState) => state.chatData.exportChatData
  );
  const isLoading = useSelector(
    (state: RootState) => state.chatData.isExportChatLoading
  );
  const dispatch = useDispatch();

  const formatDate = (date) => {
    var d = new Date(date);
    return d.toUTCString();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getuser = () => {
    reportAPI.gettAll().then((res) => {
      let labeledUsers:any = [];
      // eslint-disable-next-line array-callback-return
      res.data.map((user: any, index) => {
        if (user && user.username) {
          // @ts-ignore
          labeledUsers.push({ label: user.username, value: user.username });
        }
      });
      labeledUsers.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        return labelA.localeCompare(labelB);
      });
      labeledUsers.unshift({ label: "All Users", value: "All" })
      //@ts-ignore
      setNameArray(labeledUsers);
    });
  };

  useEffect(() => {
    console.log({ nameArray })
  }, [nameArray])

  const getrooms = () => {
    reportAPI.gettRooms().then((res) => {
      let labeledUsers = [{ label: "All Rooms", value: "All" }];
      // eslint-disable-next-line array-callback-return
      res.data.map((roomValue: any, index) => {
        if (roomValue) {
          // @ts-ignore
          labeledUsers.push({ label: roomValue.roomname, value: roomValue.roomname });
        }
      });
      //@ts-ignore
      setRoomArray(labeledUsers);
    });
  };

  function EnableF() {
    getuser();
    getrooms();
  }

  const handleDatechange = (e: any, flag) => {
    if (flag === "start") {
      setStartdate(new Date(e));
    } else {
      setEnddate(new Date(e));
    }
  };

  const handleNameChange = async (s: any) => {
    setName(s.value);

    if (s.value === "All") {
      dispatch(handleGetReportRoom());
      setName("");
      return;
    } else {
      dispatch(handleGetChatRoomByUser(s.value, setRoomArray));
    }
    return;
  };

  const handleRoomChange = (r: any) => {
    setRoom(r.value);

    if (r.value === "All") {
      setRoom("");
      return;
    }
    return;
  };

  const handlePageChange = (page_number) => {
    setPageno(page_number);
  };

  useEffect(() => {
    EnableF();
    return () => {
      dispatch(clearExportChatDataActions());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateData = async () => {
    let rooms = [];
    //@ts-ignore
    // eslint-disable-next-line array-callback-return
    roomArray.map((r: any, index) => {
      if (r.value !== "All") {
        //@ts-ignore
        rooms.push(r.value)
      }
    });
    let roomsForchat = name !== "" && room === "" ? rooms : [room];
    await dispatch(
      getExportChatDataActions(
        formatDate(startdate),
        formatDate(enddate),
        name,
        roomsForchat
      )
    );
    handlePageChange(1);
  };

  const doSearch = () => {
    generateData();
  };

  const openModal = () => {
    if (startdate.toString() === enddate.toString()) {
      toast.error("Please ensure start time is less than end time");
      return;
    }
    setExportModal(exportModal ? false : true);
  };

  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">Chat Reporting</span>

        <div className="datepicker-container">
          <div className="text-center mt-4 export_button">
            <Button variant="info" onClick={openModal}>
              Export Data
            </Button>
          </div>
          {/* <CSVLink data={dataForExport}>Download me</CSVLink> */}
          <DateTimePicker
            value={startdate}
            label="Start"
            onChange={(e: any) => handleDatechange(e, "start")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          // format="yyyy-MM-dd HH:mm:ss"
          />
          <DateTimePicker
            value={enddate}
            label="End"
            onChange={(e: any) => handleDatechange(e, "end")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <br />
          <div className="text-center mt-4">
            <Button id="search" variant="info" onClick={() => doSearch()}>
              Search
            </Button>
          </div>
        </div>
      </nav>
      <div className="container-fluid hw" id="main">
        <div className="row">
          <div className="report-container">
            <div className="header">
              <h1 className="session-chart">Session charts</h1>
              <div className="disp-flex-left"></div>
            </div>
            {/* {display && isReport && ( */}
            <div className="filters-container" style={{ width: "54%" }}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  // name="username"
                  value={name}
                  options={nameArray}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="All User" />
                  )}
                  onChange={(event, value) => handleNameChange(value)}
                />
              </FormControl>

              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  // name="username"
                  value={room}
                  options={roomArray}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="All Rooms" />
                  )}
                  onChange={(event, value) => handleRoomChange(value)}
                />
              </FormControl>
              {/* <Dropdown className="select-item">
                <Dropdown.Toggle
                  variant="success"
                  id="menu-dropdown-name"
                  style={{ width: "90%" }}
                >
                  {name ? name : "All Users"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: "400px",
                    width: "90%",
                    overflowY: "scroll",
                  }}
                >
                {userRole !== "Director" ?
                <Dropdown.Item
                onClick={() => {
                  handleNameChange("All");
                }}
              >
                All
              </Dropdown.Item> : ""}
                  {nameArray?.map((m) => (
                    <Dropdown.Item
                      onClick={() => {
                        handleNameChange(m);
                      }}
                    >
                      {m}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="select-item">
                <Dropdown.Toggle
                  variant="success"
                  id="menu-dropdown-room"
                  style={{ width: "90%" }}
                >
                  {room ? room : "All Rooms"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                    width: "90%",
                  }}
                >
                {userRole !== "Director" ?
                <Dropdown.Item
                onClick={() => {
                  handleNameChange("All");
                }}
              >
                All
              </Dropdown.Item> : ""}
                  {roomArray?.map((m) => (
                    <Dropdown.Item
                      onClick={() => {
                        handleRoomChange(m);
                      }}
                    >
                      {m}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}
            </div>

            <br />
          </div>
        </div>

        <span className="line"></span>
        {exportData.length > 0 ? (
          <>
            <div className="row all_users">
              <div className="report-container">
                <div className="single_user_name">
                  <h4>{exportData[0][0]}</h4>
                </div>
                <div className="single_user_name">
                  <h4>{exportData[0][1]}</h4>
                </div>
                {/* <div className="single_user_name">
              <h5>Time</h5>
            </div> */}
                <div className="single_user_name">
                  <h4>{exportData[0][2]}</h4>
                </div>
                <div className="single_user_name">
                  <h4>{exportData[0][3]}</h4>
                </div>
                <div className="single_user_name">
                  <h4>{exportData[0][4]}</h4>
                </div>
                <div className="single_user_name">
                  <h4>{exportData[0][5]}</h4>
                </div>
                <div className="single_user_name">
                  <h4>{exportData[0][6]}</h4>
                </div>
              </div>
            </div>

            {exportData
              .slice((pageno - 1) * 20 + 1, pageno * 20 + 1)
              .map((value: any, index) => (
                <React.Fragment key={index}>
                  <div className="row all_users">
                    <div className="report-container">
                      <div className="single_user_name">
                        <h5>{value[0]}</h5>
                      </div>
                      <div className="single_user_name">
                        <h5>{value[1]}</h5>
                      </div>
                      {/* <div className="single_user_name"><h5>{value.username}</h5></div> */}
                      <div className="single_user_name">
                        <h5>{value[2]}</h5>
                      </div>
                      <div className="single_user_name">
                        <h5>{value[3]}</h5>
                      </div>
                      <div className="single_user_name">
                        <h5>{value[4]}</h5>
                      </div>
                      <div className="single_user_name">
                        <h5>{value[5]}</h5>
                      </div>
                      <div className="single_user_name">
                        <h5>{value[6]}</h5>
                      </div>
                    </div>
                  </div>

                  <span className="line"></span>
                </React.Fragment>
              ))}
          </>
        ) : (
          <div id="idSessions">
            {isLoading ? (
              <img src={Loader2} alt="" />
            ) : (
              <div className="no-chart-div">No Chats To Display</div>
            )}
          </div>
        )}
      </div>
      {exportData.length > 0 ? (
        <Pagination
          activePage={pageno}
          itemsCountPerPage={20}
          totalItemsCount={exportData.length - 1}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      ) : (
        <div></div>
      )}

      <ExportModal
        show={exportModal}
        handleShow={setExportModal}
        generateData={generateData}
      />
    </>
  );
};

export default Chat;
