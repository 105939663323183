import request from "./index";
import qs from "querystring";

// interface sendData {
//   username: string;
//   email: string;
//   password?: string;
//   role: string;
//   first_name: string;
//   last_name: string;
//   mfa: boolean;
//   appUpdate: boolean;
//   faceVerification: boolean;
//   enableDesktopApp: boolean;
//   loginlock: boolean;
//   // complex_password: boolean,
//   bussunit?: string;
//   team?: string;
//   mobile?: string;
//   grouprooms?: Array<string>;
// }

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const editUser = async (
  username: string,
  email: string,
  password: string,
  role: string,
  firstName: string,
  lastName: string,
  mfa: boolean,
  loginlock: boolean,
  complexPassword: boolean,
  appUpdate: boolean,
  faceVerification: boolean,
  enableDesktopApp: boolean,
  team?: string,
  bussUnit?: string,
  mobile?: string,
  grouprooms?: Array<string>
) => {
  let data: any = {
    username,
    email,
    role,
    first_name: firstName,
    last_name: lastName,
    mfa,
    loginlock,
    appUpdate,
    "faceVerification.isFaceVerificationEnabled": faceVerification,
    enableDesktopApp,
    // complex_password: complexPassword
  };

  if (
    bussUnit !== "" &&
    ((grouprooms && grouprooms.length > 0) ||
      role === "Director" ||
      role === "Manager")
  ) {
    data = { ...data, bussunit: bussUnit };
  } else if ((role === "Supervisor" || role === "Agent") && bussUnit && team) {
    data = { ...data, bussunit: bussUnit, team: team };
  }

  if (grouprooms && grouprooms.length > 0 && role !== "Admin") {
    data = { ...data, grouprooms };
  }

  if (mfa) {
    data = { ...data, mobile: mobile };
  }

  if (password !== "") {
    data = { ...data, password: password };
  }

  const requestBody = qs.stringify({ ...data });
  const response = await request.post("/edituser", requestBody, config);

  return response.data;
};
