import { useEffect, useState } from "react";
import "./ViewAllUsers.css";
import Header from "./../../components/Header";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import ModalComponent from "./../../components/Modal";
import { OPEN_MODAL, SET_USERS_LIST_SUCCESS } from "../../actions/actionTypes";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  handleViewAllUsers,
  listAllUsersActon,
} from "../../actions/viewAllUsers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import { handleDeleteUser } from "../../actions/deleteUser";
import { RootState } from "../../redux";
import { useHistory } from "react-router-dom";
import React from "react";
import Input from "../../components/Input";
import moment from "moment";
import { timeZoneFormat } from "../../utils/dateformat";
import { CSVLink } from "react-csv";
import { getAllGroupRoom } from "../../actions/groupRoomAction";
import { getRoomsAction } from "../../actions/roomActions";
import { getBussUnitsAction } from "../../actions/bussUnitActions";

createTheme("solarized", {
  text: {
    primary: "#1e3869",
    secondary: "#1e3869",
  },
  background: {
    default: "#ffffff",
  },
  context: {
    background: "#cb4b16",
    text: "#000000",
  },
  divider: {
    default: "#ada8a8",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});
const ViewAllUsers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");

  // filter
  const [bussUnitSelect, setBussUnitSelect] = useState("");
  const [groupRoomSelect, setGroupRoomSelect] = useState("");
  const [teamSelect, setTeamSelect] = useState("");

  // Filter Data

  const allBussUnit = useSelector(
    (state: RootState) => state.bussUnit.bussUnits
  ).map((value) => value.bussunit);
  const allTeam = useSelector((state: RootState) => state.roomsData.rooms).map(
    (value) => value.roomname
  );
  let allGroupRoom = useSelector(
    (state: RootState) => state.groupRoomReducer.groupRoomList
  ).map((value) => value.groupname);

  // Filter Data end
  // end filter

  // const allUsers = useSelector(
  //   (state: RootState) => state.getAllUserData.userlist
  // );
  const exportedList = useSelector(
    (state: RootState) => state.getAllUserData.exportedUsers
  );
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  const deleteIcon = (username: any) => (
    <FontAwesomeIcon icon={faTrash} onClick={() => openDeleteModal(username)} />
  );
  const editIcon = (username: any) => (
    <FontAwesomeIcon
      icon={faEdit}
      onClick={() => {
        history.push("/edit_users/" + username);
      }}
    />
  );

  const { format } = timeZoneFormat();
  const current_username = useSelector(
    (state: RootState) => state.modal.current_username
  );

  const columns = [
    {
      name: "No",
      sortable: true,
      selector: "No",
      center: true,
    },
    {
      name: "Username",
      sortable: true,
      selector: "Username",
      center: true,
    },
    {
      name: "First Name",
      sortable: true,
      selector: "FirstName",
      center: true,
    },
    {
      name: "Last Name",
      sortable: true,
      selector: "LastName",
      center: true,
    },
    {
      name: "Role",
      sortable: true,
      selector: "Role",
      center: true,
    },
    {
      name: "Team",
      sortable: true,
      selector: "Team",
      center: true,
    },
    {
      name: "Business Unit",
      sortable: true,
      selector: "BussUnit",
      center: true,
    },
    {
      name: "Group Rooms",
      sortable: true,
      selector: "Grouprooms",
      center: true,
    },
    {
      name: "MFA",
      sortable: true,
      selector: "mfa",
      center: true,
    },
    {
      name: "Locked",
      sortable: true,
      selector: "loginlock",
      center: true,
    },
    {
      name: "Mobile",
      sortable: true,
      selector: "mobile",
      center: true,
    },
    {
      name: "Creation Date",
      sortable: true,
      selector: "Creation_Date",
      center: true,
    },
    {
      name: "Delete",
      selector: "Delete",
      center: true,
    },
    {
      name: "Edit",
      selector: "Edit",
      center: true,
    },
  ];

  //  user.mfa?"Yes":"No",

  const populateUsers = (users) => {
    if (users.length > 0) {
      let allUserDAta = users.map((user: any, index: any) => {
        const bussunit = user.bussunit ? user.bussunit : "";
        const team = user.team ? user.team : "";
        const grouprooms = user.grouprooms ? user.grouprooms.toString() : [];

        return getDataObject(
          index,
          user.username,
          user.first_name,
          user.last_name,
          user.joined,
          user.role,
          team,
          bussunit,
          user.mfa === "true" ? "Yes" : "No",
          user.mobile,
          user.loginlock ? "Yes" : "No",
          grouprooms
        );
      });
      setData(allUserDAta);
      const ifSelected = bussUnitSelect
        ? { key: "BussUnit", data: bussUnitSelect }
        : groupRoomSelect
        ? { key: "Grouprooms", data: groupRoomSelect }
        : teamSelect
        ? { key: "Team", data: groupRoomSelect }
        : "";

      if (ifSelected) {
        setFilterData(
          allUserDAta.filter((value) =>
            ifSelected.key === "Grouprooms"
              ? value.Grouprooms.includes(ifSelected.data)
              : value[ifSelected.key] === ifSelected.data
          )
        );
      } else if (search) {
        setFilterData(
          allUserDAta.filter((user) =>
            user.Username.includes(search.toUpperCase())
          )
        );
      } else {
        setFilterData(allUserDAta);
      }
    }
  };
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);

    dispatch({
      type: SET_USERS_LIST_SUCCESS,
      payload: {
        users: [],
      },
    });
  };
  const handleShow = () => setShow(true);

  const [data, setData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  let userlist = useSelector(
    (state: RootState) => state.getAllUserData.userlist
  );
  useEffect(() => {
    dispatch(handleViewAllUsers());
    dispatch(getAllGroupRoom());
    dispatch(getRoomsAction());
    dispatch(getBussUnitsAction());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    populateUsers(userlist);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userlist]);

  // const getWholeDate = (dateObj: Date) => {
  //   var month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  //   var date = ("0" + dateObj.getDate()).slice(-2);
  //   var year = dateObj.getFullYear();
  //   return year + "/" + month + "/" + date;
  // };

  const getDataObject = (
    index: any,
    username: any,
    firstname: any,
    lastname: any,
    creation_date: any,
    role: any,
    team: any,
    bussunit: string,
    mfa: any,
    mobile: string,
    loginlock: any,
    grouprooms: Array<string>
  ) => {
    console.log(new Date(creation_date).toISOString(), { creation_date });

    const abc = {
      No: index + 1,
      Username: username,
      FirstName: firstname,
      LastName: lastname,
      Creation_Date: moment(creation_date).format(format).split(" ")[0],
      Role: role,
      Team: team,
      Delete: deleteIcon(username),
      Edit: editIcon(username),
      BussUnit: bussunit,
      mfa: mfa,
      mobile: mobile,
      loginlock: loginlock,
      Grouprooms: grouprooms,
    };

    //console.log(abc);

    return abc;
  };

  const openDeleteModal = (username: any) => {
    dispatch({
      type: OPEN_MODAL,
      current_username: username,
    });
  };
  const handleDelete = (e: any, username: any) => {
    e.preventDefault();
    dispatch(handleDeleteUser(username));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setBussUnitSelect("");
    setGroupRoomSelect("");
    setTeamSelect("");
    if (e.target.value) {
      const value = e.target.value;
      setFilterData(
        data.filter((user) => user.Username.includes(value.toUpperCase()))
      );
    } else {
      setOnEmptySelect();
    }
  };

  const generteAllUsers = (e) => {
    dispatch(listAllUsersActon());
  };

  const handleBussUnitSearch = (e: any) => {
    setBussUnitSelect(e.target.value);
    setGroupRoomSelect("");
    setTeamSelect("");
    if (e.target.value === "") {
      setOnEmptySelect();
      return;
    }
    setFilterData(data.filter((value) => value.BussUnit === e.target.value));
  };
  const handleGroupRoomSearch = (e: any) => {
    setGroupRoomSelect(e.target.value);
    setBussUnitSelect("");
    setTeamSelect("");
    if (e.target.value === "") {
      setOnEmptySelect();
      return;
    }
    setFilterData(
      data.filter((value) => value.Grouprooms.includes(e.target.value))
    );
  };
  const handleTeamSearch = (e: any) => {
    setTeamSelect(e.target.value);
    setGroupRoomSelect("");
    setBussUnitSelect("");
    if (e.target.value === "") {
      setOnEmptySelect();
      return;
    }

    setFilterData(data.filter((value) => value.Team === e.target.value));
  };

  const setOnEmptySelect = () => {
    setFilterData(data);
  };

  // const isUserDeleted = useSelector((state: RootState) => state.deleteUser.isUserDeleted);
  // useEffect(() => {
  //   if (isUserDeleted) {
  //     history.push("/view_all_users");
  //   }
  // }, [isUserDeleted, history]);
  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">ALL USERS</span>

        <div className="search-user_view">
          <select
            id="role"
            onChange={(e) => handleBussUnitSearch(e)}
            value={bussUnitSelect}
          >
            <option value="">Please Select Business Unit</option>
            {allBussUnit.map((bussUnit: any, index: any) => (
              <option key={index} value={bussUnit}>
                {bussUnit}
              </option>
            ))}
          </select>

          <br />
        </div>
        <div className="search-user_view">
          <select
            id="role"
            onChange={(e) => handleTeamSearch(e)}
            value={teamSelect}
          >
            <option value="">Please Select Team</option>
            {allTeam.map((team: any, index: any) => (
              <option key={index} value={team}>
                {team}
              </option>
            ))}
          </select>

          <br />
        </div>
        <div className="search-user_view">
          <select
            id="role"
            onChange={(e) => handleGroupRoomSearch(e)}
            value={groupRoomSelect}
          >
            <option value="">Please Select Group room</option>
            {allGroupRoom.map((groupRoom: any, index: any) => (
              <option key={index} value={groupRoom}>
                {groupRoom}
              </option>
            ))}
          </select>

          <br />
        </div>
        <div className="search-user_view">
          <Button
            variant="info"
            className="export_btn_users"
            onClick={handleShow}
          >
            Export Data
          </Button>
          <Input
            type="text"
            name="username"
            placeholder="search username"
            value={search}
            onChange={(e) => handleSearch(e)}
          />
          <br />
        </div>
      </nav>
      <DataTable
        title="ALL USERS"
        columns={columns}
        theme="solarized"
        noHeader
        data={filterData}
        pagination
        highlightOnHover
      />
      <ModalComponent>
        <Modal.Header closeButton>
          <Modal.Title>Delete User {current_username}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <h6>Do you want to continue ?</h6>

            <button
              className="link mt-3"
              onClick={(e) => handleDelete(e, current_username)}
            >
              YES
            </button>
            <button className="link mt-3 margin-left-10">NO</button>
          </form>
        </Modal.Body>
      </ModalComponent>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              textAlign: "center",
              fontFamily: "sans-serif",
              fontSize: "1rem",
            }}
          >
            Download All Users
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {exportedList && exportedList.length > 0 ? (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="info"
                className="gen-button"
                onClick={handleClose}
              >
                <CSVLink className="link" data={exportedList}>
                  Download
                </CSVLink>
              </Button>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Button
                className="gen-button"
                variant="info"
                onClick={generteAllUsers}
              >
                {isLoading && (
                  <Spinner size="sm" animation="border" variant="light" />
                )}{" "}
                Generate Users List
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewAllUsers;
