import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_REPORT_ROOM_FAILURE,
  GET_REPORT_ROOM_SUCCESS,
  GET_HOURLY_ROOM_SUCCESS,
  GET_HOURLY_ROOM_FAILURE,
} from "./actionTypes";
import {
  getChatRoomsByUser,
  getHourlyRoom,
  getReportRoom,
} from "../services/getReportRoom";

export const handleGetReportRoom = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await getReportRoom();

      if (responseData) {
        dispatch(setSuccess(responseData, "reportRoom"));
      } else {
        dispatch(setGetReportRoomError("Incorrect Fields", "reportRoom"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setGetReportRoomError(error.message.message, "reportRoom"));
      } else {
        dispatch(setGetReportRoomError("Incorrect Fields", "reportRoom"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleGetChatRoomByUser = (username: string, setRoomArray:any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await getChatRoomsByUser(username);

      if (responseData.success) {
        let labeledUsers = [{label: "All Rooms", value: "All"}];
        // eslint-disable-next-line array-callback-return
        responseData.rooms.map((roomValue: any, index) => {
        if (roomValue) {
          // @ts-ignore
          labeledUsers.push({label: roomValue, value: roomValue});
        }
      });
      //@ts-ignore
      setRoomArray(labeledUsers);
        dispatch(setSuccess(responseData.rooms, "reportRoom"));
      } else {
        dispatch(setGetReportRoomError("Incorrect Fields", "reportRoom"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setGetReportRoomError(error.message.message, "reportRoom"));
      } else {
        dispatch(setGetReportRoomError("Incorrect Fields", "reportRoom"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const handleGetHourlyRoom = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await getHourlyRoom();
      if (responseData) {
        dispatch(setSuccess(responseData, "hourlyRoom"));
      } else {
        dispatch(setGetReportRoomError("Incorrect Fields", "hourlyRoom"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(setGetReportRoomError(error.message.message, "hourlyRoom"));
      } else {
        dispatch(setGetReportRoomError("Incorrect Fields", "hourlyRoom"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const setSuccess = (responsedata: any, val: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type:
        val === "hourlyRoom"
          ? GET_HOURLY_ROOM_SUCCESS
          : GET_REPORT_ROOM_SUCCESS,
      payload: responsedata,
    });
  };
};

const setGetReportRoomError = (errorMessage: string, val: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type:
        val === "hourlyRoom"
          ? GET_HOURLY_ROOM_FAILURE
          : GET_REPORT_ROOM_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};
