import {
  GET_ALL_BACKGROUND_iMAGE,
  CLEAR_BACKGROUND_IMAGE_DATA,
  SET_DELETE_BACKGROUND_iMAGE_NAME,
  SET_BACKGROUND_IMAGE_LOADER,
} from "../actions/actionTypes";

interface IAction {
  type: string;
  payload: any;
}

const initialState = {
  allBackgroundImage: [],
  deleteImageFileName: "",
  loader: false,
};

const backgroundImageReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_ALL_BACKGROUND_iMAGE: {
      return {
        ...state,
        allBackgroundImage: action.payload,
      };
    }
    case SET_DELETE_BACKGROUND_iMAGE_NAME: {
      return {
        ...state,
        deleteImageFileName: action.payload,
      };
    }
    case SET_BACKGROUND_IMAGE_LOADER: {
      return {
        ...state,
        loader: !state.loader,
      };
    }

    case CLEAR_BACKGROUND_IMAGE_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default backgroundImageReducer;
