import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  OTP_FAILURE,
  OTP_SUCCESS,
  SET_MFA,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CLEAR_LOGIN_DATA,
} from "../actions/actionTypes";

const initialState = {
  isLoggedIn: false,
  isOTPtest:false,
  isMFAEnable:false,
  error: "",
  isLoading:true,
  token: localStorage.token ? localStorage.token : "",
};
interface IAction {
  type: string;
  payload: any;
}

const loginReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        isOTPtest: false,
        isLoading:false,
        token: action.payload.token
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload.error,
        isLoading:false
      };
    }
    case OTP_SUCCESS: {
      return {
        ...state,
        isOTPtest: true,
        isLoggedIn: true,
        isLoading: false,
        token: action.payload.token
      };
    }
    case OTP_FAILURE: {
      return {
        ...state,
        isOTPtest: false,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case SET_MFA: {
      //console.log('mfa Cast => Login Re '+action.payload.mfa);
      return {
        ...state,
        isMFAEnable:action.payload.mfa,
        //isMFAEnable: true,  
        //Loginusername:"",    
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        isOTPtest: false,
        isLoading:false
      };
    }
    case LOGOUT_FAILURE: {
      return {
        ...state,
        isLoggedIn: true,
        isLoading:false
      };
    }
    case CLEAR_LOGIN_DATA:{
      return initialState
    }
    default: {
      return state;
    }
  }
};

export default loginReducer;
