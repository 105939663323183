/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import "./FeedbackImages.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import Header from "./../../components/Header";

import { getFeedbackImagesAction } from "../../actions/feedbackImagesActions";
import {
  Modal,
} from "react-bootstrap";


const FeedbackImages = () => {
  const dispatch = useDispatch();
  const imagesData = useSelector(
    (state: RootState) => state.feedbackImages.images
  );
  const [images, setImages] = useState([]);
  const [modelOpen, setModel] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const handleModalClose = () => {
    setModel(false);
  };

  const handleModalOpen = (url: any) => {
    setImageUrl(url);
    setModel(true);
  };

  useEffect(() => {
    dispatch(getFeedbackImagesAction());
  }, [dispatch]);

  useEffect(() => {
    if (imagesData && imagesData.length > 0) {
      setImages(imagesData);
    }
  }, [imagesData]);
  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">Feedback Images</span>
      </nav>
      <div className="box images-container">
        {images.map((image: any, index: number) => {
          return image?.files?.spacesUrl ? (
            <div className="card-feed feedback-card">
              <img
                src={image.files.spacesUrl}
                alt="Avatar"
                onClick={(e) => handleModalOpen(image.files.spacesUrl)}
              />
              <div className="container">
                <h6>
                  <b>Roomname : {image.roomname}</b>
                </h6>
                <p>
                  Agent : {image.agent} &nbsp; Parent : {image.parent}
                  &nbsp; &nbsp; &nbsp;
                  <a href={image.files.spacesUrl} target="_blank">
                    download
                  </a>
                </p>
                <p className="comment" title={image.comment}>
                  comment: {image.comment}
                </p>
              </div>
            </div>
          ) : (
            ""
          );
        })}

        <Modal show={modelOpen} onHide={handleModalClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="roomname-container">
                <a href={imageUrl} target="_blank" title="open in new tab">
                  <img src={imageUrl} alt="img" />
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default FeedbackImages;
