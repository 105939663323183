import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getReportData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string,
  pagenumber: number
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: rname,
    roomName: roomname,
    page_no: pagenumber,
  });

  const response = await request.post("/reporting", requestBody, config);

  return response.data;
};

export const getUniqueLoginUsers = async (
  starttime: string,
  endtime: string
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
  });

  const response = await request.post("/uniqueLogInUsers", requestBody, config);

  return response.data;
};

export const getUsersByRoom = async (
  starttime: string,
  endtime: string,
  room: Array<string>
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    room: room,
  });

  const response = await request.post("/reportsroom", requestBody, config);

  return response.data;
};

export const getExportSessionData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: rname,
    roomName: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const response = await request.post(
    "/exporting_session_data",
    requestBody,
    config
  );

  return response.data;
};

export const getExportExpressionData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: rname,
    roomName: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const response = await request.post(
    "/exporting_expression_data",
    requestBody,
    config
  );

  return response.data;
};

export const getExportSummaryData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: rname,
    roomName: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const response = await request.post(
    "/exporting_summary_data",
    requestBody,
    config
  );

  return response.data;
};

export const getExportNotLoggedData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: rname,
    roomName: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const response = await request.post(
    "/not_logged_person",
    requestBody,
    config
  );

  return response.data;
};

export const getExportViolationData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: rname,
    roomName: roomname,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const response = await request.post(
    "/exporting_violation_data",
    requestBody,
    config
  );

  return response.data;
};

export const getUserLocationData = async (
  starttime: string,
  endtime: string,
  rname: string,
  roomname: string,
  pagenumber: number
) => {
  const requestBody = qs.stringify({
    starttime: starttime,
    endtime: endtime,
    name: rname,
    roomName: roomname,
    page_no: pagenumber,
  });

  const response = await request.post("/userlocation", requestBody, config);
  
  return response.data ;
};

