import { Dispatch } from "react";
import { setLoading } from "./loaderActions";
import {
  GET_TOPICS_SUCCESS,
  GET_TOPICS_FAILURE,
  CREATE_TOPIC_SUCCESS,
  CREATE_TOPIC_FAILURE,
  EDIT_TOPIC_SUCCESS,
  EDIT_TOPIC_FAILURE,
  DELETE_TOPIC_SUCCESS,
  DELETE_TOPIC_FAILURE,
} from "./actionTypes";
import { getTopics, addTopic, editTopic,deleteTopic } from "../services/topics";
import { toast } from "react-toastify";

// Get All Topics
export const getAllTopics = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      const responseData = await getTopics();
      if (responseData.success) {
        dispatch(getTopicsSuccess(responseData));
      } else {
        dispatch(getTopicsFailure("Error in retrieving"));
      }
      return responseData;
    } catch (error: any) {
      if (error.message && error.message.message) {
        dispatch(getTopicsFailure(error.message.message));
      } else {
        dispatch(getTopicsFailure("Error in retrieving"));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };
};

const getTopicsSuccess = (responseData: any) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_TOPICS_SUCCESS,
      payload: responseData,
    });
  };
};

const getTopicsFailure = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: GET_TOPICS_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
  };
};

// Create Topic Action
export const createTopicAction = (
  username: String,
  topic: String,
  description: String,
  bussunit: String,
  permissions :Object,

) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const addTopicResponse = await addTopic(username, topic, description,bussunit,permissions);
      if (addTopicResponse.success) {
        dispatch(createTopicSuccess(addTopicResponse.data));
        toast("Topic Added Successfully");
      } else { 
        dispatch(createTopicFailure("Could not add topic. Please try again"));
        toast(addTopicResponse.message);
      }
      return addTopicResponse;
    } catch (error: any) {
      
      toast(error.message);
      createTopicFailure(
        error ? error?.message : "Could not add topic. Please try again"
      );
    }
  };
};

const createTopicSuccess = (addLogoResponse) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CREATE_TOPIC_SUCCESS,
      payload: addLogoResponse,
    });
  };
};

const createTopicFailure = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: CREATE_TOPIC_FAILURE,
      error: errorMessage,
    });
  };
};



// Edit Topic Action
export const editTopicAction = (
  topicId: string,
  topic: string,
  description: string,
  client: string,
  bussunit: string,
  permissions:Object
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const editTopicResponse = await editTopic(
        topicId,
        topic,
        description,
        client,
        bussunit,
        permissions
      );
      // console.log(addTopicResponse);
      if (editTopicResponse.success) {
        toast(editTopicResponse.message);
        dispatch(editTopicSuccess(editTopicResponse.message,topicId,topic,description,bussunit,permissions));
        // dispatch(viewAllTopicsReducer)
      } else {
        dispatch(editTopicFailure(editTopicResponse.message));
        toast.error("Could not add topic. Please try again");
      }
      // dispatch({ type: CLOSE_MODAL });
    } catch (error: any) {
      dispatch(editTopicFailure(error && error?.message));
      toast.error(
        error ? error?.message : "Could not edit topic. Please try again"
      );
    }
  };
};

const editTopicSuccess = (message:string,topicId,topic:string,description:string, bussunit:string,permissions:Object) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: EDIT_TOPIC_SUCCESS,
      payload: {
        message,
        topicId,
        topic,
        description,
        bussunit,
        permissions
      },
    });
  };
};

const editTopicFailure = (message) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: EDIT_TOPIC_FAILURE,
      payload: message,
    });
  };
};


export const handleDeleteTopic = (topicId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true));
    try {
      if (topicId ) {
        const responseData = await deleteTopic(topicId);
        if (responseData.success) {
          dispatch(deleteTopicSuccess(responseData.message,topicId));
          toast('Topic deleted successfully')
        } else {
          dispatch(deleteTopicFailure("Incorrect Fields"));
          toast(responseData.message)
        }
        return responseData;
      }
    } catch (error: any) {
      if (topicId) {
        if (error.message && error.message.message) {
          dispatch(deleteTopicFailure(error.message.message));
          toast(error.message.message)
        } else {
          dispatch(deleteTopicFailure("Incorrect Fields"));
        }
      } else {
        dispatch(deleteTopicFailure(""));
      }
    } finally {
      dispatch(setLoading(false));
      // dispatch({type:CLOSE_MODAL})
    }
  };
};

const deleteTopicSuccess = (message:string,id:string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: DELETE_TOPIC_SUCCESS,
      payload: {
        message,
        id
      }
    });
  };
};

const deleteTopicFailure = (errorMessage: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: DELETE_TOPIC_FAILURE,
      payload:  errorMessage,
    });
  };
};

