import { useEffect, useState } from "react";
import "./TwilioSetting.css";
import Header from "../../components/Header";
import Input from "../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { handleTwillioSetting } from "../../actions/TwillioSettingActions";
import { handleGetTwillioSettingDetail } from "../../actions/getTwillioSettingDetail";
import { RootState } from "../../redux";
import React from "react";
// import { useHistory } from "react-router-dom";


const TwilioSetting = () => {
  const dispatch = useDispatch();

  // const twilioSettingError = useSelector((state: RootState) => state.twilioSetting.error);

  // const history = useHistory();

  const [AccountSid, setAccountSid] = useState("");
  const [AccountSiderror] = useState("");
  const handleAccountSid = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountSid(e.target.value);
  };
  const [AuthToken, setAuthToken] = useState("");
  const [AuthTokenerror] = useState("");
  const handleAuthToken= (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthToken(e.target.value);
  };


  const [mobile, setMobile] = useState("");
  const [Mobileerror] = useState("");
  const handleMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobile(e.target.value);
  };

  const [CharactersCount, setCharactersCount] = useState(0);

  const [mobileotpmessage, setMobileOTPMessage] = useState("");
  const [Mobileotpmessageerror] = useState("");
  const handleMobileOTPMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobileOTPMessage(e.target.value);
    setCharactersCount(e.target.value.length);
  };
  
  
  

  // const isSettingSaved = useSelector(
  //   (state: RootState) => state.twilioSetting.isSettingSaved
  // );

  const getTwillioSettingDetail = useSelector(
    (state: RootState) => state.getTwilioDetailData
  );

  useEffect(() => {
    dispatch(handleGetTwillioSettingDetail());
  }, [dispatch]);

  useEffect(() => {
    console.log("=====", getTwillioSettingDetail);
    setAccountSid(getTwillioSettingDetail.AccountSid);
    setAuthToken(getTwillioSettingDetail.AuthToken);
    setMobile(getTwillioSettingDetail.mobile);
    setMobileOTPMessage(getTwillioSettingDetail.mobileotpmessage);
  }, [getTwillioSettingDetail]);

  /*
  React.useEffect(() => {
    if (isSettingSaved) {
      // history.go(0);
      setAccountSid("");  
      setAuthToken("");
      setMobile("");      
    }
  }, [isSettingSaved, history]);
*/

  const handleSetting = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let a = true;
    //setAccountSid("");

    if (a)
      dispatch(
        handleTwillioSetting(        
          AccountSid,
          AuthToken,
          new Date().toUTCString(),        
          mobile,
          mobileotpmessage,      
        )
      );
  };



  return (
    <>
      <Header />
      <section className="add_user">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form className="add_form" onSubmit={handleSetting}>
                <h1>
                  <span>Twilio Setting</span>
                </h1>
            
                <p>Account Sid *</p>
                <Input
                  type="text"
                  name="account-sid-field"
                  placeholder="Account Sid"
                  value={AccountSid}
                  onChange={handleAccountSid}
                />
                <p className="red-alert">{AccountSiderror}</p>
                <p>Auth Token *</p>
                <Input
                  type="text"
                  name="auth-token-field"
                  placeholder="Auth Token"
                  value={AuthToken}
                  onChange={handleAuthToken}
                />

                <p className="red-alert">{AuthTokenerror}</p>
               

           
            <p>Sender Mobile *</p>
                <Input
                  type="text"
                  name="mobile-field"
                  placeholder="Mobile"
                  value={mobile}
                  onChange={handleMobile}
                />

                <p className="red-alert">{Mobileerror}</p>

            <p> Mobile OTP Messages (Maximum 80 Characters)</p>
                <Input                 
                  type="text"
                  name="mobile-otp-messages"
                  placeholder="Mobile OTP Messages"
                  value={mobileotpmessage}
                  onChange={handleMobileOTPMessage}                
                />
                <p> Total Number of <b> {CharactersCount} </b>Characters </p>
                <p className="red-alert">{Mobileotpmessageerror}</p>
                <p>&nbsp;</p>
                <button
                  className="link marg-up-10"
                  value="save"  
                  disabled={mobileotpmessage.length >= 80}               
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TwilioSetting;
