import React from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import "./context.css";

const categoryMap = {
  OTHERS: "others",
  PRODUCTIVE: "productive",
  NONPRODUCTIVE: "nonProductive",
};
const ContextMenuComponent = ({
  id,
  position,
  onClick,
  menuItems,
  localApps,
  setApps,
  children,
}) => {
  const handleClick = (e, data) => {
    console.log(data);
    const localData = localApps;
    console.log({ localData });
    setApps((prevApps) => {
      const updatedApps = { ...prevApps };
      for (const key in updatedApps) {
        updatedApps[key] = updatedApps[key].filter(
          (app) => app !== data.target.innerText.toString()
        );
      }
      console.log(data,categoryMap[data.to]);
      updatedApps[categoryMap[data.to]] = [
        ...updatedApps[categoryMap[data.to]],
        data.target.innerText.toString(),
      ];
      return updatedApps;
    });
  };

  return (
    <div>
     
      <ContextMenuTrigger id={id} holdToDisplay={-1} collect={() => position}>
        {children}
      </ContextMenuTrigger>

      <ContextMenu id={id}>
        {menuItems.map((item, index) => (
          <MenuItem key={index} data={item.data} onClick={handleClick}>
            {item.label}
          </MenuItem>
        ))}
      </ContextMenu>
    </div>
  );
};

export default ContextMenuComponent;
