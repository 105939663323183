import axios from "../axiosConfig";

class Routes {
  gettAll() {
    return axios.get("/getAllUsers");
  }
  gettRooms() {
    return axios.get("/getAllRooms");
  }

  getBussUnits() {
    return axios.get("/getAllBussUnit");
  }

  getSummary({ dateFrom, dateTo, username, role, roomname, bussunit }) {
    return axios.post("/getSummary", {
      dateFrom,
      dateTo,
      username,
      role,
      roomname,
      bussunit,
    });
  }

  getPrivateCallReport({ dateFrom, dateTo, username, roomname }) {
    return axios.post("/getPrivateCallReport", {
      dateFrom,
      dateTo,
      username,
      roomname,
    });
  }

  getSummaryByUser({ dateFrom, dateTo, selectedUser, roomname, bussunit }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return axios.post("/getSummaryByUser", {
      dateFrom,
      dateTo,
      selectedUser,
      roomname,
      timezone,
      bussunit,
    });
  }
  getSummaryByDay({
    dateStart,
    dateEnd,
    selectedUser,
    selectedDay,
    roomname,
    bussunit,
  }) {
    let dateFrom = new Date(selectedDay + "T00:00:00");
    let dateTo = new Date(selectedDay + "T23:59:59");
    return axios.post("/getSummaryByDay", {
      dateFrom,
      dateTo,
      selectedUser,
      selectedDay,
      roomname,
      bussunit,
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Routes();
