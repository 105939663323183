import request from "./index";
import qs from "querystring";
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const getReportRoom = async () => {
  const roomResponse = await request.get("/roomPre");
  return roomResponse.data;
};

export const getChatRoomsByUser = async (username: string) => {
  const requestBody = qs.stringify({
    username,
  });
  const roomResponse = await request.post("/getRoomsChat", requestBody, config);
  return roomResponse.data;
};

export const getHourlyRoom = async () => {
  const roomResponse = await request.get("/getHourlyRooms");
  return roomResponse.data;
};
