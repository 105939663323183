import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { DateTimePicker } from "react-rainbow-components";
import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUsersByRoomAction } from "../../actions/uniqueLoginUsersActions";
import { RootState } from "../../redux";
import "./styles.css";
import { reportAPI } from "../../utils/api";

const RoomReports = () => {
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [room, setRoom] = useState("");
  // const uniqueUsersCount = useSelector(
  //   (state: RootState) => state.uniqueLoginUsers.uniqueUsersLength
  // );
  const allUserInRoom = useSelector(
    (state: RootState) => state.uniqueLoginUsers.usersByRoom
  );

  const [roomArray, setRoomArray] = useState([]);
  const dispatch = useDispatch();

  const formatDate = (date) => {
    // let hour;
    // let min;
    // let sec;

    // var d = new Date(date),
    //   month = "" + (d.getMonth() + 1),
    //   day = "" + d.getDate(),
    //   year = d.getFullYear();
    // hour = d.getHours();
    // min = d.getMinutes();
    // sec = d.getSeconds();
    // if (month.length < 2) month = "0" + month;
    // if (day.length < 2) day = "0" + day;

    // let a = [year, month, day].join("-");
    // a = a + " " + hour + ":" + min + ":" + sec;
    // return a;
    var d = new Date(date);
    return d.toUTCString();
  };

  const EnableF = () => {
    // dispatch(handleGetHourlyRoom());
    getrooms();
  };


  const getrooms = () => {
    reportAPI.gettRooms().then((res) => {
      let labeledUsers = [];
      // eslint-disable-next-line array-callback-return
      res.data.map((roomValue: any, index) => {
        if (roomValue) {
          // @ts-ignore
          labeledUsers.push(roomValue.roomname);
        }
      });
      labeledUsers.sort();
      setRoomArray(labeledUsers);
    });
  };
  const getDuration = (s) => {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;

    return hrs + ":" + mins + ":" + secs;
  };

  useEffect(() => {
    EnableF();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDatechange = (e: any, flag) => {
    if (flag === "start") {
      setStartdate(new Date(e));
    } else {
      setEnddate(new Date(e));
    }
  };

  const DoSearch = () => {
    let rooms = room === "" ? roomArray.map((value) => value) : [room];
    console.log(rooms);
    dispatch(
      getUsersByRoomAction(formatDate(startdate), formatDate(enddate), rooms)
    );
  };

  const handleRoomChange = (r: any) => {
    setRoom(r);

    if (r === "All") {
      setRoom("");
    }
  };

  return (
    <>
      <Header />
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1 mkg">Room Reports</span>

        <div className="datepicker-container">
          <DateTimePicker
            value={startdate}
            label="Start"
            onChange={(e: any) => handleDatechange(e, "start")}
            className="rainbow-m-around_small datepicker-space"
            hour24
            // format="yyyy-MM-dd HH:mm:ss"
          />
          <DateTimePicker
            value={enddate}
            label="End"
            onChange={(e: any) => handleDatechange(e, "end")}
            className="rainbow-m-around_small datepicker-space"
            hour24
          />
          <br />
          <div className="text-center mt-4">
            <Button id="search" variant="info" onClick={() => DoSearch()}>
              Search
            </Button>
          </div>
        </div>
      </nav>
      <div className="container-fluid hw" id="main">
        <div className="row">
          <div className="report-container">
            <div className="filters-container">
              <Dropdown className="select-item">
                <Dropdown.Toggle
                  variant="success"
                  id="menu-dropdown-room"
                  style={{ width: "90%" }}
                >
                  {room ? room : "All Rooms"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                    width: "90%",
                  }}
                >
                  <Dropdown.Item
                    onClick={() => {
                      handleRoomChange("All");
                    }}
                  >
                    All
                  </Dropdown.Item>
                  {roomArray?.map((m, value) => (
                    <Dropdown.Item
                      key={value + m}
                      onClick={() => {
                        handleRoomChange(m);
                      }}
                    >
                      {m}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="header">
              <h1 className="session-chart">
                Total users {Object.keys(allUserInRoom).length}
              </h1>
              <div className="disp-flex-left"></div>
            </div>
          </div>
        </div>
        <div className="row all_users">
          <div className="report-container">
            <div className="single_user_index">
              <h4>Sr.no</h4>
            </div>
            <div className="single_user_name">
              <h4>Username</h4>
            </div>
            {/* <div className="single_user_name">
              <h5>Time</h5>
            </div> */}
            <div className="single_user_name">
              <h4>Duration</h4>
            </div>
          </div>
        </div>

        <span className="line"></span>
        {Object.keys(allUserInRoom).length > 0
          ? Object.values(allUserInRoom).map((value: any, index) => (
              <React.Fragment key={index}>
                <div className="row all_users">
                  <div className="report-container">
                    <div className="single_user_index">
                      <h5>{index + 1}</h5>
                    </div>
                    <div className="single_user_name">
                      <h5>{value.username}</h5>
                    </div>
                    {/* <div className="single_user_name"><h5>{value.username}</h5></div> */}
                    <div className="single_user_name">
                      <h5>{getDuration(value.duration)}</h5>
                    </div>
                  </div>
                </div>

                <span className="line"></span>
              </React.Fragment>
            ))
          : null}
      </div>
    </>
  );
};

export default RoomReports;
