import "./styles.css";
import React, { useEffect, useState } from "react";
import Piechart from "../Piechart";
import Gantchart from "../Ganttchart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown,faSortUp } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment'
import { timeZoneFormat } from "../../utils/dateformat";
const D3div = (props: any) => {
  const [collapse, setCollapse] = useState(props.collapse);
  const [showPie, setShowPie] = useState(false);
  const gantdata = () => {
    let showPie = false;
    for (let i = 1; i < props.piechartdata.length; i++) {
      if (props.piechartdata[i][1] > 0) {
        showPie = true;
        break;
      }
    }
    setShowPie(showPie);
  };

  useEffect(() => {
    gantdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setCollapse(props.collapse);
  }, [props.collapse]);
  const handleCollapse = () => {
    if (collapse === "show") {
      setCollapse("hide");
    } else {
      setCollapse("show");
    }
  };
  const {format} = timeZoneFormat()
  console.log(props.headerdata[2].toString())
  let dateValue = moment(props.headerdata[2].toString()).local().format(format)

  // moment(props.headerdata[2]).format(format)
  return (
    <>
      {
        <div>
          <div className="acc-9" onClick={handleCollapse}>
            <div className="acc-9p">
              <p>
                {props.headerdata[0] +
                  " " +
                  props.headerdata[1] +
                  " " +
                 
                  dateValue}
              </p>
            </div>
            <div className="acc-9f">
              <FontAwesomeIcon icon={collapse === "hide"?faSortUp: faSortDown} />
            </div>
          </div>
          <div className={"chart-container " + collapse}>
            <div className="gantt-chart-container">
              <Gantchart ganttchartdata={props.ganttchartdata} />
            </div>
            {showPie && (
              <div className="pie-chart-container">
                <Piechart data={props.piechartdata} />
              </div>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default D3div;
